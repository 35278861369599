import type { PrintSettings } from '@/features/printing/types/printing-data-parsers'
import type { ImporterFetchFail } from '@/types/services'
import type { PaymentWallType } from '@/features/payment-wall/types/payment-wall.types'
import { BaseEventEmitter } from '@/common/utils/base-event-emitter'
// more complex event payloads are defined here for readability of BusDefineEmits
import type {
  BusLabelsCreatedPayload,
  BusPackGoProcessData,
  BusFilterUpdatePayload,
} from '@/common/types/event-bus.types'
import type { SubscriptionChangeModalData } from '@/features/subscription/types/subscriptions.types'
import type { PlanGroup } from '@/features/subscription/types/plan.types'

export type BusDefineEmits = {
  /**
   * Events without payloads:
   */
  'app-modal:destroy': []
  'current-brand-loaded': []
  'incoming-orders-reload': []
  'IncomingOrders:clearSelection': []
  'sender-address-changed:reload-orders': []
  'initial-address-modal:dismiss': []
  'initial-address-modal:success': []
  'instagram:load-more': []
  'integration-changed': []
  'onboarding-wizard:save-step': []
  'open-quick-print-settings': []
  'orders-fetch-errors-parsed': []
  'orders-refresh-loaded': []
  'packgo-queue-reload': []
  'packgo:reset-filters': []
  'print-options-modal:cancel': []
  'shipment-announcement-failure': []
  'shipment-form:force-update': []
  'shipment-form:reset': []
  'shipment-form:validate-backend': []
  'shipment-form:validate': []
  'subscription-plan-toggle-billing-type': []
  'subscription-plan-toggle-edit-mode': []
  'validation-check-basic-display-instagram': []
  'validation-check': []
  'validation-reset': []
  /**
   * Events with payloads:
   */
  'app-modal:build': [newComponentName: string, componentProperties: Record<string, unknown> | undefined]
  'instagram:change-orientation': [orientation: 'landscape' | 'portrait']
  'is-character-limit-exceeded': [value: boolean]
  'LabelPrintPipeline:labelsCreated': [payload: BusLabelsCreatedPayload]
  'orders-refresh-complete': [{ updatedAt: number }]
  'orders-refresh-fail': [reason: string]
  'orders-refresh-fetch-errors': [reason: ImporterFetchFail[] | string]
  'packgo:process': [processData?: BusPackGoProcessData | undefined]
  'parcel-monitor:update-filters': [filterData: BusFilterUpdatePayload]
  'payment-modal-completed': [invoiceId: string | undefined]
  'payment-modal-error': [invoiceId: string | undefined]
  'payment-wall-confirmed': [type: PaymentWallType]
  'payment-wall-dismissed': [type: PaymentWallType]
  'print-options-modal:error': [e: unknown]
  'print-options-modal:options': [printOptions: PrintSettings]
  'subscription-plan-deactivate-modal': [planGroup: PlanGroup]
  'subscription-plan-show-modal': [modalPlanData: SubscriptionChangeModalData]
  'update-selection-count': [count: number]
  'updating-order-quick-details': [isLoading: boolean]
}

class Bus extends BaseEventEmitter<BusDefineEmits> { }

const EventBus = new Bus()

export default EventBus
