import type { PlanGroup } from '@/features/subscription/types/plan.types'

export const FREE_PLAN_GROUP: Extract<PlanGroup, 'free'> = 'free'

export const PLANS_GROUP_ORDER = {
  free: 1,
  lite: 2,
  growth: 3,
  premium: 4,
  pro: 5,
  enterprise: 6,
}
