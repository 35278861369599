import axios from '@/common/utils/axios'
import type { ReturnPortalSettings } from '@/features/return-portal/types'
import type { MakeKeyOptional } from '@/common/types/utils/make-key-optional.types'
import type { AllReturnReasons, ReturnReasonPostPutPayload, ReturnReasonDetails } from '@/types/returns'

type ConnectPaymentProviderPayload = {
  state: string
  code: string
  type: 'mollie'
}

/**
* The `return_address` key is mapped to `chosen_return_address` in the PUT call
*/
export function normalisePayload(rawPayload: MakeKeyOptional<ReturnPortalSettings, 'return_address'>) {
  const payload = { ...rawPayload }
  payload.chosen_return_address = payload.return_address
  delete payload.return_address

  return payload
}

export default {
  async findAll(): Promise<ReturnPortalSettings[]> {
    const { data } = await axios.get('/xhr/return-portal')
    return data
  },

  async create(payload: ReturnPortalSettings): Promise<ReturnPortalSettings> {
    const { data } = await axios.post(`/xhr/return-portal`, normalisePayload(payload))
    return data
  },

  async update(id: number, rawPayload: ReturnPortalSettings): Promise<ReturnPortalSettings> {
    const { data } = await axios.patch(`/xhr/return-portal/${id}`, normalisePayload(rawPayload))
    return data
  },

  connectPaymentProvider(id: number, payload: ConnectPaymentProviderPayload) {
    return axios.post(`/xhr/return-portal/${id}/payments`, payload)
  },

  deletePaymentProvider(id: number) {
    return axios.delete(`/xhr/return-portal/${id}/payments`)
  },

  async fetchReturnReasons(): Promise<{ data: AllReturnReasons[] }> {
    const { data } = await axios.get('/xhr/return-reasons')
    return data
  },

  async fetchReturnReason(id: number | string): Promise<ReturnReasonDetails> {
    const { data } = await axios.get(`/xhr/return-reasons/${id}`)
    return data
  },

  async updateReturnReason(id: number | string, payload: ReturnReasonPostPutPayload) {
    return await axios.put(`/xhr/return-reasons/${id}`, payload)
  },

  async createReturnReason(payload: ReturnReasonPostPutPayload) {
    return await axios.post('/xhr/return-reasons', payload)
  },
}
