import { fetchNotifications, markNotificationsAsRead } from '@/features/notifications/api/notifications.api'

import {
  NOTIFICATIONS_CLEAR_NOTIFICATIONS,
  NOTIFICATIONS_FETCH_NOTIFICATIONS,
  NOTIFICATIONS_MARK_NOTIFICATION_AS_READ,
  NOTIFICATIONS_QUEUE_FOR_MARK_AS_READ,
  NOTIFICATIONS_TOGGLE_NOTIFICATIONS_LIST,
  NOTIFICATIONS_UPDATE_NOTIFICATION_COUNT,
} from '@/features/notifications/stores/notifications/action.types'
import {
  NOTIFICATIONS_CLEAR_MARK_AS_READ_QUEUE,
  NOTIFICATIONS_REMOVE_NOTIFICATION,
  NOTIFICATIONS_SET_MARK_AS_READ_QUEUE,
  NOTIFICATIONS_SET_NEW_NOTIFICATION_COUNT,
  NOTIFICATIONS_SET_NOTIFICATIONS,
  NOTIFICATIONS_SET_NOTIFICATIONS_VISIBILITY,
} from '@/features/notifications/stores/notifications/mutation.types'
import type { Commit } from 'vuex'
import type { Notification } from '@/features/notifications/types/notifications.types'

type NotificationsState = {
  currentPage: number
  notifications: Notification[]
  notificationsVisible: boolean
  toBeMarkedAsRead: string[]
  totalPages: number | null
  unreadCount: number
}

const state: NotificationsState = {
  currentPage: 1,
  notifications: [],
  notificationsVisible: false,
  toBeMarkedAsRead: [],
  totalPages: null,
  unreadCount: 0,
}

const getters = {
  currentPage: (state: NotificationsState) => state.currentPage,
  notifications: (state: NotificationsState) => state.notifications,
  notificationsVisible: (state: NotificationsState) => state.notificationsVisible,
  toBeMarkedAsRead: (state: NotificationsState) => state.toBeMarkedAsRead,
  totalPages: (state: NotificationsState) => state.totalPages,
  unreadCount: (state: NotificationsState) => state.unreadCount,
}

const actions = {
  [NOTIFICATIONS_CLEAR_NOTIFICATIONS]({ commit }: { commit: Commit }) {
    commit(NOTIFICATIONS_SET_NOTIFICATIONS, { notifications: [], currentPage: 1, totalPages: null })
  },

  async [NOTIFICATIONS_FETCH_NOTIFICATIONS]({ commit }: { commit: Commit }, params: Record<string, string | number>) {
    const data = await fetchNotifications(params)

    commit(NOTIFICATIONS_SET_NOTIFICATIONS, {
      notifications: data.results,
      currentPage: data.current_page,
      totalPages: data.total_pages,
    })
    commit(NOTIFICATIONS_SET_NEW_NOTIFICATION_COUNT, data.count)
  },

  async [NOTIFICATIONS_MARK_NOTIFICATION_AS_READ]({ commit }: { commit: Commit }, notificationIds: Notification['id'][]) {
    const data = await markNotificationsAsRead(notificationIds)

    commit(NOTIFICATIONS_SET_NEW_NOTIFICATION_COUNT, data.unread_count)
    commit(NOTIFICATIONS_CLEAR_MARK_AS_READ_QUEUE)
  },

  [NOTIFICATIONS_QUEUE_FOR_MARK_AS_READ]({ commit }: { commit: Commit }, notificationId: Notification['id']) {
    commit(NOTIFICATIONS_SET_MARK_AS_READ_QUEUE, notificationId)
    commit(NOTIFICATIONS_REMOVE_NOTIFICATION, notificationId)
  },

  [NOTIFICATIONS_TOGGLE_NOTIFICATIONS_LIST]({ commit }: { commit: Commit }, show: boolean) {
    commit(NOTIFICATIONS_SET_NOTIFICATIONS_VISIBILITY, show)
  },

  [NOTIFICATIONS_UPDATE_NOTIFICATION_COUNT]({ commit }: { commit: Commit }, notificationCount: number) {
    commit(NOTIFICATIONS_SET_NEW_NOTIFICATION_COUNT, notificationCount)
  },
}

const mutations = {
  [NOTIFICATIONS_CLEAR_MARK_AS_READ_QUEUE](state: NotificationsState) {
    state.toBeMarkedAsRead = []
  },

  [NOTIFICATIONS_REMOVE_NOTIFICATION](state: NotificationsState, notificationId: Notification['id']) {
    state.notifications = state.notifications.filter(item => !(item.id === notificationId))
  },

  [NOTIFICATIONS_SET_MARK_AS_READ_QUEUE](state: NotificationsState, notificationId: Notification['id']) {
    state.toBeMarkedAsRead = [...state.toBeMarkedAsRead, notificationId]
  },

  [NOTIFICATIONS_SET_NEW_NOTIFICATION_COUNT](state: NotificationsState, value: number) {
    state.unreadCount = value
  },

  [NOTIFICATIONS_SET_NOTIFICATIONS](state: NotificationsState, { notifications, currentPage, totalPages }:
    { notifications: Notification[], currentPage: number, totalPages: number }) {
    state.notifications = notifications
    state.currentPage = currentPage
    state.totalPages = totalPages
  },

  [NOTIFICATIONS_SET_NOTIFICATIONS_VISIBILITY](state: NotificationsState, value: boolean) {
    state.notificationsVisible = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
