import { logError } from './errors/error-handlers'

import type { TicketDraft, SupportTicketListSavedColumns } from '@/features/support/types/support.types'
import type { LocalPrintSettings } from '@/features/printing/types/printing-data-parsers'
import type { SortingSlug, LocalStorageFilter } from '@/types/services'
import type { OrderColumn } from '@/types/shipping.models'
import type { Integration } from '@/features/integrations/types/integrations.types'
import type { SenderAddress } from '@/features/addresses/types/addresses.types'
import type { Brand } from '@/features/brands/types/brands.types'
import type { IntegrationFilter } from '@/features/integrations/types/integrations.types.ts'
import type { ReturnTabsFilters } from '@/features/returns/types/return-tab.interface'
import type { AuthStateUPS } from '@/features/carriers/types/contract.types'
import type { TrackingPageSettingsLocal } from '@/features/tracking-pages/types/tracking-pages.types'

// For historical reasons and to not break the current behavior, `ngStorage-` prefix is kept
export const STORAGE_PREFIX = 'ngStorage-'

const getSize = (data: string) => String(data).length

/**
 * Sends errors to Sentry along with the space used in localStorage by key to monitor errors thrown when
 * modifying the localstorage.
 *
 * @see https://sendcloud.atlassian.net/browse/SC-11757
 */
const captureException = (error: unknown) => {
  const localStorageSizeByKey = Object.keys(localStorage).reduce(
    (acc, key) => ({
      ...acc,
      [key]: getSize(localStorage[key]),
    }),
    {},
  )
  const localStorageSizeTotal = getSize(Object.values(localStorage).join(''))

  logError(error, {
    additionalData: {
      extra: {
        localStorageSizeTotal,
        localStorageSizeByKey,
      },
    },
  })
}

export function getUserStorage(userId: number): Partial<UserStorage> {
  try {
    const storagePayload = localStorage.getItem(`${STORAGE_PREFIX}${userId}`) || '{}'
    return JSON.parse(storagePayload)
  } catch {
    return {}
  }
}

function saveUserStorage(userId: number, data: Partial<UserStorage>) {
  const storageKey = `${STORAGE_PREFIX}${userId}`
  const storagePayload = JSON.stringify(data)

  try {
    localStorage.setItem(storageKey, storagePayload)
  } catch (error) {
    captureException(error)
  }
}

export function setVariable<T extends keyof UserStorage>(userId: number, storageKey: T, value: UserStorage[T]): void {
  const storage = getUserStorage(userId)

  if (value !== undefined) {
    storage[storageKey] = value
  }

  saveUserStorage(userId, storage)
}

export function removeVariable(userId: number, storageKey: string) {
  const storage = getUserStorage(userId)
  delete storage[storageKey]
  saveUserStorage(userId, storage)
}

export function getVariable<T extends keyof UserStorage>(
  userId: number,
  storageKey: T,
): UserStorage[T] | undefined {
  const storage = getUserStorage(userId)
  return storage[storageKey]
}

// We do not remove these keys from local storage when a user signs in with another account.
export const PUBLIC_STORAGE_KEYS = {
  IS_PACKGO_ORDER_DETAILS_PANEL_OPEN: 'isPackGoOrderDetailsPanelOpen',
  NOT_FIRST_TIME_PACKGO_SINGLE_SCAN_ACTIVATION: 'notFirstTimePackGoSingleScanActivation',
  PRINT_OPTIONS: 'UserPrintOptions',
} as const

export const LOCAL_STORAGE_KEYS = {
  ...PUBLIC_STORAGE_KEYS,
  ACTION_REQUIRED_FILTERS: 'actionRequiredFilterParams',
  BOL_AUTH_STATE: 'bolAuthState',
  IOV_SELECTED_SORTING_SLUG: 'iovSelectedSortingSlug',
  UPS_AUTH_STATE: 'upsAuthState',
  CANCELLED_FILTERS: 'cancelledFilterParams',
  COMPANY_DUNS_NUM: 'companyDunsNumber',
  CREATED_LABELS_DEFAULT_ACTION_ID: 'createdLabelsDefaultActionId',
  CREATED_LABELS_FILTERS: 'createdLabelsFilterParams',
  CREATED_LABELS_ORDERING_OPTION_ID: 'createdLabelsOrderingOptionId',
  DO_NOT_SHOW_DEUTSCHE_POST_INTERNATIONAL_MESSAGE: 'doNotShowDhlGlobalMailMessage',
  FOOTER_OPPO_IS_VISIBLE: 'isOpportunityFooterDismissed',
  HERMES_AUTH_STATE: 'HERMES_AUTH_STATE',
  IS_HUBSPOT_TRIGGERED: 'isHubspotTriggered',
  PRODUCTS_VISIBLE_COLUMNS_IDS: 'productsVisibleColumnsIds',
  INCOMING_ORDERS_COLUMNS: 'INCOMING_ORDERS_COLUMNS',
  INCOMING_ORDERS_DEFAULT_ACTION_ID: 'scOrdersDefaultActionID',
  INCOMING_ORDERS_FILTERS: 'scFilterParams',
  INCOMING_ORDERS_LAST_INTEGRATION_ID: 'scIncomingOrders:lastIntegration',
  INCOMING_ORDERS_SELECTED_ACTION_IDS: 'incomingOrdersSelectedActionIds',
  INTEGRATIONS_FILTERS: 'integrationsFilters',
  LAST_SELECTED_BRAND_ID: 'lastSelectedBrandID',
  NAVIGATION_IS_EXPANDED: 'navigationIsExpanded',
  NOT_PROCESSED_DEFAULT_ACTION_ID: 'notProcessedDefaultActionId',
  NOTIFY_LAST_USED_BRAND_ID: 'notifyLastUsedBrand',
  PACKGO_CUSTOMS_COPIES_SETTINGS_TRACKED: 'packgoCustomsCopiesSettingTracked',
  PACKGO_VUE_ACTIVE: 'packGoVueActive',
  PARCEL_MONITOR_OPEN: 'parcelMonitorOpen',
  PARCEL_MONITOR_V2_COLUMNS: 'parcelMonitorColumns',
  PARCEL_MONITOR_V2_FILTERS: 'parcelMonitorFilters',
  RETURN_COLUMNS: 'RETURN_COLUMNS',
  RETURN_FILTERS: 'returnFilters',
  RETURN_TABS_FILTERS: 'returnTabsFilters',
  RETURNS_ITEMS_PER_PAGE: 'returnsItemsPerPage',
  RETURNS_LIST_SELECTED_ACTION_ID: 'returnsListSelectedActionId',
  RETURNS_SEND_LABEL_TO_CUSTOMER: 'returnsSendLabelToCustomer',
  RETURNS_PRINT_LABEL: 'returnsPrintLabel',
  SEEN_CANCELLATION_TOAST: 'scParcels:seenCancellationToast',
  SENDER_ADDRESS_ID: 'senderAddressId',
  SHIPPED_FILTERS: 'shippedFilterParams',
  SUPPORT_FILTERS_PLATFORM: 'supportFiltersPlatform',
  SUPPORT_FILTERS_SAAS: 'supportFiltersSaas',
  SUPPORT_FILTERS_SHIPPING: 'supportFiltersShipping',
  SUPPORT_FILTERS_INSURANCE: 'supportFiltersInsurance',
  SUPPORT_PLATFORM_TICKET_LIST_COLUMNS: 'supportPlatformTicketListColumns',
  SUPPORT_SAAS_TICKET_LIST_COLUMNS: 'supportSaasTicketListColumns',
  SUPPORT_SHIPPING_TICKET_LIST_COLUMNS: 'supportShippingTicketListColumns',
  SUPPORT_INSURANCE_LIST_COLUMNS: 'supportInsuranceListColumns',
  SUPPORT_SHOW_TALKING_WITH_CARRIER_WARNING: 'supportShowTalkingWithCarrierWarning',
  SUPPORT_TICKET_DRAFTS: 'supportTicketDrafts',
  TRACKING_PAGE: 'trackingPage',
  EXPERIMENTS: 'experiments',
  SHIPPING_RULES_UPDATES_ALERT_CLOSED: 'shippingRulesUpdatesAlertClosed',
  RETURN_RULES_UPDATES_ALERT_CLOSED: 'returnRulesUpdatesAlertClosed',
} as const

export interface UserStorage {
  [PUBLIC_STORAGE_KEYS.IS_PACKGO_ORDER_DETAILS_PANEL_OPEN]: boolean
  [PUBLIC_STORAGE_KEYS.NOT_FIRST_TIME_PACKGO_SINGLE_SCAN_ACTIVATION]: boolean
  [PUBLIC_STORAGE_KEYS.PRINT_OPTIONS]: Partial<LocalPrintSettings>
  [LOCAL_STORAGE_KEYS.PRODUCTS_VISIBLE_COLUMNS_IDS]: Record<string, boolean>
  [LOCAL_STORAGE_KEYS.BOL_AUTH_STATE]: string
  [LOCAL_STORAGE_KEYS.IOV_SELECTED_SORTING_SLUG]: SortingSlug
  [LOCAL_STORAGE_KEYS.UPS_AUTH_STATE]: AuthStateUPS
  [LOCAL_STORAGE_KEYS.CANCELLED_FILTERS]: LocalStorageFilter
  [LOCAL_STORAGE_KEYS.ACTION_REQUIRED_FILTERS]: LocalStorageFilter
  [LOCAL_STORAGE_KEYS.COMPANY_DUNS_NUM]: string
  [LOCAL_STORAGE_KEYS.CREATED_LABELS_DEFAULT_ACTION_ID]: string
  [LOCAL_STORAGE_KEYS.CREATED_LABELS_FILTERS]: LocalStorageFilter
  [LOCAL_STORAGE_KEYS.CREATED_LABELS_ORDERING_OPTION_ID]: string
  [LOCAL_STORAGE_KEYS.DO_NOT_SHOW_DEUTSCHE_POST_INTERNATIONAL_MESSAGE]: boolean
  [LOCAL_STORAGE_KEYS.FOOTER_OPPO_IS_VISIBLE]: boolean
  [LOCAL_STORAGE_KEYS.HERMES_AUTH_STATE]: string
  [LOCAL_STORAGE_KEYS.IS_HUBSPOT_TRIGGERED]: string
  [LOCAL_STORAGE_KEYS.INCOMING_ORDERS_COLUMNS]: OrderColumn[]
  [LOCAL_STORAGE_KEYS.INCOMING_ORDERS_DEFAULT_ACTION_ID]: string
  [LOCAL_STORAGE_KEYS.INCOMING_ORDERS_FILTERS]: Record<string, LocalStorageFilter>
  [LOCAL_STORAGE_KEYS.INCOMING_ORDERS_LAST_INTEGRATION_ID]: Integration['id']
  [LOCAL_STORAGE_KEYS.INCOMING_ORDERS_SELECTED_ACTION_IDS]: string
  [LOCAL_STORAGE_KEYS.INTEGRATIONS_FILTERS]: Partial<IntegrationFilter>[]
  [LOCAL_STORAGE_KEYS.LAST_SELECTED_BRAND_ID]: Brand['id']
  [LOCAL_STORAGE_KEYS.NAVIGATION_IS_EXPANDED]: boolean
  [LOCAL_STORAGE_KEYS.NOTIFY_LAST_USED_BRAND_ID]: Brand['id']
  [LOCAL_STORAGE_KEYS.PACKGO_CUSTOMS_COPIES_SETTINGS_TRACKED]: boolean
  [LOCAL_STORAGE_KEYS.PARCEL_MONITOR_OPEN]: boolean
  [LOCAL_STORAGE_KEYS.PARCEL_MONITOR_V2_COLUMNS]: SupportTicketListSavedColumns[]
  [LOCAL_STORAGE_KEYS.PARCEL_MONITOR_V2_FILTERS]: Record<string, string | string[]>
  [LOCAL_STORAGE_KEYS.RETURN_COLUMNS]: Record<string, { title: string, checked: boolean }>
  [LOCAL_STORAGE_KEYS.RETURN_TABS_FILTERS]: ReturnTabsFilters
  [LOCAL_STORAGE_KEYS.RETURNS_ITEMS_PER_PAGE]: string
  [LOCAL_STORAGE_KEYS.RETURNS_LIST_SELECTED_ACTION_ID]: string
  [LOCAL_STORAGE_KEYS.RETURNS_SEND_LABEL_TO_CUSTOMER]: boolean
  [LOCAL_STORAGE_KEYS.RETURNS_PRINT_LABEL]: boolean
  [LOCAL_STORAGE_KEYS.SEEN_CANCELLATION_TOAST]: boolean
  [LOCAL_STORAGE_KEYS.SENDER_ADDRESS_ID]: SenderAddress['id']
  [LOCAL_STORAGE_KEYS.SHIPPED_FILTERS]: LocalStorageFilter
  [LOCAL_STORAGE_KEYS.SUPPORT_FILTERS_PLATFORM]: Record<string, string | string[]>
  [LOCAL_STORAGE_KEYS.SUPPORT_FILTERS_INSURANCE]: Record<string, string | string[]>
  [LOCAL_STORAGE_KEYS.SUPPORT_FILTERS_SAAS]: Record<string, string | string[]>
  [LOCAL_STORAGE_KEYS.SUPPORT_FILTERS_SHIPPING]: Record<string, string | string[]>
  [LOCAL_STORAGE_KEYS.SUPPORT_PLATFORM_TICKET_LIST_COLUMNS]: SupportTicketListSavedColumns[]
  [LOCAL_STORAGE_KEYS.SUPPORT_SAAS_TICKET_LIST_COLUMNS]: SupportTicketListSavedColumns[]
  [LOCAL_STORAGE_KEYS.SUPPORT_SHIPPING_TICKET_LIST_COLUMNS]: SupportTicketListSavedColumns[]
  [LOCAL_STORAGE_KEYS.SUPPORT_INSURANCE_LIST_COLUMNS]: SupportTicketListSavedColumns[]
  [LOCAL_STORAGE_KEYS.SUPPORT_SHOW_TALKING_WITH_CARRIER_WARNING]: boolean
  [LOCAL_STORAGE_KEYS.SUPPORT_TICKET_DRAFTS]: TicketDraft[]
  [LOCAL_STORAGE_KEYS.TRACKING_PAGE]: TrackingPageSettingsLocal
  [LOCAL_STORAGE_KEYS.EXPERIMENTS]: Record<string, string>
  [LOCAL_STORAGE_KEYS.SHIPPING_RULES_UPDATES_ALERT_CLOSED]: boolean
  [key: string]: unknown
}
