<template>
  <button
    :id="props.id"
    :class="{ 'aside-navigation__item-link--active': isActive }"
    class="aside-navigation__item-link"
    type="button"
    @click="toggleNotificationsList(true)"
  >
    <Fa
      class="aside-navigation__item-icon"
      :icon="faBell"
    />
    <span class="aside-navigation__link-name">{{ t('Notifications') }}</span>

    <span
      v-if="unreadCount > 0"
      class="aside-navigation__item-counter"
      :class="{ 'counter-beta-color': isNewNotificationsBetaEnabled }"
    >
      {{ unreadCount }}
    </span>
  </button>
</template>

<script setup lang="ts">
import { computed, inject, type Ref } from 'vue'
import { useNotificationsStore } from '@/features/notifications/stores'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { useI18n } from 'vue-i18n'
import Fa from 'vue-fa'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { NOTIFICATIONS_TOGGLE_NOTIFICATIONS_LIST } from '@/features/notifications/stores/notifications/action.types'
import { NOTIFICATION_ROUTE, NOTIFICATION_DETAILS_ROUTE } from '@/features/notifications/routes'

import NavigationAnalytics from '@/application/utils/navigation.analytics'
import type { NavigationItemTrackingData } from '@/application/types/navigation-tracking.types'
import { NEW_NOTIFICATIONS_BETA } from '@/features/support/beta-features'

const notificationRoutesNames = [NOTIFICATION_ROUTE, NOTIFICATION_DETAILS_ROUTE]

const { t } = useI18n()
const notificationsStore = useNotificationsStore()
const { setNotificationPanelVisible } = notificationsStore

const props = defineProps<{
  id: string
}>()

const store = useStore()
const route = useRoute()
const unreadCount = computed(() => {
  if (isNewNotificationsBetaEnabled.value) {
    return notificationsStore.getUnreadCount
  }
  return store.getters.unreadCount
})
const isActive = computed(() => notificationRoutesNames.includes(route.name as string))
const isNewNotificationsBetaEnabled = computed(() => store.getters.hasBetaFeature(NEW_NOTIFICATIONS_BETA))

const isExpanded = inject<Ref<boolean>>('isExpanded')
const isTabletOrMobile = inject<Ref<boolean>>('isMobileMenuVisible')

const params = computed<NavigationItemTrackingData>(() => ({
  name: props.id,
  navigationMenuIsExpanded: isExpanded?.value || false,
  navigationIsTabletOrMobile: isTabletOrMobile?.value || false,
}))

const toggleNotificationsList = (val: boolean) => {
  if (isNewNotificationsBetaEnabled.value) {
    NavigationAnalytics.trackNavigationMenuItemClick(params.value)
    setNotificationPanelVisible(val)
    return
  }
  NavigationAnalytics.trackNavigationMenuItemClick(params.value)
  store.dispatch(NOTIFICATIONS_TOGGLE_NOTIFICATIONS_LIST, val)
}
</script>

<style lang="scss" scoped>
.aside-navigation__item-counter {
  background: #c43333;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-left: auto;
}

.counter-beta-color {
  background: $sc-informative;
}
</style>
