import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw } from 'vue-router'
import {
  ANALYTICS_BETA_FEATURE,
  ANALYTICS_BETA_FEATURE_SHIPPING_EXCEPTIONS,
} from '@/features/analytics-beta/beta-features'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { ANALYTICS_SHIPPING_ROUTE } from '@/features/analytics/routes'

// Note: We have to abstract the names "shipping" -> "ship-ping" and "returns" -> "ret-urns" for now.
// TODO: We need to refactor isActiveMenu method in AppSidebarNavigation and then remove the name abstraction
// currently everything including the two names shows the main shipping and returns pages as active.
export const ANALYTICS_ROUTES = {
  SHIPPING: {
    OVERVIEW: 'analytics-beta-ship-ping-overview',
    PERFORMANCE: 'analytics-beta-ship-ping-performance',
    EXCEPTIONS: 'analytics-beta-ship-ping-exceptions',
    TRANSIT_TIMES: 'analytics-beta-ship-ping-transit-times',
  },
  RETURNS: {
    OVERVIEW: 'analytics-beta-ret-urns-overview',
  },
}

export default [
  {
    name: 'analytics-beta',
    path: '/analytics-beta',
    redirect: { name: ANALYTICS_ROUTES.SHIPPING.OVERVIEW },
    component: () => import('@/features/analytics-beta/pages/AnalyticsPage.vue'),
    beforeEnter: betaGuard(ANALYTICS_BETA_FEATURE, ANALYTICS_SHIPPING_ROUTE),
    children: [
      // /analytics-beta/shipping/* routes
      {
        name: 'analytics-beta-ship-ping',
        path: 'shipping',
        redirect: { name: ANALYTICS_ROUTES.SHIPPING.OVERVIEW },
        component: () => import('@/features/analytics-beta/pages/shipping/AnalyticsShippingPage.vue'),
        children: [
          {
            name: ANALYTICS_ROUTES.SHIPPING.OVERVIEW,
            path: 'overview',
            component: () => import('@/features/analytics-beta/pages/shipping/AnalyticsShippingOverviewPage.vue'),
            meta: {
              pageTitle: () => i18n.t('analytics.shipping.overview.title'),
              pageDescription: () => i18n.t('analytics.shipping.overview.description'),
              permissions: ['view_analytics'],
              parent: 'analytics-beta-ship-ping',
            },
          },
          {
            name: ANALYTICS_ROUTES.SHIPPING.PERFORMANCE,
            path: 'performance',
            component: () => import('@/features/analytics-beta/pages/shipping/AnalyticsShippingPerformancePage.vue'),
            meta: {
              pageTitle: () => i18n.t('analytics.shipping.performance.title'),
              pageDescription: () => i18n.t('analytics.shipping.performance.description'),
              permissions: ['view_analytics'],
              parent: 'analytics-beta-ship-ping',
            },
          },
          {
            name: ANALYTICS_ROUTES.SHIPPING.EXCEPTIONS,
            path: 'exceptions',
            component: () => import('@/features/analytics-beta/pages/shipping/AnalyticsShippingExceptionsPage.vue'),
            beforeEnter: betaGuard(ANALYTICS_BETA_FEATURE_SHIPPING_EXCEPTIONS, ANALYTICS_ROUTES.SHIPPING.OVERVIEW),
            meta: {
              pageTitle: () => i18n.t('analytics.shipping.exceptions.title'),
              pageDescription: () => i18n.t('analytics.shipping.exceptions.description'),
              permissions: ['view_analytics'],
              parent: 'analytics-beta-ship-ping',
            },
          },
          {
            name: ANALYTICS_ROUTES.SHIPPING.TRANSIT_TIMES,
            path: 'transit-times',
            component: () => import('@/features/analytics-beta/pages/shipping/AnalyticsShippingTransitTimesPage.vue'),
            meta: {
              pageTitle: () => i18n.t('analytics.shipping.transit_times.title'),
              pageDescription: () => i18n.t('analytics.shipping.transit_times.description'),
              permissions: ['view_analytics'],
              parent: 'analytics-beta-ship-ping',
            },
          },
        ],
        meta: {
          pageTitle: () => i18n.t('Analytics'),
          permissions: ['view_analytics'],
          parent: 'analytics-beta',
        },
      },

      // /analytics-beta/returns/* routes
      {
        name: 'analytics-beta-ret-urns',
        path: 'returns',
        redirect: { name: ANALYTICS_ROUTES.RETURNS.OVERVIEW },
        component: () => import('@/features/analytics-beta/pages/returns/AnalyticsReturnsPage.vue'),
        children: [
          {
            name: ANALYTICS_ROUTES.RETURNS.OVERVIEW,
            path: 'overview',
            component: () => import('@/features/analytics-beta/pages/returns/AnalyticsReturnsOverviewPage.vue'),
            meta: {
              pageTitle: () => i18n.t('analytics.returns.overview.title'),
              pageDescription: () => i18n.t('analytics.returns.overview.description'),
              permissions: ['view_analytics'],
              parent: 'analytics-beta-ret-urns',
            },
          },
        ],
        meta: {
          pageTitle: () => i18n.t('Analytics'),
          permissions: ['view_analytics'],
          parent: 'analytics-beta',
        },
      },
    ],
    meta: {
      pageTitle: () => i18n.t('Analytics'),
      permissions: ['view_analytics'],
      parent: 'analytics-beta',
    },
  },
] satisfies RouteRecordRaw[]
