import {
  RETURNS_SET_STATIC_DATA,
  RETURNS_SET_INCOMING_PARCEL,
  RETURNS_SET_ALLOWED_METHODS,
  RETURNS_SET_RETURN_SHIPPING_METHOD_ERROR,
} from '@/features/returns/stores/mutation.types'

import {
  RETURNS_FETCH_STATIC_DATA,
  RETURNS_FETCH_DETAILS,
  RETURNS_FETCH_ALLOWED_METHODS,
} from '@/features/returns/stores/action.types'

import ReturnApi from '@/features/returns/api/return.api'
import ParcelService from '@/features/shipment-tabs/services/parcel.service'
import ParcelStatusApi from '@/features/parcel-monitor/api/parcel-status.api'

import type { Commit } from 'vuex'
import type { ReturnsState } from '@/types/returns'
import type { Carrier } from '@/features/carriers/types/carrier.types'
import type { Return, UploadedImage, ReturnsStaticData } from '@/features/returns/types'
import type { Parcel, ParcelStatusResponse } from '@/types/shipping.models'

const initialState: ReturnsState = {
  allReasons: [],
  allDeliveryOptions: [],
  allRefundOptions: [],
  allPaymentProviders: [],
  incomingParcel: undefined,
  incomingParcelStatus: undefined,
  outgoingParcel: undefined,
  availableShippingMethods: new Set(),
  returnShippingMethodErrors: new Map(),
  images: [],
  defaults: {
    return_order_shipping_method: 0,
    return_form_shipping_method: 0,
  },
  returnReasonCategories: [],
}

/**
 * @type {import('@/types/returns').ReturnsGetters}
 */
const getters = {
  returnReasonById(state: ReturnsState) {
    return (id: number) => state.allReasons.find(item => item.id === id)
  },
  returnReasons: (state: ReturnsState) => state.allReasons || [],
  returnCarriers: (_: ReturnsState, getters: any) => {
    return getters.carriers.filter((carrier: Carrier) => carrier.supports_returns)
  },
  returnDeliveryOptions: (state: ReturnsState) => state.allDeliveryOptions,
  returnRefundOptions: (state: ReturnsState) => state.allRefundOptions,
  returnAvailableShippingMethods: (state: ReturnsState) => state.availableShippingMethods,
  returnIsAvailableShippingMethod:
    (state: ReturnsState) => (method: number) => state.availableShippingMethods.has(method) || method == null,
  returnShippingMethodError:
    (state: ReturnsState) => (orderId: number) => state.returnShippingMethodErrors.get(orderId) || null,
  returnShippingMethodHasError:
    (state: ReturnsState) => (orderId: number) => state.returnShippingMethodErrors.has(orderId),
  returnReasonCategories: (state: ReturnsState) => state.returnReasonCategories,
}

const actions = {
  async [RETURNS_FETCH_STATIC_DATA]({ commit }: { commit: Commit }) {
    const data = await ReturnApi.fetchStaticData()
    commit(RETURNS_SET_STATIC_DATA, data)
  },

  async [RETURNS_FETCH_DETAILS]({ commit }: { commit: Commit }, returnShipment: Return) {
    if (returnShipment.incoming_parcel) {
      const incomingParcel = await ParcelService.find(returnShipment.incoming_parcel)
      const incomingParcelStatus = await ParcelStatusApi.find(incomingParcel.global_status_id)
      const images = returnShipment.images || []
      commit(RETURNS_SET_INCOMING_PARCEL, { incomingParcel, incomingParcelStatus, images })
    }
  },

  async [RETURNS_FETCH_ALLOWED_METHODS]({ state, commit }: { state: ReturnsState, commit: Commit }) {
    if (state.availableShippingMethods.size === 0) {
      const data = await ReturnApi.getReturnAvailableMethods()
      commit(RETURNS_SET_ALLOWED_METHODS, data?.shipping_methods)
    }
  },
}

const mutations = {
  [RETURNS_SET_INCOMING_PARCEL](
    state: ReturnsState,
    { incomingParcel, incomingParcelStatus, images }:
    { incomingParcel: Parcel, incomingParcelStatus: ParcelStatusResponse, images: UploadedImage[] },
  ) {
    state.incomingParcel = incomingParcel
    state.incomingParcelStatus = incomingParcelStatus
    state.images = images
  },

  [RETURNS_SET_STATIC_DATA](state: ReturnsState, payload: ReturnsStaticData) {
    state.allDeliveryOptions = payload.delivery_options || []
    state.allRefundOptions = payload.refund_options || []
    state.allReasons = payload.return_reasons || []
    state.allPaymentProviders = payload.payment_provider || []
    state.returnReasonCategories = payload.return_reasons_categories || []
  },

  [RETURNS_SET_ALLOWED_METHODS](state: ReturnsState, availableShippingMethods: number[]) {
    state.availableShippingMethods = new Set(availableShippingMethods)
  },

  [RETURNS_SET_RETURN_SHIPPING_METHOD_ERROR](
    state: ReturnsState, { orderId, error }: { orderId: number, error: string },
  ) {
    if (error) {
      state.returnShippingMethodErrors.set(orderId, error)
    } else {
      state.returnShippingMethodErrors.delete(orderId)
    }
  },
}

export default {
  state: () => initialState,
  getters,
  actions,
  mutations,
}
