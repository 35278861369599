import incomingOrdersRoute from '@/features/incoming-orders/routes'
import labelDetailsRoute from '@/features/label-details/routes'
import shipmentTabsRoutes from '@/features/shipment-tabs/routes'
import shipmentFormRoutes from '@/features/shipment-forms/routes'
import supportRoutes from '@/features/support/routes'
import parcelMonitorRoutes from '@/features/parcel-monitor-v2/routes'
import settingsRoutes from '@/application/routes/settings.routes'
import returnsRoutes from '@/features/returns/routes'
import { trackingPagesPreviewRoutes } from '@/features/tracking-pages/routes'
import pickupsRoutes from '@/features/pickups/routes'
import packgoRoutes from '@/features/packgo/routes'
import insurance from '@/features/insurance/routes'
import onboardingWizardRoutes from '@/features/onboarding-wizard/routes'
import opportunityCenterRoutes from '@/features/opportunity-center/routes'
import notificationsRoutes from '@/features/notifications/routes'
import fallbackRoute from '@/application/routes/fallback-route'
import csvUploadRoutes from '@/features/csv-upload/routes'
import checkoutRoutes from '@/features/dynamic-checkout/routes'
import analyticsRoutes from '@/features/analytics/routes'
import analyticsBetaRoutes from '@/features/analytics-beta/routes'
import whatsappConversationsRoutes from '@/features/whatsapp-conversations/routes'
import productsRoutes from '@/features/products/routes'
import type { RouteRecordRaw } from 'vue-router'

export default [
  ...analyticsRoutes,
  ...analyticsBetaRoutes,
  checkoutRoutes,
  ...notificationsRoutes,
  ...packgoRoutes,
  ...insurance,
  ...pickupsRoutes,
  ...returnsRoutes,
  incomingOrdersRoute,
  ...shipmentTabsRoutes,
  ...shipmentFormRoutes,
  labelDetailsRoute,
  ...settingsRoutes,
  ...supportRoutes,
  ...parcelMonitorRoutes,
  ...csvUploadRoutes,
  trackingPagesPreviewRoutes,
  onboardingWizardRoutes,
  ...whatsappConversationsRoutes,
  opportunityCenterRoutes,
  ...productsRoutes,

  fallbackRoute,
] satisfies RouteRecordRaw[]
