import { STRING_PARSER_TYPE, NUMBER_EXPECTATION, NUMBER_PARSER_TYPE } from '../constants'
import { checkIsNumber } from '../utils/checks'
import { toReadableTypeOf, createTypeOfAssertion, toReadableLiteral } from '../utils/helpers'
import { union, type UnionParser } from './union'
import type { CTX } from '../types/context.types'
import type { ParserType } from '../types/core.types'
import type { BaseCheckFunc, Parser } from '../types/parsers.types'
import type { LiteralType } from '../types/type-helpers.types'

export class SimpleParser<T> implements Parser<T> {
  constructor(
    readonly expected: string,
    private readonly baseCheck: BaseCheckFunc<T>,
    private readonly receivedDataToString = toReadableTypeOf,
    readonly type?: ParserType,
  ) {}

  parse(data: unknown, ctx: CTX) {
    ctx.assert(this.baseCheck(data, ctx), this.expected, this.receivedDataToString(data))
    // simple parsers NEVER mutate/alter data
    return data
  }
}

// we pre-compute these simple parsers, because they are not configurable and can act as singletons
const stringParser = new SimpleParser('string', createTypeOfAssertion('string'), toReadableTypeOf, STRING_PARSER_TYPE)
const bigintParser = new SimpleParser('bigint', createTypeOfAssertion('bigint'), toReadableTypeOf)
const booleanParser = new SimpleParser('boolean', createTypeOfAssertion('boolean'), toReadableTypeOf)
// TODO: maybe create integer() parser also to not accept Infinity?
const numberParser = new SimpleParser(NUMBER_EXPECTATION, checkIsNumber, toReadableTypeOf, NUMBER_PARSER_TYPE)
const numericStringParser = new SimpleParser(
  'numericString',
  (data): data is `${number}` => typeof data === 'string' && !!data && Number.isFinite(+data),
  toReadableLiteral,
)

/**
 * ```ts
 * // number
 * number()
 * // valid: 1, 0, Infinity, -Infinity
 * // invalid: `NaN`, 'string', null, '1'
 * ```
 */
export const number = () => numberParser

/**
 * Matches any string type
 * ```ts
 * // string
 * string() // valid: '', 'any-string'
 * // nonempty string
 * nonempty(string()) // valid: 'some-string', invalid: ''
 * ```
 */
export const string = () => stringParser

/**
 * Matches any strings that are not empty and can be converted to number
 * ```ts
 * numericString()
 * // valid: '1', '0', '0.2', '-100'
 * // invalid: 'someOtherString', 'Infinity', 'NaN', 'null', '', 1, other non-string types
 * ```
 */
export const numericString = () => numericStringParser

/**
 * Matches bigint type
 * ```ts
 * // bigint
 * bigint() // valid: 0n, 3n, 4000030n, BigInt(10n^1000n)
 * ```
 */
export const bigint = () => bigintParser
/**
 * ```ts
 * // boolean
 * boolean()
 * // valid: true, false
 * // invalid: 0, 1, 'true', 'false'
 * ```
 */
export const boolean = () => booleanParser

const valueToLiteralParser = <V>(value: V) =>
  new SimpleParser(toReadableLiteral(value), (data): data is V => data === value, toReadableLiteral)

/**
 * Matches an `exact` value using the `===` operator
 * works with types: string | number | boolean | bigint
 * ```ts
 * // 1
 * literal(1) // valid: 1, invalid: anything else
 * literal('hi') // valid: 'hi', invalid: anything else
 * literal('hi', false) // valid: 'hi' and false, invalid: anything else
 * ```
 */
export const literal = <T extends LiteralType, U extends readonly LiteralType[] | never[]>(first: T, ...others: U) => {
  type ReturnType = U extends never[]
    ? SimpleParser<T>
    : UnionParser<[SimpleParser<T>, ...Array<SimpleParser<U[number]>>]>

  const firstParser = valueToLiteralParser(first)

  if (!others.length) {
    return firstParser as ReturnType
  }
  return union(firstParser, ...others.map((v: U[number]) => valueToLiteralParser(v))) as ReturnType
}
