import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useOnboardingWizardStore } from '@/features/onboarding-wizard/stores/index'
import { ONBOARDING_WIZARD_ROUTE } from '@/features/onboarding-wizard/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { OPPORTUNITY_CENTER_ROUTE } from '@/features/opportunity-center/routes'

/**
 * Check if the user has access to the Onboarding Wizard
 */
export const redirectAwayOrToOnboardingWizard = (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const onboardingWizardStore = useOnboardingWizardStore()

  if (!onboardingWizardStore.isWizardSkipped && onboardingWizardStore.isWizardShown && !to.path.includes('onboarding')) {
    return next({ name: ONBOARDING_WIZARD_ROUTE })
  } else if (!onboardingWizardStore.isWizardShown && to.path.includes('onboarding')) {
    return next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  } else if (onboardingWizardStore.isWizardSkipped && to.path.includes('onboarding')) {
    return next({ name: OPPORTUNITY_CENTER_ROUTE })
  } else {
    return next()
  }
}
