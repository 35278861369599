/** @typedef {typeof state} ModuleState */
/** @typedef {import('@/features/carriers/types/carrier.types').Carrier} Carrier */
/** @typedef {import('@/types/models').NotificationStatus} NotificationStatus */
/** @typedef {import('@/types/models').ShippingFunctionalityDefinitions} ShippingFunctionalityDefinitions */
/** @typedef {import('@/types/models').ShippingMethod} ShippingMethod */
/** @typedef {import('@/types/models').ShippingProduct} ShippingProduct */
/** @typedef {import('@/types/api').ShippingProductParameters} ShippingProductParameters */
/** @typedef {{ total?: number, pending?: number}} AnnouncementProgress */
/** @typedef {import('@/types/shipping.models').ShippingPriceItem} ShippingPriceItem */

import {
  SHIPPING_CHECK_FOR_ACTION_REQUIRED,
  SHIPPING_FETCH_CARRIERS,
  SHIPPING_FETCH_CARRIERS_LAZILY,
  SHIPPING_FETCH_NOTIFICATION_HISTORY,
  SHIPPING_FETCH_SHIPPING_FUNCTIONALITIES,
  SHIPPING_FETCH_SHIPPING_METHODS,
  SHIPPING_FETCH_SHIPPING_METHODS_LAZILY,
  SHIPPING_FETCH_SHIPPING_PRODUCTS,
  SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY,
  SHIPPING_FETCH_INCOMING_SHIPPING_METHODS,
  SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY,
  SHIPPING_FETCH_SHIPPING_PRICES,
  SHIPPING_FETCH_SHIPPING_PRICES_LAZILY,
} from '@/features/shipment-tabs/stores/shipping/action.types.js'
import {
  SHIPPING_SET_CARRIERS,
  SHIPPING_SET_HAS_ACTION_REQUIRED,
  SHIPPING_SET_NOTIFICATION_HISTORY,
  SHIPPING_SET_SHIPPING_FUNCTIONALITIES,
  SHIPPING_SET_SHIPPING_METHODS,
  SHIPPING_SET_SHIPPING_PRODUCTS,
  SHIPPING_SET_INCOMING_SHIPPING_METHODS,
  SHIPPING_SET_SELECTED_ACTIONS,
  SHIPPING_SET_ANNOUNCEMENT_PROGRESS,
  SHIPPING_SET_LOADING_SHIPPING_PRICES,
  SHIPPING_SET_SHIPPING_PRICES,
  SHIPPING_RESET_SHIPPING_PRICES,
  SHIPPING_RESET_SHIPPING_PRICES_BY_ORDER_IDS,
} from '@/features/shipment-tabs/stores/shipping/mutation.types.js'

import CarrierApi from '@/features/carriers/api/carrier.api'
import ParcelService from '@/features/shipment-tabs/services/parcel.service'
import ShippingPricesApi from '@/features/incoming-orders/api/shipping-prices.api'
import ShippingMethodApi from '@/features/shipment-tabs/api/shipping-method.api'
import {
  fetchShippingFunctionalities,
  fetchShippingProducts,
} from '@/features/dynamic-checkout/api/shipping-products.api'
import { fetchNotificationHistory } from '@/features/tracking-messages/api/tracking-messages.api'

import { DIRECTION_INCOMING } from '@/app/common/constants'

// We have cases like these:
//
// - Carriers cease to operate (Fadello, Sannd)
// - User might get access to a beta carrier which then gets retracted.
//
// In cases like these, users may end up having parcels created (using that
// carrier), yet the frontend will crash if the backend hands over such
// parcels. Even worse, due to exceptions being swallowed we had issues like
// SC-15361 where people would be staring at a completely blank "Created labels"
// tab.
//
// Options of resolving this:
//
// - Backend should not hand over those parcels to the frontend: that would be quite odd, users would not
//   be able to see parcels they created anymore.
//
// - Make the frontend robust against this.
//
// Opted for the latter. Instead of littering "if (bad carrier)" all over the place, we simply return this:
export const UNKNOWN_CARRIER = {
  id: -1,
  code: 'unknown',
  name: 'Unknown',
  logo: {
    full: '',
    icon: '',
  },
}

const state = {
  /** @type {Array<Carrier>} */ carriers: [],
  /** @type {Array<NotificationStatus>} */ notificationHistory: [],
  /** @type {boolean} */ hasActionRequired: false,
  /** @type {ShippingFunctionalityDefinitions | {}} */ shippingFunctionalities: {},
  /** @type {Array<ShippingMethod>} */ shippingMethods: [],
  /** @type {Array<ShippingMethod>} */ incomingShippingMethods: [],
  /** @type {Array<ShippingProduct>} */ shippingProducts: [],
  /** @type {Array<string>} */ selectedActions: [],
  /** @type {AnnouncementProgress} */ announcementProgress: { total: 0, pending: 0 },
  /** @type {Array<ShippingPriceItem>} */ shippingPriceItems: [],
  /** @type {boolean} */ isLoadingShippingPrices: false,
}

let isLoadingCarriers = false
let isLoadingShippingMethods = false
let isLoadingIncomingShippingMethods = false
let isLoadingShippingProducts = false
let areShippingMethodsLoaded = false
let areIncomingShippingMethodsLoaded = false

// Used to track the promises of lazy store actions.
// Enables multiple concurrent dispatches of a lazy action which will resolve at the same time
// because they await the same underlying promise.
let SHIPPING_FETCH_CARRIERS_LAZILY_PROMISE = null
let SHIPPING_FETCH_SHIPPING_METHODS_LAZILY_PROMISE = null
let SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY_PROMISE = null
let SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY_PROMISE = null
let SHIPPING_FETCH_SHIPPING_PRICES_LAZILY_PROMISE = null

const getters = {
  carrierById: (/** @type {ModuleState} */ state) => id =>
    state.carriers.find(item => item.id === id) || /** @type {Carrier} */ (UNKNOWN_CARRIER),

  carrierByCode: (/** @type {ModuleState} */ state) => code =>
    state.carriers.find(item => item.code === code) || /** @type {Carrier} */ (UNKNOWN_CARRIER),

  carriers: (/** @type {ModuleState} */ state) => state.carriers,

  hasActionRequired: (/** @type {ModuleState} */ state) => state.hasActionRequired,

  shippingFunctionalities: (/** @type {ModuleState} */ state) => state.shippingFunctionalities,

  shippingMethods: (/** @type {ModuleState} */ state) => state.shippingMethods,

  incomingShippingMethods: (/** @type {ModuleState} */ state) => state.incomingShippingMethods,

  shippingMethodById: state => id =>
    Array.isArray(state.shippingMethods) ? state.shippingMethods.find(item => item.id === id) : undefined,

  incomingShippingMethodById: state => id =>
    Array.isArray(state.incomingShippingMethods) && state.incomingShippingMethods.find(item => item.id === id),

  shippingMethodsByWeight: (/** @type {ModuleState} */ state) => (weight, shippingMethods) => {
    // Filters the shipping methods by the given weight.
    // If no methods are provided we will get all available shipping methods.
    if (shippingMethods == null) {
      shippingMethods = state.shippingMethods
    }
    const weightFloat = Math.max(parseFloat(weight), 0)

    return shippingMethods.filter((sm) => {
      return parseFloat(sm.min_weight) <= weightFloat && weightFloat < parseFloat(sm.max_weight)
    })
  },

  announcementProgress: (/** @type {ModuleState} */ state) => state.announcementProgress,

  shippingProducts: (/** @type {ModuleState} */ state) => state.shippingProducts,

  notificationHistory: (/** @type {ModuleState} */ state) => state.notificationHistory,

  hasEnabledCarrier: (/** @type {ModuleState} */ state) => state.carriers.some(carrier => carrier.is_enabled),

  shippingSelectedActions: (/** @type {ModuleState} */ state) => state.selectedActions,

  shippingPriceItems: (/** @type {ModuleState} */ state) => state.shippingPriceItems,

  shippingPriceItemByOrderId: (/** @type {ModuleState} */ state) => (/** @type {number} */ orderId) => {
    return state.shippingPriceItems.find(shippingPriceItem => shippingPriceItem.id === orderId)
  },

  isLoadingShippingPrices: (/** @type {ModuleState} */ state) => state.isLoadingShippingPrices,
}

const actions = {
  async [SHIPPING_CHECK_FOR_ACTION_REQUIRED]({ commit }) {
    const hasActionRequired = await ParcelService.checkForPendingParcels()
    commit(SHIPPING_SET_HAS_ACTION_REQUIRED, hasActionRequired)
  },

  async [SHIPPING_FETCH_CARRIERS]({ commit }) {
    if (isLoadingCarriers) {
      return
    }

    isLoadingCarriers = true
    const carriers = await CarrierApi.findAll()
    isLoadingCarriers = false
    commit(SHIPPING_SET_CARRIERS, carriers)
  },

  async [SHIPPING_FETCH_CARRIERS_LAZILY]({ dispatch, state }) {
    if (state.carriers.length === 0) {
      // If there isn’t already a fetch in progress, trigger one
      if (SHIPPING_FETCH_CARRIERS_LAZILY_PROMISE === null) {
        SHIPPING_FETCH_CARRIERS_LAZILY_PROMISE = dispatch(SHIPPING_FETCH_CARRIERS)
      }

      // Await the fetch promise and reset the promise reference
      await SHIPPING_FETCH_CARRIERS_LAZILY_PROMISE
      SHIPPING_FETCH_CARRIERS_LAZILY_PROMISE = null
    }
  },

  async [SHIPPING_FETCH_SHIPPING_FUNCTIONALITIES]({ commit }) {
    const shippingFunctionalities = await fetchShippingFunctionalities()
    commit(SHIPPING_SET_SHIPPING_FUNCTIONALITIES, shippingFunctionalities)
  },

  async [SHIPPING_FETCH_SHIPPING_METHODS]({ commit }) {
    if (isLoadingShippingMethods) {
      return
    }

    isLoadingShippingMethods = true
    const shippingMethods = await ShippingMethodApi.findAll()
    isLoadingShippingMethods = false
    commit(SHIPPING_SET_SHIPPING_METHODS, shippingMethods)
    areShippingMethodsLoaded = true
  },

  async [SHIPPING_FETCH_SHIPPING_METHODS_LAZILY]({ dispatch }) {
    if (!areShippingMethodsLoaded) {
      // If there isn’t already a fetch in progress, trigger one
      if (SHIPPING_FETCH_SHIPPING_METHODS_LAZILY_PROMISE === null) {
        SHIPPING_FETCH_SHIPPING_METHODS_LAZILY_PROMISE = dispatch(SHIPPING_FETCH_SHIPPING_METHODS)
      }

      // Await the fetch promise and reset the promise reference
      await SHIPPING_FETCH_SHIPPING_METHODS_LAZILY_PROMISE
      SHIPPING_FETCH_SHIPPING_METHODS_LAZILY_PROMISE = null
    }
  },

  /**
   * @param {object} context
   * @param {ShippingProductParameters} [params]
   */
  async [SHIPPING_FETCH_SHIPPING_PRODUCTS]({ commit }, params = {}) {
    if (isLoadingShippingProducts) {
      return
    }

    isLoadingShippingProducts = true
    const shippingProducts = await fetchShippingProducts(params)
    isLoadingShippingProducts = false
    commit(SHIPPING_SET_SHIPPING_PRODUCTS, shippingProducts)
  },

  async [SHIPPING_FETCH_INCOMING_SHIPPING_METHODS]({ commit }) {
    if (isLoadingIncomingShippingMethods) {
      return
    }

    isLoadingIncomingShippingMethods = true
    const shippingMethods = await ShippingMethodApi.findByParams({ direction: DIRECTION_INCOMING })
    isLoadingIncomingShippingMethods = false
    commit(SHIPPING_SET_INCOMING_SHIPPING_METHODS, shippingMethods)
    areIncomingShippingMethodsLoaded = true
  },

  async [SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY]({ dispatch, state }) {
    if (state.shippingProducts.length === 0) {
      // If there isn’t already a fetch in progress, trigger one
      if (SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY_PROMISE === null) {
        SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY_PROMISE = dispatch(SHIPPING_FETCH_SHIPPING_PRODUCTS)
      }

      // Await the fetch promise and reset the promise reference
      await SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY_PROMISE
      SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY_PROMISE = null
    }
  },

  async [SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY]({ dispatch }) {
    if (!areIncomingShippingMethodsLoaded) {
      // If there isn’t already a fetch in progress, trigger one
      if (SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY_PROMISE === null) {
        SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY_PROMISE = dispatch(SHIPPING_FETCH_INCOMING_SHIPPING_METHODS)
      }

      // Await the fetch promise and reset the promise reference
      await SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY_PROMISE
      SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY_PROMISE = null
    }
  },

  async [SHIPPING_FETCH_SHIPPING_PRICES_LAZILY]({ dispatch }, orders = []) {
    if (orders.length === 0) {
      return
    }

    // If there isn’t already a fetch in progress, trigger one
    if (SHIPPING_FETCH_SHIPPING_PRICES_LAZILY_PROMISE === null) {
      SHIPPING_FETCH_SHIPPING_PRICES_LAZILY_PROMISE = dispatch(SHIPPING_FETCH_SHIPPING_PRICES, orders)
    }

    // Await the fetch promise and reset the promise reference
    await SHIPPING_FETCH_SHIPPING_PRICES_LAZILY_PROMISE
    SHIPPING_FETCH_SHIPPING_PRICES_LAZILY_PROMISE = null
  },

  async [SHIPPING_FETCH_SHIPPING_PRICES]({ commit }, orders = []) {
    if (state.isLoadingShippingPrices) {
      return
    }

    commit(SHIPPING_SET_LOADING_SHIPPING_PRICES, true)
    commit(
      SHIPPING_RESET_SHIPPING_PRICES_BY_ORDER_IDS,
      orders.map(order => order.id),
    )

    let shippingPriceItems = []
    try {
      const shippingPricesResponse = await ShippingPricesApi.getMultipleShippingPrices(orders)
      shippingPriceItems = shippingPricesResponse.data.data

      commit(SHIPPING_SET_SHIPPING_PRICES, shippingPriceItems)
    } catch {
      commit(SHIPPING_SET_SHIPPING_PRICES, shippingPriceItems)
    } finally {
      commit(SHIPPING_SET_LOADING_SHIPPING_PRICES, false)
    }
  },

  async [SHIPPING_FETCH_NOTIFICATION_HISTORY]({ commit }, { id, betaFlag = false }) {
    let history
    try {
      history = await fetchNotificationHistory(id, betaFlag)
    } catch {
      history = []
    }
    commit(SHIPPING_SET_NOTIFICATION_HISTORY, history)
  },
}

const mutations = {
  /**
   * @param {ModuleState} state
   * @param {Array<Carrier>} carriers
   */
  [SHIPPING_SET_CARRIERS](state, carriers) {
    state.carriers = carriers
  },

  /**
   * @param {ModuleState} state
   * @param {boolean} hasActionRequired
   */
  [SHIPPING_SET_HAS_ACTION_REQUIRED](state, hasActionRequired) {
    state.hasActionRequired = hasActionRequired
  },

  /**
   * @param {ModuleState} state
   * @param {ShippingFunctionalityDefinitions} shippingFunctionalities
   */
  [SHIPPING_SET_SHIPPING_FUNCTIONALITIES](state, shippingFunctionalities) {
    state.shippingFunctionalities = shippingFunctionalities
  },

  /**
   * @param {ModuleState} state
   * @param {Array<ShippingMethod>} shippingMethods
   */
  [SHIPPING_SET_SHIPPING_METHODS](state, shippingMethods) {
    state.shippingMethods = shippingMethods
  },

  /**
   * @param {ModuleState} state
   * @param {Array<ShippingMethod>} shippingMethods
   */
  [SHIPPING_SET_INCOMING_SHIPPING_METHODS](state, shippingMethods) {
    state.incomingShippingMethods = shippingMethods
  },

  /**
   * @param {ModuleState} state
   * @param {Array<ShippingProduct>} shippingProducts
   */
  [SHIPPING_SET_SHIPPING_PRODUCTS](state, shippingProducts) {
    state.shippingProducts = shippingProducts
  },

  /**
   * @param {ModuleState} state
   * @param {Array<NotificationStatus>} notificationHistory
   */
  [SHIPPING_SET_NOTIFICATION_HISTORY](state, notificationHistory) {
    state.notificationHistory = notificationHistory
  },

  /**
   * @param {ModuleState} state
   * @param {Array<string>} selectedActions
   */
  [SHIPPING_SET_SELECTED_ACTIONS](state, selectedActions) {
    state.selectedActions = selectedActions
  },

  /**
   * @param {ModuleState} state
   * @param {AnnouncementProgress} announcementProgress
   */
  [SHIPPING_SET_ANNOUNCEMENT_PROGRESS](state, announcementProgress) {
    if (announcementProgress.total !== undefined) {
      state.announcementProgress.total = announcementProgress.total
    }
    if (announcementProgress.pending !== undefined) {
      state.announcementProgress.pending = announcementProgress.pending
    }
  },

  /**
   * @param {ModuleState} state
   * @param {boolean} isLoading
   */
  [SHIPPING_SET_LOADING_SHIPPING_PRICES](state, isLoading) {
    state.isLoadingShippingPrices = isLoading
  },

  /**
   * @param {ModuleState} state
   * @param {Array<ShippingPriceItem>} shippingPriceItems
   */
  [SHIPPING_SET_SHIPPING_PRICES](state, shippingPriceItems) {
    // ensure store is not keeping duplicate prices for single shipments, newest price is leading
    const mergeArrayUniqueValuesById = (array1, array2) => {
      return Array.from([...array1, ...array2].reduce((m, o) => m.set(o.id, o), new Map()).values())
    }

    state.shippingPriceItems = mergeArrayUniqueValuesById(state.shippingPriceItems, shippingPriceItems)
  },

  /**
   * Reset shipping price when refetching shipping price for specific order(s) to reflect loading state within the UI
   * @param {ModuleState} state
   * @param {string[]} orderIds
   */
  [SHIPPING_RESET_SHIPPING_PRICES_BY_ORDER_IDS](state, orderIds) {
    state.shippingPriceItems = state.shippingPriceItems.filter(order => !orderIds.includes(order.id))
  },

  /**
   * @param {ModuleState} state
   */
  [SHIPPING_RESET_SHIPPING_PRICES](state) {
    state.shippingPriceItems = []
  },
}

const resetShippingMethodsLoaded = () => (areShippingMethodsLoaded = false)

export default {
  state,
  getters,
  actions,
  mutations,
  resetShippingMethodsLoaded,
}
