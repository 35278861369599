import axios from '@/common/utils/axios'
import i18n from '@/application/i18n/i18n'
import { NormalizedError } from '@/common/utils/errors/normalize-error'
import type { ShippingMethod, ShippingMethodDeliveryDay, ShippingMethodState } from '@/types/models'
import type { FilterParams } from '@/features/shipment-tabs/types/shipping-method-api.types'

export default {

  async findAll(): Promise<ShippingMethod[]> {
    const response = await axios.get('/xhr/shippingmethod')
    return response.data
  },

  async findByParams(params: FilterParams = {}): Promise<ShippingMethod[]> {
    const response = await axios.get('/xhr/shippingmethod', { params })
    return response.data
  },

  async findDeliveryDays(shipping_product_code: string): Promise<ShippingMethodDeliveryDay[]> {
    try {
      const response = await axios.get('/xhr/shippingmethod/delivery-days', {
        params: {
          shipping_product_code: shipping_product_code,
        },
      })
      return response.data
    } catch {
      const message = i18n.t('Could not load shipping method delivery days.')
      throw new NormalizedError(message, 'api.shippingmethod.failed_to_load_delivery_days')
    }
  },

  async fetchDestinationCountries(): Promise<string[]> {
    try {
      const response = await axios.get('/xhr/shippingmethod/destination-countries')
      return response.data.destination_countries
    } catch {
      const message = i18n.t('Could not load destination countries')
      throw new NormalizedError(message, 'api.shippingmethod.failed_to_load_destination_countries')
    }
  },

  /**
   * Toggles the state of one or multiple shipping methods.
   */
  async toggleShippingMethodState(shippingMethodsToToggle: ShippingMethodState[]): Promise<ShippingMethodState[]> {
    const response = await axios.post('/xhr/shippingmethod/toggle', shippingMethodsToToggle)
    return response.data
  },
}
