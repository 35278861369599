export const CORE_SET_COUNTRIES = 'CORE_SET_COUNTRIES'
export const CORE_SET_CURRENCIES = 'CORE_SET_CURRENCIES'
export const CORE_SET_DATA_DUMP = 'CORE_SET_DATA_DUMP'
export const CORE_SET_GLOBAL_SETTINGS = 'CORE_SET_GLOBAL_SETTINGS'
export const CORE_SET_PAGE_TITLE = 'CORE_SET_PAGE_TITLE'
export const CORE_SET_PAYMENT_INVOICE_INFO = 'CORE_SET_PAYMENT_INVOICE_INFO'
export const CORE_SET_PAYMENT_WALL_TYPE = 'CORE_SET_PAYMENT_WALL_TYPE'
export const CORE_SET_SEARCH_QUERY_SHIPMENTS = 'CORE_SET_SEARCH_QUERY_SHIPMENTS'
export const CORE_SET_SIDEBAR_VISIBILITY = 'CORE_SET_SIDEBAR_VISIBILITY'
export const CORE_SET_STYLED_PORTAL = 'CORE_SET_STYLED_PORTAL'
export const CORE_SET_UNSAVED_CHANGES = 'CORE_SET_UNSAVED_CHANGES'
export const CORE_SET_USER = 'CORE_SET_USER'
export const CORE_SET_USER_BETA_COMMUNITY = 'CORE_SET_USER_BETA_COMMUNITY'
