import i18n from '@/application/i18n/i18n'
import { faList, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { faCircleQuestion, faHeadSideHeadphones } from '@fortawesome/pro-regular-svg-icons'
import type { RouteRecordRaw } from 'vue-router'
import type { RouteButton } from '@/common/types/route.types'

export const ISSUE_MANAGEMENT_ROUTE = 'issue-management'
export const ISSUE_MANAGEMENT_MY_CONTRACTS_ROUTE = 'issue-management.my_contracts'
export const ISSUE_MANAGEMENT_SENDCLOUD_CONTRACTS_ROUTE = 'issue-management.sendcloud_contracts'
export const ISSUE_MANAGEMENT_INSURANCE_ROUTE = 'issue-management.insurance'
export const ISSUE_MANAGEMENT_DETAILS_ROUTE = 'issue-management.details'
export const ISSUE_MANAGEMENT_NEW_TICKET_ROUTE = 'issue-management.new-ticket'

export const SUPPORT_ROUTE = 'support.tickets'
export const SUPPORT_LEGACY_TICKETS_ROUTE = 'support.legacy.tickets'
export const SUPPORT_NEW_TICKET_ROUTE = 'support.newTicket'
export const SUPPORT_TICKET_DETAILS_ROUTE = 'support.ticketDetails'
export const SUPPORT_SHIPMENTS_TICKET_LIST = 'support.shipments.ticketList'
export const SUPPORT_PLATFORM_TICKET_LIST = 'support.platform.ticketList'
export const SUPPORT_OWN_CONTRACTS_TICKET_LIST = 'support.ownContracts.ticketList'
export const SUPPORT_TICKET_DRAFT_LIST = 'support.ticketDraftList'
export const SUPPORT_INSURANCE_LIST = 'support.insurance.list'
export const SUPPORT_REFUND_CLAIMS = 'support.refundClaims'
export const SUPPORT_CLAIM = 'support.claim'
const SUPPORT_AGENT_LOGIN = 'support.agent-login'

const btnHelpCenter: RouteButton = {
  id: 'open-help-center',
  title: () => i18n.t('Help center'),
  name: 'https://support.sendcloud.com',
  newTab: true,
  icon: faCircleQuestion,
  mode: 'secondary',
  permissions: ['manage_tickets'],
}

const btnListTickets: RouteButton = {
  id: 'support-ticket-list',
  title: () => i18n.t('View tickets'),
  name: SUPPORT_ROUTE,
  icon: faList,
  mode: 'primary',
  permissions: ['manage_tickets'],
}

const btnAgentLogin: RouteButton = {
  id: 'agent-login',
  name: SUPPORT_AGENT_LOGIN,
  title: () => i18n.t('Agent login'),
  mode: 'secondary',
  icon: faHeadSideHeadphones,
  permissions: ['manage_tickets'],
}

const btnNewTicket: RouteButton = {
  id: 'new-ticket',
  name: SUPPORT_NEW_TICKET_ROUTE,
  title: () => i18n.t('New ticket'),
  mode: 'primary',
  icon: faPlus,
  permissions: ['manage_tickets'],
}

const btnNewTicketIssueMgmt: RouteButton = {
  id: 'new-ticket',
  name: ISSUE_MANAGEMENT_NEW_TICKET_ROUTE,
  title: () => i18n.t('New ticket'),
  mode: 'primary',
  icon: faPlus,
  permissions: ['manage_tickets'],
}

export default [
  {
    name: ISSUE_MANAGEMENT_ROUTE,
    path: '/issues',
    component: () => import('@/features/support/components/SupportNav.vue'),
    redirect: { name: ISSUE_MANAGEMENT_SENDCLOUD_CONTRACTS_ROUTE },
    props: {
      isIssueManagementNavigation: true,
    },
    meta: {
      pageTitle: () => i18n.t('support.issue_management.title'),
      permissions: ['manage_tickets'],
      buttons: [btnHelpCenter, btnAgentLogin, btnNewTicketIssueMgmt],
      showUserId: true,
      parent: ISSUE_MANAGEMENT_ROUTE,
    },
    children: [
      {
        name: ISSUE_MANAGEMENT_SENDCLOUD_CONTRACTS_ROUTE,
        path: '/issues/shipments',
        component: () => import('@/features/support/pages/SupportTicketListShipmentsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('support.issue_management.title'),
          permissions: ['manage_tickets'],
          parent: ISSUE_MANAGEMENT_ROUTE,
        },
      },
      {
        name: ISSUE_MANAGEMENT_MY_CONTRACTS_ROUTE,
        path: '/issues/my-contracts',
        component: () => import('@/features/support/pages/SupportTicketListOwnContractsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('support.issue_management.title'),
          permissions: ['manage_tickets'],
          parent: ISSUE_MANAGEMENT_ROUTE,
        },
      },
      {
        name: ISSUE_MANAGEMENT_INSURANCE_ROUTE,
        path: '/issues/insurance',
        component: () => import('@/features/support/pages/SupportTicketListOwnContractsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('support.issue_management.title'),
          permissions: ['manage_tickets'],
          parent: ISSUE_MANAGEMENT_ROUTE,
        },
      },
    ],
  },
  {
    name: SUPPORT_ROUTE,
    path: '/support',
    component: () => import('@/features/support/components/SupportNav.vue'),
    redirect: { name: SUPPORT_SHIPMENTS_TICKET_LIST },
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnHelpCenter, btnAgentLogin, btnNewTicket],
      showUserId: true,
      parent: 'support',
    },
    children: [
      {
        name: SUPPORT_LEGACY_TICKETS_ROUTE,
        path: '/support/tickets',
        redirect: { name: SUPPORT_SHIPMENTS_TICKET_LIST },
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
      {
        name: SUPPORT_SHIPMENTS_TICKET_LIST,
        path: '/support/tickets/shipments',
        component: () => import('@/features/support/pages/SupportTicketListShipmentsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
      {
        name: SUPPORT_PLATFORM_TICKET_LIST,
        path: '/support/tickets/platform',
        component: () => import('@/features/support/pages/SupportTicketListPlatformPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
      {
        name: SUPPORT_INSURANCE_LIST,
        path: '/support/tickets/insurance',
        component: () => import('@/features/support/pages/SupportTicketListInsurancePage.vue'),
        meta: {
          pageTitle: () => i18n.t('Insurance'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
      {
        name: SUPPORT_OWN_CONTRACTS_TICKET_LIST,
        path: '/support/tickets/own-contracts',
        component: () => import('@/features/support/pages/SupportTicketListOwnContractsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
      {
        name: SUPPORT_TICKET_DRAFT_LIST,
        path: '/support/tickets/drafts',
        component: () => import('@/features/support/pages/SupportTicketListDraftsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
    ],
  },
  {
    name: SUPPORT_NEW_TICKET_ROUTE,
    path: '/support/new-ticket',
    component: () => import('@/features/support/pages/SupportNewTicketFormPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnHelpCenter],
      showUserId: true,
      parent: 'support',
    },
    props: route => ({
      categoryId: route.query.categoryId ?? null,
      presetSupportTypeId: route.query.supportType ?? null,
      presetTrackingNumber: route.query.trackingNumber ?? null,
      invoiceId: route.query.invoiceId ?? null,
      invoiceItemId: route.query.invoiceItemId ?? null,
    }),
  },
  {
    name: ISSUE_MANAGEMENT_NEW_TICKET_ROUTE,
    path: '/issues/new-ticket',
    component: () => import('@/features/support/pages/SupportNewTicketFormPage.vue'),
    meta: {
      pageTitle: () => i18n.t('support.issue_management.title'),
      permissions: ['manage_tickets'],
      buttons: [btnHelpCenter],
      showUserId: true,
    },
    props: route => ({
      categoryId: route.query.categoryId ?? null,
      presetSupportTypeId: route.query.supportType ?? null,
      presetTrackingNumber: route.query.trackingNumber ?? null,
      invoiceId: route.query.invoiceId ?? null,
      invoiceItemId: route.query.invoiceItemId ?? null,
    }),
  },
  {
    name: SUPPORT_TICKET_DETAILS_ROUTE,
    path: '/support/ticket/:ticketType/:id',
    props: route => ({
      ticketType: route.params.ticketType,
      id: Number.parseInt(route.params.id as string),
    }),
    component: () => import('@/features/support/pages/SupportTicketDetailsPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnHelpCenter],
      showUserId: true,
    },
  },
  {
    name: ISSUE_MANAGEMENT_DETAILS_ROUTE,
    path: '/issues/:ticketType/:id',
    props: route => ({
      ticketType: route.params.ticketType,
      id: Number.parseInt(route.params.id as string),
    }),
    component: () => import('@/features/support/pages/SupportTicketDetailsPage.vue'),
    meta: {
      pageTitle: () => i18n.t('support.issue_management.title'),
      permissions: ['manage_tickets'],
      buttons: [btnHelpCenter],
      showUserId: true,
    },
  },
  {
    name: SUPPORT_REFUND_CLAIMS,
    path: '/support/refund-claim/:action(accept|wait)',
    props: route => ({
      action: route.params.action,
      payload: route.query.payload,
    }),
    component: () => import('@/features/support/pages/RefundClaimPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnListTickets, btnHelpCenter],
      showUserId: true,
      parent: 'support',
    },
  },
  {
    name: SUPPORT_CLAIM,
    path: '/support/claim/manage/:payload',
    props: route => ({
      payload: route.params.payload,
    }),
    component: () => import('@/features/support/pages/GenericRefundClaimPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnListTickets, btnHelpCenter],
      showUserId: true,
      parent: 'support',
    },
  },
] satisfies RouteRecordRaw[]
