import type { Commit } from 'vuex'
import { SUPPORT_GO_TO_DRAFT_TICKET } from './action.types'
import { SUPPORT_SET_DRAFT_TICKET } from './mutation.types'

type SupportState = {
  selectedDraftTicket: string | null
}

const state: SupportState = {
  selectedDraftTicket: null,
}

const getters = {
  selectedDraftTicket: (state: SupportState) => state.selectedDraftTicket,
}

const actions = {
  [SUPPORT_GO_TO_DRAFT_TICKET]({ commit }: { commit: Commit }, selectedDraftTicket: string) {
    commit(SUPPORT_SET_DRAFT_TICKET, selectedDraftTicket)
  },
}

const mutations = {
  [SUPPORT_SET_DRAFT_TICKET](state: SupportState, selectedDraftTicket: string) {
    state.selectedDraftTicket = selectedDraftTicket
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
