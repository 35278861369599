<template>
  <nav
    class="aside-navigation__main"
    aria-label="main navigation"
  >
    <ul>
      <AppSidebarNavigationItem
        v-if="userHasOpportunityCenter || userHasOpportunityCenterCio"
        id="discover-sendcloud-item"
        parent-section="discover-sendcloud"
        :href="{ name: OPPORTUNITY_CENTER_ROUTE }"
        :icon="faRocketLaunch"
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isActiveMenu('discover-sendcloud'),
          'aside-navigation__item --notification': opportunityNotification,
        }"
      >
        <span class="aside-navigation__link-name">
          {{ t('opportunity_center.title') }}
        </span>
        <span
          v-if="opportunityNotification"
          id="aside-navigation__item-counter"
          class="aside-navigation__item-counter"
        >
          {{ opportunityNotification }}
        </span>
      </AppSidebarNavigationItem>
      <li
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Shipping') || isActiveMenu('Shipping'),
        }"
      >
        <UiTooltip
          v-if="!isExpanded && !isSubmenuOpen('Shipping') && !isMobileMenuVisible"
          for="shipping-sidebar-item"
          :text="t('Shipping')"
          placement="right"
          :offset-size="22"
        />
        <button
          id="shipping-sidebar-item"
          class="aside-navigation__item-link"
          data-test="smoke-test-shipping"
          @click.prevent="toggleSubmenu('Shipping')"
        >
          <Fa
            :icon="faTruckFast"
            class="aside-navigation__item-icon"
          />
          <span class="aside-navigation__link-name">{{ t('Shipping') }}</span>
          <Fa
            :icon="faChevronDown"
            :class="['aside-navigation__icon--submenu', 'chevron-sidebar', { 'rotate-180': isSubmenuOpen('Shipping') }]"
          />
        </button>
        <ul
          v-show="isSubmenuOpen('Shipping')"
          class="aside-navigation__submenu"
        >
          <span class="aside-navigation__link-name-collapsed">{{ t('Shipping') }}</span>
          <AppSidebarNavigationItem
            v-if="permissions.view_parcels || permissions.manage_shipping || permissions.process_orders"
            id="shipping-orders"
            data-test="smoke-test-shipping-orders"
            parent-section="shipping"
            :href="{ name: SHIPPING_INCOMING_ORDERS_ROUTE }"
          >
            <span class="aside-navigation__link-name">
              {{ t('aside.navigation.orders') }}
            </span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipping-pack-go"
            parent-section="shipping"
            :href="{ name: PACKGO_QUEUE_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Pack & Go') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipping-pickups"
            parent-section="shipping"
            :href="{ name: PICKUPS_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Pickups') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipping-prices"
            parent-section="shipping"
            :href="{ name: SETTINGS_RATES_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.shipping_prices') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipping-carriers"
            parent-section="shipping"
            :href="{ name: SETTINGS_CARRIERS_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Carriers') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipping-defaults"
            parent-section="shipping"
            :href="{ name: SETTINGS_SHIPPING_DEFAULTS_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.shipping_defaults') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipping-rules"
            parent-section="shipping"
            :href="{ name: SETTINGS_SHIPPING_RULES_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.shipping_rules') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipment-protection"
            parent-section="shipping"
            :href="{ name: SHIPMENT_PROTECTION_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('settings.shipping.protection.header.title') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="shipping-boxes"
            parent-section="shipping"
            :href="{ name: SETTINGS_BOXES_LIST_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Boxes') }}</span>
          </AppSidebarNavigationItem>

          <AppSidebarNavigationItem
            id="shipping-printing"
            parent-section="shipping"
            :href="{ name: SETTINGS_PRINTING_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.printing_options') }}</span>
          </AppSidebarNavigationItem>
        </ul>
      </li>
      <li
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Returns') || isActiveMenu('Returns'),
        }"
      >
        <UiTooltip
          v-if="!isExpanded && !isSubmenuOpen('Returns') && !isMobileMenuVisible"
          for="returns-sidebar-item"
          :text="t('Returns')"
          placement="right"
          :offset-size="22"
        />
        <button
          id="returns-sidebar-item"
          class="aside-navigation__item-link"
          @click.prevent="toggleSubmenu('Returns')"
        >
          <Fa
            :icon="faRotateLeft"
            class="aside-navigation__item-icon"
          />
          <span class="aside-navigation__link-name">{{ t('Returns') }}</span>
          <Fa
            :icon="faChevronDown"
            :class="['aside-navigation__icon--submenu', 'chevron-sidebar', { 'rotate-180': isSubmenuOpen('Returns') }]"
          />
        </button>
        <ul
          v-show="isSubmenuOpen('Returns')"
          class="aside-navigation__submenu"
        >
          <span class="aside-navigation__link-name-collapsed">{{ t('Returns') }}</span>
          <AppSidebarNavigationItem
            v-if="user.is_returns_enabled && permissions.manage_returns"
            id="returns-overview"
            parent-section="returns"
            data-test="returns"
            :href="{ name: RETURNS_LIST_ROUTE }"
          >
            <span class="aside-navigation__link-name"> {{ t('aside.navigation.overview') }} </span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.configure_returns"
            id="returns-default"
            parent-section="returns"
            :href="{ name: SETTINGS_RETURN_DEFAULTS_ROUTE }"
          >
            <span class="aside-navigation__link-name">
              {{ t('Return defaults') }}
            </span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.configure_returns"
            id="returns-rules"
            parent-section="returns"
            :href="{ name: SETTINGS_RETURN_RULES_LIST_ROUTE }"
          >
            <span class="aside-navigation__link-name"> {{ t('Return rules') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.configure_returns"
            id="returns-portal"
            parent-section="returns"
            :href="{ name: SETTINGS_RETURNS_ROUTE }"
          >
            <span class="aside-navigation__link-name">
              {{ t('Return portal') }}
            </span>
          </AppSidebarNavigationItem>
        </ul>
      </li>

      <template v-if="userHasParcelMonitorV2BetaFeature || userHasSupportAutomationEnabled">
        <li
          class="aside-navigation__item"
          :class="{ 'aside-navigation__item--active': isSubmenuOpen('Support automation') || isActiveMenu('Support automation') }"
        >
          <UiTooltip
            v-if="!isExpanded && !isSubmenuOpen('Support automation') && !isMobileMenuVisible"
            for="parcel-monitor-sidebar-item"
            :text="t('support.automation.title')"
            placement="right"
            :offset-size="22"
          />
          <button
            id="parcel-monitor-sidebar-item"
            class="aside-navigation__item-link"
            @click.prevent="toggleSubmenu('Support automation')"
          >
            <Fa
              :icon="faArrowsRotate"
              class="aside-navigation__item-icon"
            />
            <span class="aside-navigation__link-name">{{ t('support.automation.title') }}</span>
            <Fa
              :icon="faChevronDown"
              class="aside-navigation__icon--submenu chevron-sidebar"
              :class="{ 'rotate-180': isSubmenuOpen('Support automation') }"
            />
          </button>
          <ul
            v-show="isSubmenuOpen('Support automation')"
            class="aside-navigation__submenu"
          >
            <template v-if="userHasParcelMonitorV2BetaFeature">
              <span class="aside-navigation__link-name-collapsed">{{ t('support.parcel_monitor.title') }}</span>
              <AppSidebarNavigationItem
                id="parcel-monitor"
                parent-section="parcel-monitor"
                data-test="parcel-monitor"
                :href="{ name: PARCEL_MONITOR_V2_ROUTE }"
              >
                <span class="aside-navigation__link-name">{{ t('support.parcel_monitor.title') }}</span>
              </AppSidebarNavigationItem>
            </template>
            <template v-if="userHasSupportAutomationEnabled">
              <span class="aside-navigation__link-name-collapsed">{{ t('support.issue_management.title') }}</span>
              <AppSidebarNavigationItem
                id="issue-management"
                parent-section="issue-management"
                data-test="issue-management"
                :href="{ name: ISSUE_MANAGEMENT_ROUTE }"
              >
                <span class="aside-navigation__link-name">{{ t('support.issue_management.title') }}</span>
              </AppSidebarNavigationItem>
            </template>
          </ul>
        </li>
      </template>

      <UiTooltip
        v-if="!isExpanded && !isMobileMenuVisible"
        for="appstore-sidebar-item"
        :text="t('main.navigation.appstore.title')"
        placement="right"
        :offset-size="22"
      />
      <AppSidebarNavigationItem
        id="appstore-sidebar-item"
        target="_blank"
        rel="noopener noreferrer"
        href="https://app-store.sendcloud.com/"
        :icon="faStore"
      >
        <span class="aside-navigation__link-name"> {{ $t('main.navigation.appstore.title') }}</span>
        <Fa
          :icon="faArrowUpRightFromSquare"
          class="external-icon"
        />
      </AppSidebarNavigationItem>

      <li
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Tracking') || isActiveMenu('Tracking'),
        }"
      >
        <UiTooltip
          v-if="!isExpanded && !isSubmenuOpen('Tracking') && !isMobileMenuVisible"
          for="tracking-sidebar-item"
          :text="t('Tracking')"
          placement="right"
          :offset-size="22"
        />
        <button
          id="tracking-sidebar-item"
          class="aside-navigation__item-link"
          @click.prevent="toggleSubmenu('Tracking')"
        >
          <Fa
            :icon="faEnvelope"
            class="aside-navigation__item-icon"
          />
          <span class="aside-navigation__link-name">{{ t('Tracking') }}</span>
          <Fa
            :icon="faChevronDown"
            :class="['aside-navigation__icon--submenu', 'chevron-sidebar', { 'rotate-180': isSubmenuOpen('Tracking') }]"
          />
        </button>
        <ul
          v-show="isSubmenuOpen('Tracking')"
          class="aside-navigation__submenu"
        >
          <span class="aside-navigation__link-name-collapsed">{{ t('Tracking') }}</span>

          <!-- NEW TRACKING NOTIFICATIONS PAGES UNDER BETA FEATURE -->
          <template v-if="hasTrackingNotificationsBetaFeature">
            <AppSidebarNavigationItem
              id="email-notifications"
              parent-section="tracking"
              :href="{ name: SETTINGS_TRACKING_NOTIFICATIONS_EMAIL_ROUTE }"
            >
              <span class="aside-navigation__link-name">{{ t('aside.navigation.email_notifications') }}</span>
            </AppSidebarNavigationItem>
            <AppSidebarNavigationItem
              id="sms-notifications"
              parent-section="tracking"
              :href="{ name: SETTINGS_TRACKING_NOTIFICATIONS_SMS_ROUTE }"
            >
              <span class="aside-navigation__link-name">{{ t('aside.navigation.sms_notifications') }}</span>
            </AppSidebarNavigationItem>
            <AppSidebarNavigationItem
              id="whatsapp-notifications"
              parent-section="tracking"
              :href="{ name: SETTINGS_TRACKING_NOTIFICATIONS_WHATSAPP_ROUTE }"
            >
              <span class="aside-navigation__link-name">{{ t('aside.navigation.whatsapp_notifications') }}</span>
            </AppSidebarNavigationItem>
          </template>
          <!-- /NEW TRACKING NOTIFICATIONS PAGES UNDER BETA FEATURE -->

          <AppSidebarNavigationItem
            v-else
            id="tracking-messages"
            parent-section="tracking"
            :href="{ name: SETTINGS_NOTIFY_TRACKING_MESSAGES_EMAIL_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.tracking_messages') }}</span>
          </AppSidebarNavigationItem>

          <AppSidebarNavigationItem
            id="tracking-page"
            parent-section="tracking"
            :href="{ name: SETTINGS_NOTIFY_TRACKING_PAGE_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.tracking_page') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="tracking-widget"
            parent-section="tracking"
            :href="{ name: SETTINGS_NOTIFY_TRACKING_WIDGET_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.tracking_portal') }}</span>
          </AppSidebarNavigationItem>
        </ul>
      </li>

      <UiTooltip
        v-if="!isExpanded && !isMobileMenuVisible && hasConversationsBetaFeature"
        for="conversations-sidebar-item"
        :text="t('whatsapp_replies.page.title')"
        placement="right"
        :offset-size="22"
      />
      <AppSidebarNavigationItem
        v-if="hasConversationsBetaFeature"
        id="conversations-sidebar-item"
        parent-section="conversations"
        :href="{ name: WHATSAPP_CONVERSATIONS_ROUTE }"
        :icon="faComment"
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Conversations') || isActiveMenu('Conversations'),
        }"
      >
        <span
          :class="[
            'aside-navigation__link-name',
            { 'aside-navigation__link-conversations--padded': !!unreadMessagesCounterText },
          ]"
        >
          {{ t('whatsapp_replies.page.title') }}
        </span>
        <span
          v-if="unreadMessagesCounterText"
          class="aside-navigation__unread-counter"
        >
          {{ unreadMessagesCounterText }}
        </span>
      </AppSidebarNavigationItem>

      <li
        v-if="userHasAnalyticsBetaFeature"
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Analytics-beta') || isActiveMenu('Analytics-beta'),
        }"
      >
        <UiTooltip
          v-if="!isExpanded && !isSubmenuOpen('Analytics-beta') && !isMobileMenuVisible"
          for="analytics-beta-sidebar-item"
          :text="t('Analytics')"
          placement="right"
          :offset-size="22"
        />
        <button
          id="analytics-beta-sidebar-item"
          class="aside-navigation__item-link"
          @click.prevent="toggleSubmenu('Analytics-beta')"
        >
          <Fa
            :icon="faChartMixed"
            class="aside-navigation__item-icon"
          />
          <span class="aside-navigation__link-name">{{ t('Analytics') }}</span>
          <Fa
            :icon="faChevronDown"
            :class="[
              'aside-navigation__icon--submenu',
              'chevron-sidebar',
              { 'rotate-180': isSubmenuOpen('Analytics-beta') },
            ]"
          />
        </button>
        <ul
          v-show="isSubmenuOpen('Analytics-beta')"
          class="aside-navigation__submenu"
        >
          <span class="aside-navigation__link-name-collapsed">{{ t('Analytics') }}</span>
          <AppSidebarNavigationItem
            id="analytics-beta-ship-ping"
            parent-section="analytics-beta"
            :href="{ name: ANALYTICS_ROUTES.SHIPPING.OVERVIEW }"
          >
            <span class="aside-navigation__link-name">{{ t('Shipping') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            id="analytics-beta-ret-urns"
            parent-section="analytics-beta"
            :href="{ name: ANALYTICS_ROUTES.RETURNS.OVERVIEW }"
          >
            <span class="aside-navigation__link-name">{{ t('Returns') }}</span>
          </AppSidebarNavigationItem>
        </ul>
      </li>

      <template v-else>
        <UiTooltip
          v-if="!isExpanded && !isMobileMenuVisible"
          for="analytics-sidebar-item"
          :text="t('Analytics')"
          placement="right"
          :offset-size="22"
        />
        <AppSidebarNavigationItem
          v-if="permissions.view_analytics"
          id="analytics-sidebar-item"
          parent-section="analytics"
          :href="{ name: ANALYTICS_SHIPPING_ROUTE }"
          :icon="faChartMixed"
          :class="{
            'aside-navigation__item': true,
            'aside-navigation__item--active': isSubmenuOpen('Analytics') || isActiveMenu('Analytics'),
          }"
        >
          <span class="aside-navigation__link-name">{{ t('Analytics') }}</span>
        </AppSidebarNavigationItem>
      </template>
    </ul>
  </nav>

  <nav
    class="aside-navigation__bottom"
    aria-label="settings navigation"
  >
    <ul>
      <li
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Settings') || isActiveMenu('Settings'),
        }"
      >
        <UiTooltip
          v-if="!isExpanded && !isSubmenuOpen('Settings') && !isMobileMenuVisible"
          for="settings-sidebar-item"
          :text="t('Settings')"
          placement="right"
          :offset-size="22"
        />
        <button
          id="settings-sidebar-item"
          class="aside-navigation__item-link"
          @click.prevent="toggleSubmenu('Settings')"
        >
          <Fa
            :icon="faGear"
            class="aside-navigation__item-icon"
          />
          <span class="aside-navigation__link-name">{{ t('Settings') }}</span>
          <Fa
            :icon="faChevronDown"
            :class="['aside-navigation__icon--submenu', 'chevron-sidebar', { 'rotate-180': isSubmenuOpen('Settings') }]"
          />
        </button>
        <ul
          v-show="isSubmenuOpen('Settings')"
          class="aside-navigation__submenu--bottom"
        >
          <span class="aside-navigation__link-name-collapsed">{{ t('Settings') }}</span>
          <AppSidebarNavigationItem
            v-if="permissions.manage_integrations"
            id="settings-integrations"
            parent-section="settings"
            :href="{ name: SETTINGS_INTEGRATIONS_MANAGE_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Integrations') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.manage_addresses"
            id="settings-addresses"
            parent-section="settings"
            :href="{ name: SETTINGS_ADDRESSES_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Addresses') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="hasProductsFeatureAccess"
            id="settings-products"
            parent-section="settings"
            :href="{ name: SETTINGS_PRODUCTS_ROUTE }"
          >
            <span class="aside-navigation__link--name"> {{ t('settings.products.menu_item') }} </span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.manage_shipping"
            id="settings-contacts"
            parent-section="settings"
            :href="{ name: SETTINGS_SAVED_CONTACTS_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Saved contacts') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.manage_brands"
            id="settings-brands"
            parent-section="settings"
            :href="{ name: SETTINGS_BRANDS_LIST_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('Brands') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.manage_subusers"
            id="settings-users"
            parent-section="settings"
            :href="{ name: SETTINGS_USERS_INVITATIONS_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.user_roles') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.configure_checkout"
            id="settings-configurations"
            parent-section="settings"
            :href="{ name: CHECKOUT_CONFIGURATIONS_LIST_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.dynamic_checkout') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.configure_checkout"
            id="settings-checkout-rules"
            parent-section="settings"
            :href="{ name: SETTINGS_CHECKOUT_RULES_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('settings.checkout_rules.path_title') }}</span>
          </AppSidebarNavigationItem>

          <AppSidebarNavigationItem
            v-if="user?.features?.pack_and_go && user?.permissions.configure_packing"
            id="settings-packgo"
            parent-section="settings"
            :href="{ name: SETTINGS_PACKGO_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.pack_go_settings') }}</span>
          </AppSidebarNavigationItem>
        </ul>
      </li>
      <li
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Account') || isActiveMenu('Account'),
        }"
      >
        <UiTooltip
          v-if="!isExpanded && !isSubmenuOpen('Account') && !isMobileMenuVisible"
          for="account-sidebar-item"
          :text="t('Account')"
          placement="right"
          :offset-size="22"
        />
        <button
          id="account-sidebar-item"
          class="aside-navigation__item-link"
          @click.prevent="toggleSubmenu('Account')"
        >
          <Fa
            :icon="faUser"
            class="aside-navigation__item-icon"
          />
          <span class="aside-navigation__link-name">{{ t('Account') }}</span>
          <Fa
            :icon="faChevronDown"
            :class="['aside-navigation__icon--submenu', 'chevron-sidebar', { 'rotate-180': isSubmenuOpen('Account') }]"
          />
        </button>
        <ul
          v-show="isSubmenuOpen('Account')"
          class="aside-navigation__submenu--bottom"
        >
          <span class="aside-navigation__link-name-collapsed">{{ t('Account') }}</span>
          <AppSidebarNavigationItem
            v-if="permissions.manage_account || permissions.delete_account"
            id="account-profile"
            parent-section="account"
            :href="{ name: SETTINGS_ACCOUNT_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.profile') }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.manage_subscriptions"
            id="account-plans"
            parent-section="account"
            :href="{ name: SETTINGS_PLANS_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{
              t('settings.financial.invoices.filter.category.subscription')
            }}</span>
          </AppSidebarNavigationItem>
          <AppSidebarNavigationItem
            v-if="permissions.manage_invoices"
            id="account-financial"
            parent-section="account"
            :href="{ name: SETTINGS_FINANCIAL_ROUTE }"
          >
            <span class="aside-navigation__link-name">{{ t('aside.navigation.financial_invoices') }}</span>
          </AppSidebarNavigationItem>

          <AppSidebarNavigationItem
            id="account-logout"
            parent-section="logout"
            :href="getLogoutURL()"
            :icon="faPowerOff"
          >
            <span class="aside-navigation__link-name">{{ t('Sign out') }}</span>
          </AppSidebarNavigationItem>
        </ul>
      </li>

      <UiTooltip
        v-if="!isExpanded && !isMobileMenuVisible"
        for="support-sidebar-item"
        :text="t('Support')"
        placement="right"
        :offset-size="22"
      />
      <AppSidebarNavigationItem
        v-if="permissions.manage_tickets"
        id="support"
        parent-section="support"
        :href="{ name: SUPPORT_ROUTE }"
        :icon="faQuestionCircle"
        :class="{
          'aside-navigation__item': true,
          'aside-navigation__item--active': isSubmenuOpen('Support') || isActiveMenu('Support'),
        }"
      >
        <span class="aside-navigation__link-name">{{ t('Support') }}</span>
      </AppSidebarNavigationItem>

      <li class="aside-navigation__item">
        <UiTooltip
          v-if="!isExpanded && !isMobileMenuVisible"
          for="sidebar-notifications-item"
          :text="t('Notifications')"
          placement="right"
          :offset-size="22"
        />
        <AppSidebarNotifications
          id="notifications"
          data-test="notifications-button"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { UiTooltip } from '@sendcloud/ui-library'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useConversationsStore } from '@/features/whatsapp-conversations/stores'

import AppSidebarNavigationItem from '@/application/components/navigation/AppSidebarNavigationItem.vue'
import AppSidebarNotifications from '@/application/components/navigation/AppSidebarNotifications.vue'
import type { User, UserPermissions } from '@/types/models'
import {
  faArrowUpRightFromSquare,
  faChartMixed,
  faChevronDown,
  faComment,
  faEnvelope,
  faGear,
  faPowerOff,
  faQuestionCircle,
  faRocketLaunch,
  faRotateLeft,
  faStore,
  faTruckFast,
  faUser,
  faArrowsRotate,
} from '@fortawesome/pro-regular-svg-icons'
import Fa from 'vue-fa'

import { getLogoutURL } from '@/common/utils/backend'

// Shipping Navigation
import { SETTINGS_BOXES_LIST_ROUTE } from '@/features/boxes/routes'
import { SETTINGS_CARRIERS_ROUTE } from '@/features/carriers/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { PACKGO_QUEUE_ROUTE } from '@/features/packgo/routes'
import { PICKUPS_ROUTE } from '@/features/pickups/routes'
import { SETTINGS_RATES_ROUTE } from '@/features/rates/routes'
import { SETTINGS_SHIPPING_RULES_ROUTE } from '@/features/shipping-rules/routes'

// From Settings
import {
  SETTINGS_PACKGO_ROUTE,
  SETTINGS_PRINTING_ROUTE,
  SETTINGS_PRODUCTS_ROUTE,
  SETTINGS_RETURNS_ROUTE,
  SETTINGS_SHIPPING_DEFAULTS_ROUTE,
} from '@/application/routes/settings.routes.names'

// Returns Navigation
import { SETTINGS_RETURN_DEFAULTS_ROUTE } from '@/features/return-defaults/routes'
import { SETTINGS_RETURN_RULES_LIST_ROUTE } from '@/features/return-rules/routes'
import { RETURNS_LIST_ROUTE } from '@/features/returns/routes'

// Parcel Radar, Support Automation Navigation and Beta
import { PARCEL_MONITOR_V2_ROUTE } from '@/features/parcel-monitor-v2/routes'
import { PARCEL_MONITOR_V2_BETA, OWN_CONTRACT_SUPPORT } from '@/features/support/beta-features'

// Tracking Navigation
import { SETTINGS_NOTIFY_TRACKING_MESSAGES_EMAIL_ROUTE } from '@/features/tracking-messages/routes'
import { SETTINGS_NOTIFY_TRACKING_PAGE_ROUTE } from '@/features/tracking-pages/routes'
import { SETTINGS_NOTIFY_TRACKING_WIDGET_ROUTE } from '@/features/tracking-widget/routes'
import { BETA_FEATURE_TRACKING_NOTIFICATIONS } from '@/features/tracking-notifications/beta-features'
import {
  SETTINGS_TRACKING_NOTIFICATIONS_EMAIL_ROUTE,
  SETTINGS_TRACKING_NOTIFICATIONS_SMS_ROUTE,
  SETTINGS_TRACKING_NOTIFICATIONS_WHATSAPP_ROUTE,
} from '@/features/tracking-notifications/routes'

// Analytics Navigation
import { ANALYTICS_SHIPPING_ROUTE } from '@/features/analytics/routes'

// Analytics BETA Navigation
import { ANALYTICS_ROUTES } from '@/features/analytics-beta/routes'

// Settings navigation
import { SETTINGS_ADDRESSES_ROUTE } from '@/features/addresses/routes'
import { SETTINGS_BRANDS_LIST_ROUTE } from '@/features/brands/routes'
import { SETTINGS_CHECKOUT_RULES_ROUTE } from '@/features/dynamic-checkout-rules/routes'
import { CHECKOUT_CONFIGURATIONS_LIST_ROUTE } from '@/features/dynamic-checkout/routes'
import { SETTINGS_INTEGRATIONS_MANAGE_ROUTE } from '@/features/integrations/routes'
import { SETTINGS_SAVED_CONTACTS_ROUTE } from '@/features/saved-contacts/routes'
import { SETTINGS_USERS_INVITATIONS_ROUTE } from '@/features/users-and-roles/routes'

// Account Navigation
import { SETTINGS_ACCOUNT_ROUTE } from '@/features/account/routes'
import { SETTINGS_FINANCIAL_ROUTE } from '@/features/financial/routes'
import { SETTINGS_PLANS_ROUTE } from '@/features/subscription/routes'

// Support navigation

import { SUPPORT_ROUTE, ISSUE_MANAGEMENT_ROUTE } from '@/features/support/routes'

import NavigationAnalytics from '@/application/utils/navigation.analytics'
import { CONVERSATIONS_BETA_FEATURE } from '@/features/whatsapp-conversations/beta-features'
import { WHATSAPP_CONVERSATIONS_ROUTE } from '@/features/whatsapp-conversations/routes'
import { SHIPMENT_PROTECTION_ROUTE } from '@/features/insurance/routes'
import { PRODUCTS_BETA_FEATURE } from '@/features/products/beta-features'

// Opportunity Center

import { OPPORTUNITY_CENTER_ROUTE } from '@/features/opportunity-center/routes'
import { useOpportunityStore } from '@/features/opportunity-center/stores/'
import { OPPORTUNITY_CENTER, OPPORTUNITY_CENTER_CIO } from '@/features/opportunity-center/beta-features'
import { ANALYTICS_BETA_FEATURE } from '@/features/analytics-beta/beta-features'

const store = useStore()
const route = useRoute()
const { t } = useI18n()
const user = computed<User>(() => store.getters.user)
const permissions = computed<UserPermissions>(() => store.getters.user?.permissions)
const opportunityStore = useOpportunityStore()

const conversationsStore = useConversationsStore()
const unreadMessagesCounterText = computed(() => conversationsStore.unreadMessagesCounterText)

const opportunityNotification = computed(() => opportunityStore.getOpportunityNotification)

const userHasOpportunityCenter = computed<boolean>(() => store.getters.hasBetaFeature(OPPORTUNITY_CENTER))
const userHasOpportunityCenterCio = computed<boolean>(() => store.getters.hasBetaFeature(OPPORTUNITY_CENTER_CIO))

const userHasAnalyticsBetaFeature = computed<boolean>(() => store.getters.hasBetaFeature(ANALYTICS_BETA_FEATURE))

const userHasSupportAutomationEnabled = computed<boolean>(() => store.getters.hasBetaFeature(OWN_CONTRACT_SUPPORT))
const userHasParcelMonitorV2BetaFeature = computed<boolean>(() => store.getters.hasBetaFeature(PARCEL_MONITOR_V2_BETA))

const hasTrackingNotificationsBetaFeature = computed<boolean>(() =>
  store.getters.hasBetaFeature(BETA_FEATURE_TRACKING_NOTIFICATIONS))

const props = defineProps<{
  isExpanded: boolean
  isMobileMenuVisible: boolean
}>()

const submenuState = ref({
  activeMenu: '',
})

const toggleSubmenu = (label: string) => {
  if (submenuState.value.activeMenu === label) {
    submenuState.value.activeMenu = ''
  } else {
    submenuState.value.activeMenu = label
  }
  NavigationAnalytics.trackNavigationCategoryClicked({
    name: label,
    isExpanded: submenuState.value.activeMenu === label,
  })
}

const isActiveMenu = (label: string) => {
  const parentPath = route.meta.parent
  return typeof parentPath === 'string' && parentPath.includes(label.toLowerCase())
}

const isSubmenuOpen = computed(() => {
  return (label: string) => submenuState.value.activeMenu === label
})

const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (
    !props.isExpanded &&
    !target.closest('.aside-navigation__submenu') &&
    !target.closest('.aside-navigation__item-link')
  ) {
    submenuState.value.activeMenu = ''
  }
}

const handleLinkClick = () => {
  if (!props.isExpanded) {
    submenuState.value.activeMenu = ''
  }
}

const handleEscapeKey = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    submenuState.value.activeMenu = ''
  }
}

const hasConversationsBetaFeature = computed<boolean>(() => store.getters.hasBetaFeature(CONVERSATIONS_BETA_FEATURE))

const hasProductsFeatureAccess = computed<boolean>(
  () => store.getters.hasBetaFeature(PRODUCTS_BETA_FEATURE) && permissions.value.manage_products,
)

onMounted(async () => {
  document.addEventListener('click', handleClickOutside)
  document.addEventListener('keydown', handleEscapeKey)
  const submenuLinks = document.querySelectorAll('.aside-navigation__submenu a')
  submenuLinks.forEach((link) => {
    link.addEventListener('click', handleLinkClick)
  })
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
  document.removeEventListener('keydown', handleEscapeKey)
  const submenuLinks = document.querySelectorAll('.aside-navigation__submenu a')
  submenuLinks.forEach((link) => {
    link.removeEventListener('click', handleLinkClick)
  })
})
</script>

<style lang="scss">
.aside-navigation__menu ul {
  margin: 0;
  padding: 0;
}

.layout-with-sidebar .pagenav-sidebar {
  display: none;
}

.chevron-sidebar {
  transition: transform 0.1s ease;
}

.chevron-sidebar.rotate-180 {
  transform: rotate(180deg);
}

.aside-navigation__main::-webkit-scrollbar,
.aside-navigation__bottom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.aside-navigation__main::-webkit-scrollbar-thumb,
.aside-navigation__bottom::-webkit-scrollbar-thumb {
  background-color: #4d5e81;
  border-radius: $sc-border-radius;
}

.aside-navigation__main::-webkit-scrollbar-track,
.aside-navigation__bottom::-webkit-scrollbar-track {
  background: transparent;
  border-radius: $sc-border-radius;
}

.aside-navigation__main,
.aside-navigation__bottom {
  scrollbar-width: thin;
  scrollbar-color: #4d5e81 transparent;
}

.aside-navigation__main *::-webkit-scrollbar-track,
.aside-navigation__main *::-webkit-scrollbar-thumb,
.aside-navigation__bottom *::-webkit-scrollbar-track,
.aside-navigation__bottom *::-webkit-scrollbar-thumb {
  border-radius: $sc-border-radius;
}

.aside-navigation__menu--mobile {
  z-index: 200;
}

.aside-navigation__main {
  background: $sc-dark-blue;
  margin-top: var(--app-navigation-header-height);
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
}

.aside-navigation__bottom {
  background: $sc-dark-blue;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #1a3b5d;

  @include breakpoint-max-width(medium) {
    overflow: auto;
  }
}

.aside-navigation--expanded .aside-navigation__link-name {
  display: block;
  font-weight: 600;
}

.aside-navigation__link-conversations--padded {
  padding-right: $sc-spacing-32;
}

.aside-navigation__unread-counter {
  position: absolute;
  transform: translateX(50%);
  bottom: 50%;
  font-size: 0.75rem;
  line-height: 1.1875;
  font-weight: 600;
  height: 16px;
  min-width: 16px;
  padding: $sc-spacing-4;
  background-color: $sc-danger-80;
  border-radius: $sc-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $sc-white;
}

.aside-navigation--expanded .aside-navigation__unread-counter {
  right: 18px;
  height: 20px;
  min-width: 20px;
  bottom: unset;
  padding: 0 $sc-spacing-8;
}

.aside-navigation__link-name-collapsed {
  font-size: 14px;
  font-weight: bold;
  padding: $sc-spacing-8;
  display: block;
}

.aside-navigation--collapsed .aside-navigation__link-name {
  @include breakpoint-min-width(large) {
    display: none;
  }
}

.aside-navigation--collapsed .aside-navigation__submenu .aside-navigation__link-name {
  display: block;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom .aside-navigation__link-name {
  display: block;
}

.aside-navigation--collapsed .aside-navigation__icon--submenu {
  display: none;
}

.aside-navigation--collapsed .external-icon {
  display: none;
}

.aside-navigation--expanded .external-icon {
  display: block;
  margin-left: auto;
}

.aside-navigation--expanded .aside-navigation__icon--submenu {
  display: block;
  margin-left: auto;
}

.aside-navigation--expanded .aside-navigation__link-name-collapsed {
  display: none;
}

.aside-navigation__item {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
  position: relative;
  min-height: 44px;
}

.aside-navigation__submenu .aside-navigation__item {
  margin: 0;
}

.aside-navigation__submenu--bottom .aside-navigation__item {
  margin: 0;
}

.aside-navigation__item-link {
  color: $sc-white;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: $sc-border-radius;
  transition: background-color 0.1s;
  width: 100%;
  font-size: 14px;
  background: none;
  border: none;
  min-height: 44px;

  &:hover {
    color: $sc-white;
    background: rgba($sc-informative, 0.2);
    box-shadow: none;
    border-color: transparent;
  }

  &:active {
    color: $sc-white;
    background: rgba($sc-informative, 0.6);
  }

  &:focus {
    box-shadow: none;
    color: $sc-white;
  }

  &:focus-visible {
    background: rgba($sc-informative, 0.6);
    box-shadow: 0 0 0 3px rgb(29 151 255 / 40%);
    color: $sc-white;
  }
}

.aside-navigation--expanded .aside-navigation__item-icon {
  margin-right: 10px;
}

.aside-navigation--collapsed .aside-navigation__item-icon {
  @include breakpoint-min-width(large) {
    margin: 0;
    padding: 0;
  }
}

.aside-navigation__submenu .aside-navigation__item-icon,
.aside-navigation__submenu--bottom .aside-navigation__item-icon {
  margin-right: 6px;
}

.aside-navigation__item-counter {
  background: $sc-danger-80;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-left: auto;
}

.aside-navigation--collapsed .aside-navigation__item {
  @include breakpoint-min-width(large) {
    width: 44px;
  }

  &.--notification {
    .aside-navigation__item-icon {
      @include breakpoint-min-width(large) {
        display: none;
      }
    }

    .aside-navigation__item-counter {
      margin-left: 0;
    }
  }
}

.aside-navigation__item:not(:disabled):hover {
  border-color: transparent;
}

.aside-navigation__item:not(:disabled):focus {
  border-color: transparent;
}

.aside-navigation__item-link--active {
  background: rgba($sc-informative, 0.6);
  color: $sc-white;
  font-weight: 600;
}

.aside-navigation__submenu .aside-navigation__item-link--active,
.aside-navigation__submenu--bottom .aside-navigation__item-link--active {
  background: rgba($sc-informative, 0.6);
}

.aside-navigation--expanded .aside-navigation__submenu .aside-navigation__link-name {
  font-weight: 400;
}

.aside-navigation--expanded .aside-navigation__submenu--bottom .aside-navigation__link-name {
  font-weight: 400;
}

.aside-navigation--expanded
.aside-navigation__submenu
.aside-navigation__item-link--active
.aside-navigation__link-name {
  font-weight: 600;
}

.aside-navigation--expanded
.aside-navigation__submenu--bottom
.aside-navigation__item-link--active
.aside-navigation__link-name {
  font-weight: 600;
}

.aside-navigation--collapsed .aside-navigation__item-link {
  @include breakpoint-min-width(large) {
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 0;
  }
}

.aside-navigation__item--active {
  background: $sc-extra-dark-blue;
  border-radius: $sc-border-radius;
}

.aside-navigation--expanded .aside-navigation__submenu,
.aside-navigation--expanded .aside-navigation__submenu--bottom {
  position: relative;
  top: 0;
  left: 0;
  padding: 0 6px 10px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  border-radius: $sc-border-radius;
  width: 100%;
}

.aside-navigation--collapsed .aside-navigation__submenu .aside-navigation__item-link::after {
  border-left: 1px solid $sc-light-grey;
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom .aside-navigation__item-link::after {
  border-left: 1px solid $sc-light-grey;
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--expanded .aside-navigation__submenu .aside-navigation__item-link::after {
  border-left: 1px solid $sc-dark-blue-75;
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--expanded .aside-navigation__submenu--bottom .aside-navigation__item-link::after {
  border-left: 1px solid $sc-dark-blue-75;
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom .aside-navigation__item-link:hover::after {
  border-left: 1px solid rgba($sc-informative, 0.4);
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--collapsed .aside-navigation__submenu .aside-navigation__item-link--active::after {
  border-left: 1px solid rgba($sc-informative, 0.4);
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom .aside-navigation__item-link--active::after {
  border-left: 1px solid rgba($sc-informative, 0.4);
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--expanded .aside-navigation__submenu .aside-navigation__item-link {
  padding: 8px 12px 8px 26px;
  line-height: 1.43;
}

.aside-navigation--collapsed .aside-navigation__submenu .aside-navigation__item-link:hover::after {
  border-left: 1px solid rgba($sc-informative, 0.4);
  position: absolute;
  content: '';
  left: 8px;
  height: 100%;
}

.aside-navigation--expanded .aside-navigation__submenu--bottom .aside-navigation__item-link {
  padding: 8px 12px 8px 26px;
  line-height: 1.43;
}

.aside-navigation--expanded .aside-navigation__submenu--bottom .aside-navigation__item-link:hover::after {
  border-left: 1px solid rgba($sc-white, 0.5);
}

.aside-navigation--expanded .aside-navigation__submenu--bottom .aside-navigation__item-link:active::after {
  border-left: 1px solid $sc-white;
}

.aside-navigation--expanded .aside-navigation__submenu--bottom .aside-navigation__item-link--active::after {
  border-left: 1px solid $sc-white;
}

.aside-navigation--expanded .aside-navigation__submenu .aside-navigation__item-link:hover::after {
  border-left: 1px solid rgba($sc-white, 0.5);
}

.aside-navigation--expanded .aside-navigation__submenu .aside-navigation__item-link:active::after {
  border-left: 1px solid $sc-white;
}

.aside-navigation--expanded .aside-navigation__submenu .aside-navigation__item-link--active::after {
  border-left: 1px solid $sc-white;
}

.aside-navigation--expanded .aside-navigation__main {
  overflow: auto;
}

.aside-navigation--collapsed .aside-navigation__submenu {
  @include breakpoint-min-width(large) {
    position: absolute;
    top: 0;
    left: 60px;
    background: $sc-white;
    padding: 0 6px 6px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    border-radius: $sc-border-radius;
    width: 200px;
  }
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom {
  @include breakpoint-min-width(large) {
    position: absolute;
    bottom: 0;
    left: 60px;
    background: $sc-white;
    padding: 0 6px 6px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    border-radius: $sc-border-radius;
    width: 200px;
  }
}

.aside-navigation--collapsed .aside-navigation__submenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.aside-navigation--collapsed .aside-navigation__submenu li {
  padding: 0;
  width: 100%;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom li {
  padding: 0;
  width: 100%;
}

.aside-navigation--collapsed .aside-navigation__submenu a {
  color: $sc-black;
  text-decoration: none;
  text-align: left;
  justify-content: left;
  width: 100%;
  padding-left: 20px;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom a {
  color: $sc-black;
  text-decoration: none;
  text-align: left;
  justify-content: left;
  width: 100%;
  padding-left: 20px;
}

.aside-navigation--collapsed .aside-navigation__submenu a:hover {
  text-decoration: none;
  font-weight: 600;
}

.aside-navigation--collapsed .aside-navigation__submenu--bottom a:hover {
  text-decoration: none;
  font-weight: 600;
}

.counter-beta-color {
  background: $sc-informative;
}
</style>
