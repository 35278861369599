import { OBJECT_PARSER_TYPE } from '../constants'
import { Context } from '../context'
import type { CTX, CreateContextParams } from '../types/context.types'
import type { AnyObjectParser, AnyObjectParserResult, BaseObjectParser, ParsersObject } from '../types/parsers.types'
import type { Prettify } from '../types/prettify.types'
import type { UnionToIntersection } from '../types/type-helpers.types'
import { checkIsObject } from '../utils/checks'
import {
  generateErrorMessage,
  getErrorMsg,
  getTruncatedErrorsText,
  keysOf,
  toReadableTypeOf,
} from '../utils/helpers'
import { merge } from '../utils/merge'
import { MagicObjectParser } from './magic-object'

type ParserArrayToResultIntersection<First extends AnyObjectParser, Parsers extends AnyObjectParser[]> = Prettify<
  AnyObjectParserResult<First> & UnionToIntersection<AnyObjectParserResult<Parsers[number]>>
>

class IntersectionParser<First extends AnyObjectParser, Others extends AnyObjectParser[]>
implements AnyObjectParser<ParserArrayToResultIntersection<First, Others>> {
  readonly type = OBJECT_PARSER_TYPE

  constructor(readonly expected: string, readonly parsersArray: [First, ...Others]) { }

  parse(data: unknown, ctx: CTX): ParserArrayToResultIntersection<First, Others> {
    type Result = ParserArrayToResultIntersection<First, Others>

    const { parsersArray } = this
    const l = parsersArray.length

    if (!l) {
      return data as Result
    }

    const isObject = checkIsObject(data)
    ctx.assert(isObject, this.expected, toReadableTypeOf(data))
    // ctx.assert does not have to throw error (can just log it), so we have to re-check conditions ourselves
    if (!isObject) {
      return data as Result
    }

    const errorMap: Record<number, Array<string | undefined>> = Object.create(null)
    let shouldStopParsing = false

    // used for error generation and the loop
    let i = 0

    const onAssertionError: CreateContextParams['onAssertionError'] = (params) => {
      // we collect all the errors from all parsers, without quitting on errors
      // because we want to check all the keys, some might fail, but some will be okay
      const parserN = i + 1
      errorMap[parserN] ||= []
      // no need to generate errors if we will not display them
      errorMap[parserN].push(
        errorMap[parserN].length < ctx.maxErrorsInOneMsg ? generateErrorMessage(params) : undefined,
      )

      if (ctx.shouldStopParsingOnError) {
        shouldStopParsing = true
      }
    }

    const populateErrorsCtx = new Context({
      originalCtx: ctx,
      onAssertionError,
    })
    const isInitialCtxPartial = ctx.isPartial

    // we need this to save the results from previous parsers (since they can mutate some data keys)
    // these are clean results, without the rest of data keys
    const results: Result[] = []
    for (; i < l; i++) {
      try {
        // need to reset partial each time (since any object parser can disable it)
        // could also create new populateErrorsCtx each time, but that's more expensive
        populateErrorsCtx.isPartial = isInitialCtxPartial
        const result = parsersArray[i]!.parse(data, populateErrorsCtx) as Result
        results.push(result)

        if (shouldStopParsing) {
          break
        }
      } catch (e) {
        // this will be some unexpected errors
        const parserN = i + 1
        errorMap[parserN] ||= []
        errorMap[parserN].push(errorMap[parserN].length < ctx.maxErrorsInOneMsg ? getErrorMsg(e) : undefined)
      }
    }

    const result = results.reduce<Result>((acc, curr, i) => {
      // we start with results[0]
      if (i === 0) {
        return acc
      }
      acc = merge(acc, curr)
      return acc
    }, results[0])
    const errorKeys = keysOf(errorMap)

    if (!errorKeys.length) {
      return result
    }

    const errorsTitleText = shouldStopParsing ? 'early return error:' : `errors from ${errorKeys.length}/${parsersArray.length} parsers:`
    // If we got here, some parsers have failed
    // !!false to fail the assertion and avoid TS unreachable code error
    ctx.assert(
      !!false,
      this.expected,
      `${errorsTitleText} [${errorKeys
        .map((k) => {
          const errors = errorMap[k]
          const errorText = getTruncatedErrorsText(errors, ctx.maxErrorsInOneMsg, errors.length)
          const formattedText = errors.length === 1 ? errorText : `[${errorText}]`
          return `\n\tParser ${k}: ${formattedText}`
        })
        .join(',')}\n]`,
    )

    return result
  }
}

/**
 * `assign()` will create an intersection type from `object()` parsers passed to it
 *
 * Intersection is created through `Object.assign()`,
 * so **conflicting keys will be overridden by the last parser**.
 *
 * **This is the fastest and preferred way to create intersections.** But it has **limitations:**
 *
 * `assign()` only supports `object()`. **Use `intersection(...)` parser instead** if you want to create intersections
 * with `partial(...)`, `deepPartial(...)`, `snakeToDeepCamel(...)`, `camelToDeepSnake(...)`
 * ```ts
 * const parser1 = object({ a: number(), z: number() })
 * const parser2 = object({ b: string(), z: string() }) // z will be overridden by this
 *
 * const resultParser = assign(parser1, parser2) // valid: { a: 1, b: '', z: ''}, invalid: { a: 1, b: '', z: 1}
 *
 * // result: { a: number, b: string, z: string }
 * const result = resultParser.parse(someData, ctx)
 * ```
 */
export const assign = <
  First extends BaseObjectParser<ParsersObject>,
  Others extends ReadonlyArray<BaseObjectParser<ParsersObject>>,
>(
  ...parsersArray: [firstParser: First, ...otherParsers: Others]
) =>
  // merges all parsers into 1, overriding keys if necessary
  new MagicObjectParser(
    `(${parsersArray.map(({ expected }) => expected).join(' & ')})`,
    Object.assign({}, ...parsersArray.map(p => p.parsersObject)) as First['parsersObject'] &
    UnionToIntersection<Others[number]['parsersObject']>,
  )

/**
 * `intersection(A, B)` will create an intersection type (A & B) from object parsers passed to it.
 *
 * It is done through parsing data by each parser and then `merge(result1, result2, ...etc.)`
 *
 * **Note:** this means if you did some mutations in previous parser, the new parser could override it,
 * if both parsers have the same key
 *
 * Unlike `assign(...)` keys with conflicting types will become `never` and emit errors.
 *
 * **If you are creating an intersection from `object(...)` parsers - prefer `assign(object(), object())`
 * as a generally less strict, safer and more performant option**
 *
 * **Use** `intersection(...)` parser **if you want to create intersections
 * with** `partial(...)`, `deepPartial(...)`, `snakeToDeepCamel(...)`, `camelToDeepSnake(...)`
 * or to intersect those with `object(...)` parsers, as this will be not possible with `assign()`
 * ```ts
 * const p1 = object({ a: number(), c: literal('hi') })
 * const p2 = object({ b: string(), c: string() })
 *
 * // type: { a: number, b: string, c: 'hi' }
 * const resultParser = intersection(p1, p2)
 *
 * // valid: { a: 1, b: '', c: 'hi' }, invalid: { a: 1, b: '', c: '' }, { a: 1, c: 'hi' }
 * const result = resultParser.parse(someData, ctx)
 *
 *
 * const parser1 = object({ a: number(), z: number() })
 * const parser2 = object({ b: string(), z: string() }) // z becomes number & string, which is === never
 *
 * // No object is valid here, because of `z: never` will always emit errors
 * const resultParser = intersection(parser1, parser2)
 * ```
 */
export const intersection = <First extends AnyObjectParser, Others extends AnyObjectParser[]>(
  ...parsersArray: [firstParser: First, ...otherParsers: Others]
) => new IntersectionParser(`(${parsersArray.map(({ expected }) => expected).join(' & ')})`, parsersArray)
