/**
 * This is the returns settings Vuex module. Its purpose is to work as an isolated unit containing all required actions,
 * mutations and so forth to make the returns settings (Settings > Returns) application module fully functional. By
 * detaching it from the main Vuex store module (i.e. `@/common/stores/store`) we can lazy load this module and
 * dynamically register it to Vuex (see `returns-portal/module.js`)
 */
import ReturnPortalApi from '@/features/return-portal/api/return-portal.api'

import {
  RETURNS_SETTINGS_PORTALS_CREATE,
  RETURNS_SETTINGS_PORTALS_FETCH_ALL,
  RETURNS_SETTINGS_PORTALS_UPDATE,
  RETURNS_SETTINGS_PAYMENT_PROVIDER_DISCONNECT,
} from './action.types'

import { RETURNS_SETTINGS_SET_PORTALS } from './mutation.types'
import { CORE_SET_UNSAVED_CHANGES } from '@/common/stores/core/mutation.types'

import type { Commit, Dispatch } from 'vuex'
import type { ReturnPortalSettings, ReturnPortalState } from '@/features/return-portal/types'

/**
 * Some fields should be transformed to be used on the UI. This function will do those transformations.
 */
function fixResponse(portalData: ReturnPortalSettings) {
  portalData.delivery_options = Array.isArray(portalData.delivery_options) ? portalData.delivery_options : []
  portalData.refund_options = Array.isArray(portalData.refund_options) ? portalData.refund_options : []
  portalData.enable_refunds = !!portalData.enable_refunds
  if (typeof portalData.return_fee === 'string') {
    portalData.return_fee = parseFloat(portalData.return_fee || 0)
  }
  return portalData
}

/**
 * Some fields should be transformed to be sent to the API. This function will do those transformations.
 */
function fixRequest(payload: ReturnPortalSettings) {
  const payloadItems = Object.keys(payload)
  // if return_fee is empty then set it to '0' because the API does not accept null
  if (payloadItems.includes('return_fee') || payloadItems.includes('enable_refunds')) {
    if (payload.return_fee === null || payload.enable_refunds === false) {
      payload.return_fee = 0
    }
  }

  // if support_url is null then set it to '' because the API does not accept null
  if (payloadItems.includes('support_url')) {
    if (payload.support_url === null) {
      payload.support_url = ''
    }
  }
  return payload
}

const state: ReturnPortalState = {
  portals: {},
}

const getters = {
  findReturnsPortalByBrand(state: ReturnPortalState) {
    return (brandId: number) => state.portals[brandId]
  },
}

const actions = {
  async [RETURNS_SETTINGS_PORTALS_CREATE](
    { state, commit }: { state: ReturnPortalState, commit: Commit },
    payload: ReturnPortalSettings,
  ) {
    const data = await ReturnPortalApi.create(fixRequest(payload))
    const newPortals = { ...state.portals, [data.brand]: data }
    commit(RETURNS_SETTINGS_SET_PORTALS, newPortals)
    commit(CORE_SET_UNSAVED_CHANGES, false)
  },
  async [RETURNS_SETTINGS_PORTALS_UPDATE](
    { state, commit }: { state: ReturnPortalState, commit: Commit },
    payload: ReturnPortalSettings,
  ) {
    const data = await ReturnPortalApi.update(payload.id, fixRequest(payload))
    const newPortals = { ...state.portals, [data.brand]: fixResponse(data) }
    commit(RETURNS_SETTINGS_SET_PORTALS, newPortals)
    commit(CORE_SET_UNSAVED_CHANGES, false)
  },
  async [RETURNS_SETTINGS_PORTALS_FETCH_ALL]({ commit }: { commit: Commit }) {
    const data = await ReturnPortalApi.findAll()
    // Group portals per brand
    const portals = data.reduce<ReturnPortalState['portals']>((acc, item) => {
      return {
        [item.brand]: fixResponse(item),
        ...acc,
      }
    }, {})
    commit(RETURNS_SETTINGS_SET_PORTALS, portals)
  },
  async [RETURNS_SETTINGS_PAYMENT_PROVIDER_DISCONNECT]({ dispatch }: { dispatch: Dispatch }, brandId: number) {
    await ReturnPortalApi.deletePaymentProvider(brandId)
    await dispatch(RETURNS_SETTINGS_PORTALS_FETCH_ALL)
  },
}

const mutations = {
  [RETURNS_SETTINGS_SET_PORTALS](state: ReturnPortalState, portals: ReturnPortalSettings[]) {
    state.portals = portals
  },
}

import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { AllReturnReasons, ReturnReasonDetails } from '@/types/returns'

export const useReturnPortalStore = defineStore('returnPortal', () => {
  const returnReasons = ref<AllReturnReasons[]>([])
  const reasonBeingEdited = ref<ReturnReasonDetails>()

  async function fetchReturnReasons() {
    const response = await ReturnPortalApi.fetchReturnReasons()
    setReturnReasons(response.data)
  }

  async function fetchReturnReason(id: number) {
    const response = await ReturnPortalApi.fetchReturnReason(id)
    setReasonBeingEdited(response)
  }

  function setReturnReasons(reasons: AllReturnReasons[]) {
    returnReasons.value = reasons
  }

  function setReasonBeingEdited(reason: ReturnReasonDetails) {
    reasonBeingEdited.value = reason
  }

  return {
    fetchReturnReasons,
    fetchReturnReason,
    returnReasons,
    reasonBeingEdited,
  }
})

export default {
  state,
  getters,
  actions,
  mutations,
  name: 'returnsSettings',
}
