import type { RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { PARCEL_MONITOR_V2_BETA } from '@/features/support/beta-features'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'

export const PARCEL_MONITOR_V2_ROUTE = 'parcel-monitor'
export const PARCEL_MONITOR_V2_EXCEPTIONS_ROUTE = 'parcel-monitor.exceptions'
export const PARCEL_MONITOR_V2_ACTIVE_ROUTE = 'parcel-monitor.active'
export const PARCEL_MONITOR_V2_ALL_ROUTE = 'parcel-monitor.all'
export const PARCEL_MONITOR_V2_BOOKMARKS_ROUTE = 'parcel-monitor.bookmarks'

export default [
  {
    name: PARCEL_MONITOR_V2_ROUTE,
    path: '/parcel-monitor',
    redirect: { name: PARCEL_MONITOR_V2_EXCEPTIONS_ROUTE },
    meta: {
      pageTitle: () => i18n.t('support.parcel_monitor.title'),
      permissions: [],
    },
    beforeEnter: betaGuard(PARCEL_MONITOR_V2_BETA, SHIPPING_INCOMING_ORDERS_ROUTE),
    children: [
      {
        name: PARCEL_MONITOR_V2_EXCEPTIONS_ROUTE,
        path: '/parcel-monitor/exceptions',
        component: () => import('@/features/parcel-monitor-v2/pages/ParcelMonitorPage.vue'),
        meta: {
          pageTitle: () => i18n.t('support.parcel_monitor.title'),
          permissions: [],
          parent: 'parcel-monitor',
        },
        props: { activeTab: 'exceptions' },
      },
      {
        name: PARCEL_MONITOR_V2_ACTIVE_ROUTE,
        path: '/parcel-monitor/active',
        component: () => import('@/features/parcel-monitor-v2/pages/ParcelMonitorPage.vue'),
        meta: {
          pageTitle: () => i18n.t('support.parcel_monitor.title'),
          permissions: [],
          parent: 'parcel-monitor',
        },
        props: { activeTab: 'shipped' },
      },
      {
        name: PARCEL_MONITOR_V2_ALL_ROUTE,
        path: '/parcel-monitor/all',
        component: () => import('@/features/parcel-monitor-v2/pages/ParcelMonitorPage.vue'),
        meta: {
          pageTitle: () => i18n.t('support.parcel_monitor.title'),
          permissions: [],
          parent: 'parcel-monitor',
        },
        props: { activeTab: 'all' },
      },
      {
        name: PARCEL_MONITOR_V2_BOOKMARKS_ROUTE,
        path: '/parcel-monitor/watchlist',
        component: () => import('@/features/parcel-monitor-v2/pages/ParcelMonitorPage.vue'),
        meta: {
          pageTitle: () => i18n.t('support.parcel_monitor.title'),
          permissions: [],
          parent: 'parcel-monitor',
        },
        props: { activeTab: 'bookmarked' },
      },
    ],
  },
] satisfies RouteRecordRaw[]
