<template>
  <div class="title-bar">
    <router-link
      v-if="backLinkRoute"
      :to="backLinkRoute.to"
      class="back-link"
      data-test="back-link"
    >
      <Fa :icon="faChevronLeft" />

      <span>
        {{ backLinkTitle }}
      </span>
    </router-link>
    <div class="title-bar__row">
      <div
        :id="APP_TITLE_BAR_HEADING_SECTION_ELEMENT_ID"
        class="title-bar__heading-section"
      >
        <h1
          class="title-bar-heading"
          data-test="page-title"
        >
          {{ pageTitle }}
          <span
            v-if="showUserId"
            class="ui-badge ui-badge--info title-bar-heading-user ui-margin-8--left"
          >
            {{ $t('User ID') }}: {{ user.id }}
          </span>
        </h1>
      </div>
      <AppTopNavigation
        v-if="buttons && buttons.length > 0"
        :buttons="buttons"
      />
      <div
        v-else
        :id="APP_TITLE_BAR_RIGHT_BUTTONS_SECTION_ELEMENT_ID"
        class="title-bar__right-buttons"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { APP_TITLE_BAR_HEADING_SECTION_ELEMENT_ID, APP_TITLE_BAR_RIGHT_BUTTONS_SECTION_ELEMENT_ID } from '@/application/constants'
import Fa from 'vue-fa'

import type { User, UserPermissions } from '@/types/models'
import type { BackLinkRoute, RouteButton } from '@/common/types/route.types'

const AppTopNavigation = defineAsyncComponent(() => import('@/application/components/AppTopNavigation.vue'))

const route = useRoute()
const store = useStore()
const { t } = useI18n()

const pageTitle = computed<string>(() => store.getters.pageTitle)
const user = computed<User>(() => store.getters.user)

const backLinkRoute = computed<BackLinkRoute | undefined>(() => {
  if (typeof route.meta?.backLinkRoute === 'function') {
    return route.meta.backLinkRoute(route)
  } else {
    return undefined
  }
})

const backLinkTitle = computed<string>(() => {
  return backLinkRoute.value?.title || t('Go back.')
})

const buttons = computed<RouteButton[]>(() => {
  // route.meta.buttons can be either an array or a function
  // function is useful when the buttons depend on the store state, for example
  const buttonsArray = typeof route.meta?.buttons === 'function' ? route.meta.buttons() : route.meta?.buttons

  return (Array.isArray(buttonsArray) ? buttonsArray : []).filter(
    button =>
      typeof button.permissions === 'undefined' ||
      button.permissions.every((p: keyof UserPermissions) => user.value?.permissions[p]),
  )
})

const showUserId = computed<boolean | undefined>(() => route.meta.showUserId)

watch(pageTitle, (newPageTitle) => {
  document.title = newPageTitle
})
</script>

<style lang="scss" scoped>
.title-bar {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: $sc-spacing-16;

  @include breakpoint-min-width(large) {
    margin-bottom: $sc-spacing-16;
  }

  .title-bar__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $sc-spacing-20;
    justify-content: space-between;
    min-height: 44px;
    width: 100%;
  }
}

.title-bar__right-buttons {
  @extend %flex-row;

  gap: $sc-spacing-12;
  flex-wrap: wrap;
}

.title-bar > :not(:first-child) {
  margin-top: $sc-spacing-16;
}

.title-bar__heading-section {
  display: flex;
  align-items: center;
}

.title-bar-heading {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin: 0;

  .title-bar-heading-user {
    font-size: 14px;
    font-weight: 400;
  }

  @include breakpoint-min-width(medium) {
    font-size: 23px;
  }
}

.back-link {
  align-items: center;
  color: currentColor;
  display: inline-flex;
  font-weight: 600;
}

.back-link > :not(:first-child) {
  margin-left: $sc-spacing-8;
}
</style>
