import { defineStore } from 'pinia'
import { ref } from 'vue'

import { getPlans, getPlansFeatures } from '@/features/subscription/api/subscription.api'
import { PLANS_GROUP_ORDER } from '@/features/subscription/constants'

import type { PlanWithFeatures, Plan, PlanGroup, PlanFeatures } from '@/features/subscription/types/plan.types'

export const usePlansStore = defineStore('plans', () => {
  const plans = ref<Plan[]>([])
  const plansFeatures = ref<null | Record<PlanGroup, PlanFeatures>>(null)
  const plansWithFeatures = ref<PlanWithFeatures[]>([])

  async function fetchPlans(): Promise<Plan[]> {
    if (!plans.value.length) {
      plans.value = await getPlans()
    }

    return plans.value
  }

  async function fetchPlansFeatures(): Promise<Record<PlanGroup, PlanFeatures>> {
    if (!plansFeatures.value) {
      plansFeatures.value = await getPlansFeatures()
    }

    return plansFeatures.value
  }

  async function fetchPlansWithFeatures(): Promise<void> {
    const [plans, features] = await Promise.all([
      fetchPlans(),
      fetchPlansFeatures(),
    ])

    plansWithFeatures.value = plans
      .reduce((plansWithFeatures, currentPlan) => {
        if (!plansWithFeatures.find(plan => plan.group === currentPlan.group)) {
          plansWithFeatures.push({
            features: features[currentPlan.group],
            group: currentPlan.group,
            name: currentPlan.title,
            order: PLANS_GROUP_ORDER[currentPlan.group],
          })
        }

        return plansWithFeatures
      }, [] as PlanWithFeatures[])
      .sort((a, b) => a.order - b.order)
  }

  function getMinimumPlanForFeature(featureId: keyof PlanFeatures, featureValue?: number | string,
  ): string {
    const plan = plansWithFeatures.value.find((currentPlanInfo) => {
      if (
        typeof featureValue === 'string' &&
        Array.isArray(currentPlanInfo.features[featureId])
      ) {
        return (currentPlanInfo.features[featureId] as string[]).includes(featureValue)
      }

      if (
        typeof featureValue === 'number' &&
        typeof currentPlanInfo.features[featureId] === 'number'
      ) {
        return currentPlanInfo?.features[featureId] >= featureValue || currentPlanInfo?.features[featureId] === -1
      }

      return currentPlanInfo?.features[featureId]
    })

    if (plan?.name) {
      return plan.name
    }

    throw new Error('No plan was found for the given inputs')
  }

  return {
    fetchPlansWithFeatures,
    fetchPlans,
    fetchPlansFeatures,
    getMinimumPlanForFeature,
    plans,
    plansWithFeatures,
    plansFeatures,
  }
})
