import { CORE_DISPLAY_PAYMENT_MODAL } from '@/common/stores/core/action.types'
import EventBus from '@/common/utils/event-bus'
import axios from '@/common/utils/axios'
import store from '@/common/stores/store'
import type { PaymentSessionConfigurationData } from '@/types/payment'

export const PAYMENT_INVOICE_REF_TYPE = {
  NONE: undefined,
  INITIAL: 'initial',
}

export const RESULT_CODES = {
  AUTHENTICATION_FINISHED: 'AuthenticationFinished',
  AUTHENTICATION_NOT_REQUIRED: 'AuthenticationNotRequired',
  AUTHORISED: 'Authorised',
  CANCELLED: 'Cancelled',
  CHALLENGE_SHOPPER: 'ChallengeShopper',
  ERROR: 'Error',
  IDENTIFY_SHOPPER: 'IdentifyShopper',
  NONE: undefined,
  PENDING: 'Pending',
  PRESENT_TO_SHOPPER: 'PresentToShopper',
  RECEIVED: 'Received',
  REDIRECT_SHOPPER: 'RedirectShopper',
  REFUSED: 'Refused',
  TECHNICAL_ERROR: 'TechnicalError',
}

export const LOCALE_MAP = {
  'nl-nl': 'nl-NL',
  'en-gb': 'en-US',
  'en-us': 'en-US',
  'fr-fr': 'fr-FR',
  'de-de': 'de-DE',
  'it-it': 'it-IT',
  'es-es': 'es-ES',
}

export async function createSession(
  invoiceId: string,
  returnUrl: string,
  recurring: boolean,
): Promise<PaymentSessionConfigurationData | undefined> {
  if (invoiceId === undefined) {
    return
  }

  const url = `/xhr/payments/sessions/${invoiceId}`
  const payload = {
    return_url: returnUrl,
    recurring,
  }

  const response = await axios.post(url, payload)
  return response.data
}

/**
 * Displays the payment modal based on the ID of an invoice.
 * When the invoiceId is "INITIAL", the backend will find or create
 * an initial payment invoice and create the payment session for it.
 *
 * Example:
 * <code>
 * AdyenPaymentService.show(invoiceId)
 *     .then((result) => console.log(result.invoiceId))
 *     .catch ((invoiceId) => console.log(invoiceId + ' dismissed!'))
 * </code>
 *
 * @return {Promise} Rejected with the invoice reference if it's dismissed.
 *                   Resolved with an object containing the invoice reference
 *
 */
export function show(invoiceId: string | undefined): Promise<{ invoiceId: string }> {
  return new Promise((resolve, reject) => {
    if (!invoiceId) {
      return reject(new Error(`Unexpected invoice ID value: "${invoiceId}"`))
    }

    EventBus.$once('payment-modal-completed', (completedInvoiceId: string | undefined) => {
      if (!completedInvoiceId) {
        return reject(new Error('No invoice ID received on completion'))
      }
      return resolve({ invoiceId: completedInvoiceId })
    })

    EventBus.$once('payment-modal-error', (errorInvoiceId: string | undefined) => {
      return reject(new Error(errorInvoiceId || `Error processing invoice ${invoiceId}`))
    })

    store.dispatch(CORE_DISPLAY_PAYMENT_MODAL, invoiceId)
  })
}

export default {
  PAYMENT_INVOICE_REF_TYPE,
  LOCALE_MAP,
  RESULT_CODES,
  createSession,
  show,
}
