import { defineStore } from 'pinia'
import { ref } from 'vue'
import { logError } from '@/common/utils/errors/error-handlers'
import ContractApi from '@/features/carriers/api/contract.api'
import type { RequestStatus } from '@/types/api'
import type { Contract } from '@/features/carriers/types/contract.types'

// Store Definition
export const useContractsStore = defineStore('contracts', () => {
  // State
  const contracts = ref<Contract[]>([])
  const fetchAllContractsRequestStatus = ref<RequestStatus['status']>()

  // Actions
  const fetchContracts = async ({ forceRefetch = true, onlyActive = true } = {}) => {
    if (!forceRefetch && fetchAllContractsRequestStatus.value !== undefined) {
      return
    }

    try {
      fetchAllContractsRequestStatus.value = 'pending'
      let { data: fetchedContracts } = await ContractApi.findAll()

      if (onlyActive) {
        fetchedContracts = fetchedContracts.filter(contract => contract.is_active)
      }

      contracts.value = fetchedContracts.sort(sortContract)
      fetchAllContractsRequestStatus.value = 'ok'
    } catch (error) {
      logError(error)
      fetchAllContractsRequestStatus.value = 'error'
    }
  }

  // Returning State, Getters, and Actions
  return {
    contracts,
    fetchContracts,
    fetchAllContractsRequestStatus,
  }
})

const sortContract = (contractA: Contract, contractB: Contract) => {
  // Compare a boolean is_default
  if (contractA.is_default !== contractB.is_default) {
    return contractB.is_default ? -1 : 1
  }

  // Compare client_id existence
  if (!!contractA.client_id !== !!contractB.client_id) {
    return contractB.client_id ? -1 : 1
  }

  // Compare names (strings)
  return contractA.name.localeCompare(contractB.name)
}
