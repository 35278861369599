import { initializeCustomerIo } from '@/common/utils/marketing-tools/customerio'
import { initializeHubspot } from '@/common/utils/marketing-tools/hubspot'

import type { User } from '@/types/models'
import type { Definitions } from '@/common/types/definitions.types'

export function installMarketingTools(user: User, definitions: Definitions) {
  if (!user || !definitions) {
    throw new Error('Missing required parameters to install user marketing tool')
  }

  if (definitions.enable_user_analytics) {
    initializeHubspot(user)
    initializeCustomerIo(user.id, user.language, user?.invoice_country_iso_2)
  }
}
