import { computed, ref, watch } from 'vue'
import { isCancel } from 'axios'
import { defineStore } from 'pinia'
import { useStore } from 'vuex'
import { useUnreadCounterPolling } from '@/features/whatsapp-conversations/services/composables/use-unread-counter-polling'
import { createAbortController } from '@/common/utils/create-abort-controller'
import { CONVERSATIONS_BETA_FEATURE } from '@/features/whatsapp-conversations/beta-features'
import { fetchChats, fetchMessages, sendMessage, uploadWhatsappMedia } from '@/features/whatsapp-conversations/api/whatsapp-conversations.api'
import { logError } from '@/common/utils/errors/error-handlers'
import { wait } from '@/common/utils/wait'
import ToastService from '@/common/services/toast.service'
import i18n from '@/application/i18n/i18n'
import {
  type WhatsappMessage,
  type WhatsappChat,
  type SendMessagePayload,
} from '@/features/whatsapp-conversations/types/conversations-data-parsers'
import type { RequestStatus } from '@/types/api'
import { arrayToObject } from '@/common/utils/array-helpers/array-to-object'
import { useBrandsStore } from '@/features/brands/stores'
import type { UserPermissions } from '@/types/models'
import { BETA_FEATURE_TRACKING_NOTIFICATIONS } from '@/features/tracking-notifications/beta-features'

type MessageRequestType = 'initialMessages' | 'nextMessages' | 'syncMessages'
type ChatRequestType = 'initialChats' | 'nextChats'
type SendMessageRequestType = 'sendMessage'

type RequestType = MessageRequestType | ChatRequestType | SendMessageRequestType

type Statuses = Partial<Record<RequestType, RequestStatus['status']>>
type AbortControllers = Partial<Record<RequestType, AbortController>>

type FetchMessagesParams = {
  chatId: string
  requestType: MessageRequestType
}
type AdditionalFetchMessagesParams = {
  onBeforeStateUpdate?: () => void
  timeoutMs?: number
  shouldClearPreviousMessages?: boolean
}

type HandleErrorParams = { error: unknown, toastMessage: string, onError?: () => void, onWasCancelled?: () => void }

export const useConversationsStore = defineStore('whatsappConversations', () => {
  const statuses = ref<Statuses>({})
  const abortControllers = ref<AbortControllers>({})
  const whatsappChats = ref<WhatsappChat[]>([])
  const selectedChatId = ref<string>()
  const nextPageChatsCursor = ref<string | null>(null)
  const nextPageMessagesCursor = ref<string | null>(null)
  const whatsAppMessages = ref<WhatsappMessage[]>([])
  const brandsStore = useBrandsStore()
  const { checkIsPersonalWabaConnectedToAnyBrand } = brandsStore

  const store = useStore()
  const permissions = computed<UserPermissions | undefined>(() => store.getters.user?.permissions)
  const hasManageWhatsappPermission = computed(() => permissions.value?.manage_whatsapp_chats || false)

  const vuexStore = useStore()
  const hasConversationsBetaFeature = computed<boolean>(() =>
    vuexStore.getters.hasBetaFeature(CONVERSATIONS_BETA_FEATURE),
  )
  const hasNewTrackingNotifications = computed(() =>
    vuexStore.getters.hasBetaFeature(BETA_FEATURE_TRACKING_NOTIFICATIONS),
  )
  const { throttledUpdateUnreadCounter, startPolling, stopPolling, unreadMessagesCount, unreadMessagesCounterText } =
    useUnreadCounterPolling()

  watch(
    [hasConversationsBetaFeature, hasManageWhatsappPermission],
    async ([hasBeta, hasPermission]) => {
      if (!hasBeta || !hasPermission) {
        return
      }
      const hasPersonalWabaConnected = await checkIsPersonalWabaConnectedToAnyBrand()
      hasPersonalWabaConnected ? startPolling() : stopPolling()
    },
    { immediate: true },
  )

  const handleError = ({
    error,
    onError,
    onWasCancelled,
    toastMessage = i18n.t('Something went wrong'),
  }: HandleErrorParams) => {
    if (isCancel(error)) {
      onWasCancelled?.()
      return
    }
    onError?.()
    ToastService.error(toastMessage)
    logError(error)
  }

  /**
   * This function:
   *
   * 1. Abort of previous request
   * 2. Creation of a new AbortController
   * 3. Setup of timeout abort
   * 4. Returns Axios config
   *
   */
  const configurateSingletonRequest = ({
    requestType,
    timeoutMs,
  }: {
    requestType: RequestType
    timeoutMs: number
  }): { signal: AbortController['signal'] | undefined } => {
    const currentAbortController = createAbortController()
    // we need to do this to avoid race condition when switching chats
    // (could accidentally load messages from previous chat, instead of current)
    abortControllers.value[requestType]?.abort()
    abortControllers.value[requestType] = currentAbortController

    timeoutMs && setTimeout(() => currentAbortController?.abort(), timeoutMs)
    return { signal: abortControllers.value[requestType]?.signal }
  }

  const fetchWhatsappChats = async (
    requestType: ChatRequestType,
    { timeoutMs = 0, onlyUnread = false } = {},
  ) => {
    const previousRequestStatus = statuses.value[requestType]
    statuses.value[requestType] = 'pending'
    const axiosConfig = configurateSingletonRequest({ requestType, timeoutMs })
    try {
      const isInitial = requestType === 'initialChats'
      // if its initial call, means previous calls for next chats should be aborted,
      // as it will likely fetch a wrong page of next chats
      isInitial && abortControllers.value['nextChats']?.abort()

      const { data: chats, next } = await fetchChats(
        {
          cursor: requestType === 'nextChats' ? nextPageChatsCursor.value : null,
          onlyUnread,
        },
        axiosConfig,
      )

      if (isInitial) {
        whatsappChats.value = chats
      } else {
        whatsappChats.value.push(...chats)
      }
      nextPageChatsCursor.value = next
      statuses.value[requestType] = 'ok'

      // we do not await, as it is just a side effect:
      // we need to update the counter after the chats were loaded
      isInitial && wait(300).then(throttledUpdateUnreadCounter)
    } catch (error) {
      handleError({
        error,
        // TODO: ADD REQUEST SPECIFIC TEXTS AND TRANSLATIONS:
        toastMessage: i18n.t('Something went wrong'),
        onWasCancelled: () => {
          // if there were no new requests - bring back previous status
          if (axiosConfig.signal === abortControllers.value[requestType]?.signal) {
            statuses.value[requestType] = previousRequestStatus
          }
        },
        onError: () => {
          statuses.value[requestType] = 'error'
        },
      })
    }
  }

  const fetchWhatsappMessages = async (
    { chatId, requestType }: FetchMessagesParams,
    { onBeforeStateUpdate, timeoutMs = 0, shouldClearPreviousMessages = true }: AdditionalFetchMessagesParams = {},
  ) => {
    const previousRequestStatus = statuses.value[requestType]
    statuses.value[requestType] = 'pending'
    const axiosConfig = configurateSingletonRequest({ requestType, timeoutMs })
    try {
      const isInitial = requestType === 'initialMessages'
      // if its initial call, means previous calls for next message should be aborted,
      // as it will fetch messages for the wrong chat
      if (isInitial) {
        shouldClearPreviousMessages && clearMessages()
        abortControllers.value['nextMessages']?.abort()
      }

      const { data: messages, next } = await fetchMessages(
        {
          chatId,
          cursor: requestType === 'nextMessages' ? nextPageMessagesCursor.value : null,
        },
        axiosConfig,
      )

      onBeforeStateUpdate?.()

      if (!isInitial) {
        whatsAppMessages.value.push(...messages)
      } else {
        whatsAppMessages.value = messages
      }

      nextPageMessagesCursor.value = next

      statuses.value[requestType] = 'ok'

      // we do not await, as it is just a side effect:
      // we need to update the counter after the chat was open
      isInitial && wait(300).then(throttledUpdateUnreadCounter)
    } catch (error) {
      handleError({
        error,
        // TODO: ADD REQUEST SPECIFIC TEXTS AND TRANSLATIONS:
        toastMessage: i18n.t('Something went wrong'),
        onWasCancelled: () => {
          // if there were no new requests - bring back previous status
          if (axiosConfig.signal === abortControllers.value[requestType]?.signal) {
            statuses.value[requestType] = previousRequestStatus
          }
        },
        onError: () => {
          statuses.value[requestType] = 'error'
        },
      })
    }
  }

  const sendWhatsappMessage = async (payload: SendMessagePayload) => {
    if (!selectedChatId.value) {
      return false
    }
    statuses.value.sendMessage = 'pending'

    try {
      const sendingMessageChatId = selectedChatId.value
      const newMessage = await sendMessage(payload)
      updateChatsLastMessage(sendingMessageChatId, newMessage)

      statuses.value.sendMessage = 'ok'

      if (sendingMessageChatId === selectedChatId.value) {
        appendNewMessage(newMessage)
        return true
      }

      return false
    } catch (error) {
      handleError({
        error,
        // TODO: ADD REQUEST SPECIFIC TEXTS AND TRANSLATIONS:
        toastMessage: i18n.t('Something went wrong'),
        onError: () => {
          statuses.value.sendMessage = 'error'
        },
      })
    }
  }

  const syncWhatsappMessages = async () => {
    if (!selectedChatId.value) {
      return
    }

    statuses.value.syncMessages = 'pending'
    try {
      const { data: messages } = await fetchMessages(
        {
          chatId: selectedChatId.value,
          cursor: null,
        },
      )
      seamlessMessagesUpdate(messages)

      statuses.value.syncMessages = 'ok'
    } catch (error) {
      handleError({
        error,
        // TODO: ADD REQUEST SPECIFIC TEXTS AND TRANSLATIONS:
        toastMessage: i18n.t('Something went wrong'),
        onError: () => {
          statuses.value.syncMessages = 'error'
        },
      })
    }
  }

  const seamlessMessagesUpdate = (newMessages: WhatsappMessage[]) => {
    const newMessagesMap = arrayToObject(newMessages, {
      getKey: message => message.id,
      getValue: message => message,
    })

    // forEach, not map, so that we do not update array without necessity and only update items surgically
    whatsAppMessages.value.forEach((oldMessage, i) => {
      if (newMessagesMap[oldMessage.id]) {
        whatsAppMessages.value[i] = newMessagesMap[oldMessage.id]
      }
    })
  }

  const updateChatsLastMessage = (chatId: string, newMessage: WhatsappMessage) => {
    whatsappChats.value = whatsappChats.value.map(chat =>
      chat.id === chatId
        ? {
            ...chat,
            last_message: newMessage,
            last_message_at: newMessage.created_at,
          }
        : chat,
    )
  }

  const clearSelectedChat = () => {
    selectedChatId.value = undefined
    clearMessages()
  }

  const clearMessages = () => {
    whatsAppMessages.value = []
  }

  const clearWhatsappConversationStore = () => {
    clearSelectedChat()
    whatsappChats.value = []
  }

  const appendNewMessage = (sentMessage: WhatsappMessage) => {
    whatsAppMessages.value.unshift(sentMessage)
  }

  const uploadMediaFile = async (file: File) => {
    if (!selectedChatId.value) {
      return
    }

    return await uploadWhatsappMedia({ chatId: selectedChatId.value, file })
  }

  let cachedPreviousSelectedChat: WhatsappChat | undefined = undefined

  const selectedWhatsappChat = computed(() => {
    if (!selectedChatId.value) {
      return undefined
    }
    const existingChat = whatsappChats.value.find(whatsappChat => whatsappChat.id === selectedChatId.value)

    if (existingChat) {
      return existingChat
    }

    // we could have updated the chat list and lost the chat we had. But if it was open, we keep it the same
    if (cachedPreviousSelectedChat && cachedPreviousSelectedChat?.id === selectedChatId.value) {
      return cachedPreviousSelectedChat
    }

    // we have a selectedChatId but we couldn't find it in the list
    logError(new Error('Failed to find selected Whatsapp chat'))
    ToastService.error(i18n.t('Oops something went wrong'))
    return undefined
  })

  watch(selectedWhatsappChat, (currentChat) => {
    if (!currentChat) {
      return
    }
    // We cache selectedChat to keep it even if our chatList updates and no longer has it
    cachedPreviousSelectedChat = currentChat
    // BE auto-marks all new messages as read as soon as we open active chat
    currentChat.unread_messages_count = 0
  })

  return {
    statuses,
    abortControllers,
    whatsappChats,
    whatsAppMessages,
    unreadMessagesCount,
    unreadMessagesCounterText,
    selectedChatId,
    nextPageChatsCursor,
    nextPageMessagesCursor,
    selectedWhatsappChat,
    hasNewTrackingNotifications,
    fetchWhatsappChats,
    fetchWhatsappMessages,
    syncWhatsappMessages,
    sendWhatsappMessage,
    uploadMediaFile,
    clearMessages,
    clearSelectedChat,
    clearWhatsappConversationStore,
  }
})
