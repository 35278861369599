<template>
  <UiModal
    v-if="resultInfo"
    :is-open="isModalOpen"
    :has-close-button="resultInfo.resultCode !== RESULT_CODES.TECHNICAL_ERROR"
    :hide-title="title === ''"
    :esc-emits-close-event="resultInfo.resultCode !== RESULT_CODES.TECHNICAL_ERROR"
    :outside-click-emits-close-event="resultInfo.resultCode !== RESULT_CODES.TECHNICAL_ERROR"
    @close="closeModal"
  >
    <template #title>
      <span class="ui-margin-12--right">
        <Fa
          v-if="isNonTechnicalError"
          class="app-payment-result-modal__error-icon"
          :icon="faExclamationTriangle"
        />
        <Fa
          v-else-if="resultInfo.resultCode === RESULT_CODES.AUTHORISED"
          class="app-payment-result-modal__success-icon"
          :icon="faCheckCircle"
        />
        <Fa
          v-else
          class="app-payment-result-modal__info-icon"
          :icon="faInfoCircle"
        />
      </span>
      <span>
        {{ title }}
      </span>
    </template>

    <section v-if="resultInfo.resultCode === RESULT_CODES.TECHNICAL_ERROR">
      <div class="ui-text:center ui-margin-16--top">
        <img
          class="app-payment-result-modal__image"
          src="@/application/assets/img/payment-technical-error.svg"
          :alt="t('Technical error image')"
        >

        <h3>{{ t('Oops something went wrong') }}</h3>
        <p class="app-payment-result-modal__info">
          {{ t('The payment provider is unresponsive or another error response occurred') }}
        </p>

        <button
          type="button"
          class="ui-button ui-button--primary ui-margin-12--top ui-margin-16--bottom"
          @click="closeModal"
        >
          {{ t('Try again later') }}
        </button>
      </div>
    </section>

    <section v-else>
      <p class="app-payment-result-modal__info">
        {{ extraInfo }}
      </p>

      <button
        class="ui-button ui-button--primary ui-margin-12--top app-payment-result-modal__button"
        type="button"
        @click="closeModal"
      >
        {{ t('Ok') }}
      </button>
    </section>
  </UiModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import Fa from 'vue-fa'
import { faCheckCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { UiModal } from '@sendcloud/ui-library'
import { RETURN_SETTINGS_ONBOARDING } from '@/features/return-portal/constants'
import { SETTINGS_RETURNS_ROUTE } from '@/application/routes/settings.routes.names'
import Segment from '@/common/utils/tracking/segment'

import AdyenPaymentService from '@/features/payment-wall/services/adyen-payment.service'
import { CORE_DISPLAY_PAYMENT_MODAL } from '@/common/stores/core/action.types'
import EventBus from '@/common/utils/event-bus'
import type { User } from '@/types/models'
import type { ResultInfo } from '@/features/payment-wall/types/payment-wall.types'

const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

const EXTRA_INFO_MAP = computed(() => ({
  [AdyenPaymentService.RESULT_CODES.AUTHORISED]: t('Your payment was successful!'),
  [AdyenPaymentService.RESULT_CODES.CANCELLED]: t('Your payment was interrupted, please try again'),
  [AdyenPaymentService.RESULT_CODES.ERROR]: t(
    'An error occurred while processing your payment, please try again later',
  ),
  [AdyenPaymentService.RESULT_CODES.REFUSED]: t('The transaction was refused by your bank'),
  [AdyenPaymentService.RESULT_CODES.PENDING]: t('Your payment is being processed'),
  [AdyenPaymentService.RESULT_CODES.RECEIVED]: t('Your payment is being processed'),
}),
)

const TITLES_MAP = computed(() => ({
  [AdyenPaymentService.RESULT_CODES.AUTHORISED]: t('Payment successful'),
  [AdyenPaymentService.RESULT_CODES.CANCELLED]: t('Payment cancelled'),
  [AdyenPaymentService.RESULT_CODES.ERROR]: t('An error occurred'),
  [AdyenPaymentService.RESULT_CODES.REFUSED]: t('Payment declined'),
  [AdyenPaymentService.RESULT_CODES.PENDING]: t('Payment in progress'),
  [AdyenPaymentService.RESULT_CODES.RECEIVED]: t('Payment in progress'),
}),
)

const props = withDefaults(defineProps<{
  resultInfo: ResultInfo | undefined
}>(), {
  resultInfo: () => ({
    resultCode: 'UNKNOWN',
    isRedirect: false,
  }),
})

const emit = defineEmits(['close'])

const RESULT_CODES = AdyenPaymentService.RESULT_CODES
const INVOICE_REF_TYPES = AdyenPaymentService.PAYMENT_INVOICE_REF_TYPE

const paymentInvoiceId = computed(() => store.getters.paymentInvoiceId)
const user = computed<User>(() => store.getters.user)

const extraInfo = computed(() =>
  EXTRA_INFO_MAP.value[props.resultInfo.resultCode as keyof typeof EXTRA_INFO_MAP.value] || 'Unknown result',
)

const title = computed(() =>
  TITLES_MAP.value[props.resultInfo.resultCode as keyof typeof TITLES_MAP.value] || 'Unknown title',
)

const isNonTechnicalError = computed(() =>
  props.resultInfo.resultCode === RESULT_CODES.ERROR ||
  props.resultInfo.resultCode === RESULT_CODES.REFUSED ||
  props.resultInfo.resultCode === RESULT_CODES.CANCELLED,
)

const isModalOpen = computed(() => Boolean(props.resultInfo.resultCode))

/**
 * Clears the current invoice ID, emits the error event for the payment modal, and triggers closing the modal.
 *
 * Until the beta-testing period is over, the error event cannot be emitted from the payment modal.
 * The reason is that the user still has the possibility to use the old implementation.
 */
function closeModal() {
  const isError = props.resultInfo.resultCode === RESULT_CODES.TECHNICAL_ERROR || isNonTechnicalError.value
  const invoiceId = paymentInvoiceId.value || undefined
  if (isError && !!invoiceId) {
    EventBus.$emit('payment-modal-error', invoiceId)
  }

  const hasStatusChanged =
        props.resultInfo.resultCode === RESULT_CODES.AUTHORISED ||
        props.resultInfo.resultCode === RESULT_CODES.PENDING ||
        props.resultInfo.resultCode === RESULT_CODES.RECEIVED

  if (route.query?.onboarding === RETURN_SETTINGS_ONBOARDING) {
    Segment.track(
      'Return portal onboarding - enable direct debit completed',
      {
        user_id: user.value.id,
        country: user.value.invoice_country_iso_2,
        subscription_type: user.value.new_plan_group,
      },
      { integrations: { All: true, Mixpanel: true } },
    )
    router.push({ name: SETTINGS_RETURNS_ROUTE, query: { onboarding: RETURN_SETTINGS_ONBOARDING } })
    emit('close')
  } else if (props.resultInfo.isRedirect && hasStatusChanged) {
    window.location.reload()
  } else {
    store.dispatch(CORE_DISPLAY_PAYMENT_MODAL, INVOICE_REF_TYPES.NONE)
    emit('close')
  }
}
</script>

<style lang="scss">
.app-payment-result-modal__info-icon {
  color: $sc-informative;
}

.app-payment-result-modal__error-icon {
  color: $sc-danger;
}

.app-payment-result-modal__success-icon {
  color: $sc-positive;
}

.app-payment-result-modal__button {
  width: 100%;
}

.app-payment-result-modal__fallback-notification svg {
  margin-top: 0 !important;
  display: flex;
  align-self: center;
}

.app-payment-result-modal__fallback-notification-content {
  display: flex;
  align-items: center;
}

.app-payment-result-modal__fallback-notification-message {
  flex-grow: 1;
}

.app-payment-result-modal__image {
  width: 50%;
}

.app-payment-result-modal__info {
  font-size: 16px;
}
</style>
