import Segment from '@/common/utils/tracking/segment'
import type { ShippingRuleCreatedData } from '@/types/tracking'

const mixpanelOnly = {
  integrations: {
    Mixpanel: true,
    All: false,
  },
}

export type RulesFilterAppliedEventParams = {
  updated_filter: 'action_tag' | 'condition_tag' | 'rules_status'
  updated_filter_value: string[]
}

export default {
  shippingRuleCreatedOrUpdated({
    isNewlyCreatedRule,
    ruleName,
    conditionsProperty,
    conditionsQuantity,
    actionsType,
    actionsQuantity,
    userSubscriptionPlan,
  }: ShippingRuleCreatedData) {
    Segment.track(
      `Shipping rules - Rule ${isNewlyCreatedRule ? 'created' : 'updated'}`,
      {
        'Rule name': ruleName,
        'Conditions property': conditionsProperty,
        'Conditions quantity': conditionsQuantity,
        'Actions type': actionsType,
        'Actions quantity': actionsQuantity,
        'User subscription plan': userSubscriptionPlan,
      },
      mixpanelOnly,
    )
  },

  openShippingRuleImportPage() {
    Segment.track('Shipping rules - Open shipping rule import', {}, mixpanelOnly)
  },

  importShippingRule(
    { isSuccessful, numberOfImportedShippingRules }: { isSuccessful: boolean, numberOfImportedShippingRules?: number },
  ) {
    Segment.track(
      'Shipping rules - Import shipping rule',
      { 'Successful': isSuccessful, 'Number of imported shipping rules': numberOfImportedShippingRules },
      mixpanelOnly,
    )
  },

  downloadTemplate() {
    Segment.track('Shipping rules - Download template', {}, mixpanelOnly)
  },

  downloadCheatlist() {
    Segment.track('Shipping rules - Download cheatlist', {}, mixpanelOnly)
  },

  openHelpCenter(source: string) {
    Segment.track(
      'Shipping rules - Open help center',
      {
        source: source,
      },
      mixpanelOnly,
    )
  },

  openAcademy(source: string) {
    Segment.track(
      'Shipping rules - Open academy',
      {
        source: source,
      },
      mixpanelOnly,
    )
  },

  trackRulesFiltersApplied(params: RulesFilterAppliedEventParams) {
    Segment.track('Rules Filter Applied', params)
  },
}
