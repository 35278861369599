import axios from '@/common/utils/axios'

import type { ShippingDefaultsPayload, ShippingDefaults } from '@/features/shipping-defaults/types/shipping-defaults.types'

export default {
  async getDefaults(): Promise<ShippingDefaults> {
    const { data } = await axios.get('/xhr/shipping-defaults')
    return data
  },

  /** TODO: This should ideally become a PATCH request instead of PUT */
  async updateDefaults(payload: Partial<ShippingDefaultsPayload>): Promise<ShippingDefaults> {
    const { data } = await axios.put('/xhr/shipping-defaults', payload)
    return data
  },
}
