import store from '@/common/stores/store'
import { useContractsStore } from '@/features/carriers/stores/carrier-contracts/contracts'
import type { Carrier } from '@/features/carriers/types/carrier.types'
import type { ShippingMethod } from '@/features/shipment-tabs/stores/shipping'
import type { ParcelDirection } from '@/types/shipping.models'
import { DIRECTION_INCOMING } from '@/app/common/constants'
import { storeToRefs } from 'pinia'

/**
 * To fetch shipping prices for multiple shipping methods, each method must include a contract ID when the merchant has
 * multiple contracts with the carrier. However, the Order type does not always contain a contract ID, requiring us to
 * deduce it from the selected shipping method. If the provided contract ID is invalid for the chosen shipping method,
 * the first applicable contract will be used. If no applicable contracts are available, the contract will be omitted.
 * When no applicable contracts are identified, it indicates that only one contract is available, and the backend will
 * automatically return prices for that contract.
 *
 * NOTE: This is a temporary solution to facilitate the migration of Isendu customers.
 * The business logic should be migrated to the backend in the future.
 */
export function determineContract(
  shippingMethodId: number,
  contractId: number | null | undefined,
  direction: ParcelDirection,
) {
  const contractsStore = useContractsStore()
  const { contracts } = storeToRefs(contractsStore)

  // If no contracts are available, return undefined
  if (!contracts.value.length) {
    return undefined
  }

  const shippingMethod: ShippingMethod = direction === DIRECTION_INCOMING
    ? store.getters.incomingShippingMethodById(shippingMethodId)
    : store.getters.shippingMethodById(shippingMethodId)

  const shippingMethodCarrier: Carrier = store.getters.carrierById(shippingMethod.carrier)
  const applicableContractIds = contracts.value
    .filter(contract => contract.carrier === shippingMethodCarrier.id)
    .map(contract => contract.id)

  // If a contract has been provided, check its validity for the shipping method
  if (contractId && applicableContractIds.includes(contractId)) {
    return contractId
  }

  // If no contract has been provided, return the first applicable one
  if (applicableContractIds.length) {
    return applicableContractIds[0]
  }

  // Provided contract turned out to be not valid and no other applicable contracts were found
  return undefined
}
