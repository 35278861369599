import axios from '@/common/utils/axios'

import type { ShipmentCount, SubscriptionsPromotion, UserSubscription } from '@/features/subscription/types/subscriptions.types'
import type { Plan, PlanCode, PlanFeatures, PlanGroup } from '@/features/subscription/types/plan.types'

export async function getSubscriptionPromotions(): Promise<SubscriptionsPromotion[]> {
  const { data } = await axios.get('/xhr/subscriptions/promotions?new_plans=1')
  return data
}

export async function getUserSubscriptions(): Promise<UserSubscription[]> {
  const { data } = await axios.get('/xhr/user-subscriptions')
  return data
}

export async function getShipmentCount(): Promise<ShipmentCount> {
  const { data } = await axios.get('/xhr/user-subscriptions/shipment-count')
  return data
}

export async function createUserSubscription({ code }: { code: PlanCode }): Promise<void> {
  return axios.post('/xhr/user-subscriptions', { code })
}

export async function switchUserSubscription(
  payload: { code: PlanCode, explanation: string },
): Promise<void> {
  return axios.post('/xhr/user-subscriptions/switch', payload)
}

export async function deactivateUserSubscription(
  payload: { id: number, explanation: string, reasons: [] },
): Promise<void> {
  return axios.post('/xhr/user-subscriptions/deactivate', payload)
}

export async function getPlans(): Promise<Plan[]> {
  const { data } = await axios.get('/xhr/subscriptions?new_plans=1')
  return data
}

export async function getPlansFeatures(): Promise<Record<PlanGroup, PlanFeatures>> {
  const { data } = await axios.get('/xhr/subscriptions-features')
  return data
}
