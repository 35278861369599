import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import OnboardingWizardService from '@/features/onboarding-wizard/api/onboarding-wizard.api'
import { logError } from '@/common/utils/errors/error-handlers'
import type { Step, Status } from '@/features/onboarding-wizard/types/onboarding-wizard.types'

type Integration = {
  id: number | null
  systemCode: string | null
}

// Store Definition
export const useOnboardingWizardStore = defineStore('onboardingWizard', () => {
  // State
  const steps = ref<Step[]>([])
  const step = ref<Step | null>(null)
  const status = ref<Status>({
    is_wizard_skipped: true,
    is_wizard_complete: true,
  })
  const userIntegration = ref<Integration>({
    id: null,
    systemCode: null,
  })

  const experiments = ref<Record<string, any>>({})

  // Getters
  const getExperimentsOW = computed(() => experiments.value)

  const getPrimaryStepsOnboardingWizard = computed<Step[]>(() => steps.value.filter(step => step.type === 'primary'))

  const isWizardSkipped = computed(() => status.value.is_wizard_skipped)

  const isWizardShown = computed(() => !status.value.is_wizard_complete)

  const totalSteps = computed(() => getPrimaryStepsOnboardingWizard.value.length + 1)

  const completedSteps = computed(() => {
    let completedCount = 1
    getPrimaryStepsOnboardingWizard.value.forEach((step) => {
      if (step.is_complete) completedCount++
    })
    return completedCount
  })

  const isMandatoryStepUncompleted = computed(() => {
    const mandatoryStep = steps.value.find(step => step.id === 'your-details')
    return mandatoryStep ? !mandatoryStep.is_complete : false
  })

  // Actions
  async function fetchSteps() {
    try {
      const response = await OnboardingWizardService.fetchAllSteps()
      if (response && response.steps) {
        steps.value = response.steps
      }
    } catch (error) {
      logError(error)
    }
  }

  async function fetchStep(stepId: string) {
    try {
      const fetchedStep = await OnboardingWizardService.fetchStep(stepId)
      step.value = fetchedStep
    } catch (error) {
      logError(error)
    }
  }

  async function fetchStatus() {
    try {
      const response = await OnboardingWizardService.fetchStatus()
      if (response && response.status) {
        status.value = {
          is_wizard_skipped: response.status.is_wizard_skipped ?? true,
          is_wizard_complete: response.status.is_wizard_complete ?? false,
        }
      }
    } catch (error) {
      logError(error)
      status.value.is_wizard_complete = true
    }
  }

  async function completeStep(stepId: string) {
    const currentStep = steps.value.find(step => step.id === stepId)
    if (currentStep && !currentStep.is_complete) {
      try {
        await OnboardingWizardService.completeStep(stepId)
      } catch (error) {
        logError(error)
      }
    }
  }

  async function skipWizard() {
    if (!isWizardSkipped.value) {
      try {
        await OnboardingWizardService.skipWizard()
        await fetchStatus()
      } catch (error) {
        logError(error)
      }
    }
  }

  async function saveYourDetails(formData: Record<string, any>) {
    try {
      await OnboardingWizardService.saveYourDetails(formData)
      completeStep('your-details')
    } catch (error) {
      logError(error)
      throw error
    }
  }

  function resetIntegrationForm() {
    userIntegration.value = {
      id: null,
      systemCode: null,
    }
  }

  function setIntegrationForm(integration: Integration) {
    userIntegration.value = integration
  }

  // Returning State, Getters, and Actions
  return {
    steps,
    step,
    status,
    userIntegration,
    experiments,
    getExperimentsOW,
    getPrimaryStepsOnboardingWizard,
    isWizardSkipped,
    isWizardShown,
    totalSteps,
    completedSteps,
    isMandatoryStepUncompleted,
    fetchSteps,
    fetchStep,
    fetchStatus,
    completeStep,
    skipWizard,
    saveYourDetails,
    resetIntegrationForm,
    setIntegrationForm,
  }
})
