import type { RouteRecordRaw } from 'vue-router'

import i18n from '@/application/i18n/i18n'

export const SETTINGS_PLANS_ROUTE = 'settings.subscriptions.plans'

export default [
  {
    name: SETTINGS_PLANS_ROUTE,
    path: '/accounts/subscriptions/plans',
    component: () => import('@/features/subscription/pages/PlansSettingsPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Plans'),
      permissions: ['manage_subscriptions'],
      parent: 'account-plans',
    },
    alias: ['/settings/subscriptions/plans', '/accounts/subscriptions/plans'],
  },
] satisfies RouteRecordRaw[]
