import store from '@/common/stores/store'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import Smooch from 'smooch'
import { getZendeskToken } from '@/features/support/api/support.api'
import i18n from '@/application/i18n/i18n'
import type { SupportChatMetafields } from '@/features/support/types/support.types'
import { logError } from '@/common/utils/errors/error-handlers'
import { faMinus } from '@fortawesome/pro-solid-svg-icons'

const COLOR_SENDCLOUD_DARK_BLUE = '112857'
const COLOR_SENDCLOUD_LIGHT_BLUE = '1d97ff'

let isSmoochReady = false
dayjs.extend(isBetween)

const WIDGET_MODES = {
  DEFAULT: 'default',
  ONBOARDING: 'onboarding',
}

const eventHandlers: {
  widgetClosed: ((event?: unknown) => void) | null
} = {
  widgetClosed: null,
}
/**
 * Returns the Chat widget container element
 */
function getChatWidgetContainer(): HTMLIFrameElement | null {
  return document.querySelector('#web-messenger-container')
}

/**
 * Returns an object with the populated metafields for the widget
 */
function getMetafields(): SupportChatMetafields {
  return {
    corporation_id: store.getters.user.corporation?.id,
    corporation_email: store.getters.user.corporation?.email,
    date_joined: store.getters.user.date_joined?.substring(0, 10), // date in YYYY-MM-DD format
    email: store.getters.user.email,
    invoice_country: store.getters.user.corporation?.invoice_country_iso_2,
    name: store.getters.user.company_name,
    plan: store.getters.user.new_plan_group,
    user_email: store.getters.user.email,
    user_id: store.getters.user.id,
  }
}

async function setSmoochMetafields() {
  await Smooch.updateUser({
    metadata: getMetafields(),
  })
}

async function createSmoochConversation() {
  if (Smooch.isOpened()) {
    const conversation = await Smooch.createConversation({})
    toggleHideChatFooter(true)
    await Smooch.loadConversation(conversation.id)
    sendSmoochHiddenMessage(conversation)
  }
}

/**
 * Sends a hidden message to trigger the widget autoreply
 */
function sendSmoochHiddenMessage(conversation: Conversation, message?: string) {
  Smooch.sendMessage(
    {
      type: 'text',
      text: message || i18n.t('support.chat.hidden_hi_message'),
      // @ts-expect-error we can send metadata
      metadata: {
        hidden: true,
      },
    },
    conversation.id,
  )
}

/**
 * Attachs an event listener to the new conversation button
 */
function addConversationButtonListener(iframeDocument: Document) {
  const conversationFooter = iframeDocument.getElementsByClassName('conversation-group-footer')[0]
  const newConversationButton = conversationFooter?.querySelector('button')
  if (newConversationButton) {
    newConversationButton.addEventListener('click', () => createSmoochConversation())
  }
}

/**
 * Overrides the syling removing the Powered by Zendesk logo
 */
function overrideSmoochStyles(iframe: HTMLIFrameElement) {
  if (iframe.contentDocument && iframe.contentDocument.styleSheets) {
    const styleElement = iframe.contentDocument.createElement('style')
    const styles = `
      .messages-container .logo {
        display: none;
      }
      .footer-transition.hidden {
        display: none !important;
      }
    `
    styleElement.innerHTML = styles
    iframe.contentDocument.head.appendChild(styleElement)
  }
}

/**
 * Hides the chatbox footer so the user cannot type.
 */
function toggleHideChatFooter(shouldHide = false) {
  const iframe = getChatWidgetContainer()
  const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document
  const footerElement = iframeDocument?.querySelector('.footer-transition')

  if (!footerElement) {
    return
  }

  if (shouldHide) {
    footerElement.classList.add('hidden')
  } else {
    footerElement.classList.remove('hidden')
  }
}

function replaceCloseIcon() {
  const iframe = getChatWidgetContainer()
  const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document
  const closeIcon = iframeDocument?.body.querySelector('.close-icon') as SVGElement

  if (!closeIcon) return
  closeIcon.setAttribute('viewBox', `0 0 ${faMinus.icon[0]} ${faMinus.icon[1]}`)
  closeIcon.innerHTML = `<path fill="#FFFFFF" d="${faMinus.icon[4]}"/>`
}

function toggleBackButton(display: string): void {
  const iframe = getChatWidgetContainer()
  const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document
  const backHandle = iframeDocument?.querySelector('.back-handle') as HTMLElement

  if (backHandle) {
    backHandle.style.display = display
  }
}

function hideBackButton(): void {
  toggleBackButton('none')
}

function showBackButton(): void {
  toggleBackButton('inline-block')
}

function createSpinnerElement(iframeDocument: Document): HTMLElement {
  const statusElement = iframeDocument.createElement('div')
  statusElement.className = 'hidden-message-status'
  statusElement.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `

  const spinner = iframeDocument.createElement('div')
  spinner.className = 'spinner'
  spinner.style.cssText = `
    width: 40px;
    height: 40px;
    border: 4px solid rgba(29, 151, 255, 0.2);
    border-top-color: #${COLOR_SENDCLOUD_LIGHT_BLUE};
    border-radius: 50%;
    animation: spin 1s linear infinite;
  `

  const waitText = iframeDocument.createElement('div')
  waitText.className = 'wait-text'
  waitText.textContent = i18n.t('Please wait.')
  waitText.style.cssText = `
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
  `

  if (!iframeDocument.querySelector('style#spinner-animation')) {
    const styleElement = iframeDocument.createElement('style')
    styleElement.id = 'spinner-animation'
    styleElement.textContent = `
      @keyframes spin {
        to { transform: rotate(360deg); }
      }
    `
    iframeDocument.head.appendChild(styleElement)
  }

  statusElement.appendChild(spinner)
  statusElement.appendChild(waitText)

  return statusElement
}

function removeSpinnerElement(iframeDocument: Document): void {
  const spinnerElement = iframeDocument.querySelector('.hidden-message-status')
  if (spinnerElement && spinnerElement.parentNode) {
    spinnerElement.parentNode.removeChild(spinnerElement)
  }
}

/**
 * Positions the chat widget based on mode and screen size
 */
function positionChatWidget(mode = WIDGET_MODES.DEFAULT) {
  const widgetContainer = getChatWidgetContainer()
  if (!widgetContainer) return

  if (mode === WIDGET_MODES.ONBOARDING && window.innerWidth >= 768) {
    // For onboarding on larger screens, position to the left
    widgetContainer.style.left = '20px'
  } else {
    // Default position (bottom right)
    widgetContainer.style.left = 'auto'
  }
}

/**
 * Handles widget closed event for onboarding flow
 */
function handleOnboardingWidgetClosed() {
  SupportChatService.hideChatWidget()
}

/**
 * Setup Smooch event handlers
 */
function setupSmoochEventHandlers() {
  Smooch.on('ready', function () {
    isSmoochReady = true
  })

  Smooch.on('widget:opened', async function () {
    if (Smooch.getConversations().length === 0) {
      const newConversation = await Smooch.createConversation({
        metadata: {
          language: i18n.locale,
          webpage: window.location.href,
        },
      })
      sendSmoochHiddenMessage(newConversation)
    }
    replaceCloseIcon()
  })

  Smooch.on('message:sent', async function (message, _data) {
    // @ts-expect-error metadata does exist
    if (message.metadata?.hidden) {
      const iframe = getChatWidgetContainer()
      if (iframe && iframe.contentDocument) {
        const iframeDocument = iframe.contentDocument

        const spinnerElement = createSpinnerElement(iframeDocument)
        hideBackButton()
        iframeDocument.body.appendChild(spinnerElement)
      }
    }
  })

  Smooch.on('message:received', async function (message, data) {
    const currentConversation = await Smooch.getConversationById(data.conversation.id)
    const displayedConversation = Smooch.getDisplayedConversation()

    // Remove spinner element when a message is received
    const iframe = getChatWidgetContainer()
    if (iframe && iframe.contentDocument) {
      removeSpinnerElement(iframe.contentDocument)
      showBackButton()
    }

    if (displayedConversation?.id === currentConversation?.id) {
      // we hide the footer with input and attach button if there are multiple actions or we get special metadata
      // @ts-expect-error we can send metadata
      if (message.metadata?.hideFooter || message.actions?.length > 1) {
        toggleHideChatFooter(true)
        return
      }
      toggleHideChatFooter(false)
    }

    // @ts-expect-error message does have avatarUrl
    if (currentConversation.iconUrl !== message.avatarUrl) {
      await Smooch.updateConversation(data.conversation.id, {
        // @ts-expect-error message does have avatarUrl
        iconUrl: message.avatarUrl,
      })
    }
  })
}

const SupportChatService = {
  async startSmoochWidget() {
    setupSmoochEventHandlers()

    let jwtZendesk = ''

    try {
      jwtZendesk = await this.getZendeskToken()
    } catch (error) {
      logError(error)
    }

    if (!jwtZendesk) {
      return
    }

    await Smooch.init({
      integrationId: '64da4e698cb265aea1149db7',
      externalId: String(store.getters.user?.corporation?.id),
      jwt: jwtZendesk,
      businessName: 'Sendcloud Messenger',
      locale: i18n.locale,
      businessIconUrl:
        'https://www.sendcloud.com/wp-content/help-center-images/Sendcloud%20brands/eyJwYXRoIjoic2VuZGNsb3VkXC9maWxlXC9GWHNaOTNYckJRV2lYNmJpN0dlUi5wbmcifQ_sendcloud_umGaaXQ-BT_JVn2SWSAXIIfZghsRqnSNj5KKiR4GWHM.png',
      customColors: {
        brandColor: COLOR_SENDCLOUD_DARK_BLUE,
        conversationColor: COLOR_SENDCLOUD_DARK_BLUE,
        actionColor: COLOR_SENDCLOUD_LIGHT_BLUE,
      },
      customText: {
        newConversationButtonText: i18n.t('support.chat.new_conversation'),
        conversationListHeaderText: i18n.t('support.chat.my_conversations'),
        inputPlaceholder: i18n.t('support.chat.type_a_message'),
      },
      menuItems: {
        imageUpload: true,
        fileUpload: true,
        shareLocation: false,
      },
      delegate: {
        beforeDisplay(message, _data) {
          // Hide hidden messages sent by the widget, i.e. the greeting message trigger
          // @ts-expect-error we can send metadata
          if (message?.metadata?.hidden) {
            return null
          }
          return message
        },
      },
    })
    const iframe = getChatWidgetContainer()
    if (!iframe) {
      return
    }
    overrideSmoochStyles(iframe)
    const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document
    if (iframeDocument) {
      iframeDocument.addEventListener('click', () => addConversationButtonListener(iframeDocument), false)
    }
    await setSmoochMetafields()
  },

  /**
   * Checks if the chat widget is enabled in the env variables
   */
  isChatWidgetEnabled(): boolean {
    return import.meta.env.VUE_APP_CHAT_WIDGET_ENABLED === 'yes'
  },

  showChatWidget() {
    const chatWidget = getChatWidgetContainer()
    if (chatWidget) {
      chatWidget.style.display = 'block'
    }
  },

  hideChatWidget() {
    const chatWidget = getChatWidgetContainer()
    if (chatWidget) {
      chatWidget.style.display = 'none'
    }
  },

  hideSupportButtons() {
    if (this.isChatWidgetEnabled()) {
      /*
       * since the chat widget is loaded asynchronously, we have to wait for it to load in
       * order to hide it. We check every 500ms and when it is found we hide it.
       */
      let timeOut
      if (isSmoochReady) {
        this.hideChatWidget()
        if (timeOut) {
          clearInterval(timeOut)
        }
      } else {
        timeOut = setTimeout(() => this.hideSupportButtons(), 500)
      }
    }
  },

  async getZendeskToken() {
    const { data } = await getZendeskToken()
    return data
  },

  waitForSmooch(): Promise<void> {
    return new Promise((resolve, reject) => {
      const checkInterval = 250
      const maxAttempts = 20
      let attempts = 0

      function checkSmooch(): void {
        if (isSmoochReady) {
          return resolve()
        }

        attempts++

        if (attempts < maxAttempts) {
          setTimeout(checkSmooch, checkInterval)
          return
        }

        reject(new Error('Smooch did not load within the expected time.'))
      }

      checkSmooch()
    })
  },

  openWidget() {
    if (!Smooch.isOpened()) {
      Smooch.open()
    }
  },

  async openLiveChat(message: string) {
    this.openWidget()
    const conversation = await Smooch.createConversation({})
    await Smooch.loadConversation(conversation.id)
    sendSmoochHiddenMessage(conversation, message)
  },

  async openExistingConversation(chatId?: string) {
    try {
      await this.waitForSmooch()
    } catch (error) {
      logError(error)
      return
    }
    this.openWidget()
    if (chatId) {
      await Smooch.loadConversation(chatId)
    }
  },

  /**
   * Open chat widget for onboarding flow
   * - Positions widget to the left on desktop
   * - Sets up widget closed event handler
   */
  openOnboardingChat() {
    this.showChatWidget()
    positionChatWidget(WIDGET_MODES.ONBOARDING)

    const chatButton = getChatWidgetContainer()
    if (chatButton) {
      chatButton.style.opacity = '0'
      setTimeout(() => {
        chatButton.style.opacity = '1'
      }, 250)
    }

    this.openWidget()

    eventHandlers.widgetClosed = handleOnboardingWidgetClosed
    Smooch.on('widget:closed', eventHandlers.widgetClosed)
  },

  /**
   * Restore default widget position and behavior
   * Used when leaving onboarding flow
   */
  restoreDefaultChatWidget() {
    this.showChatWidget()
    positionChatWidget(WIDGET_MODES.DEFAULT)
  },
}

export default SupportChatService
