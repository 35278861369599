import type { RawAddress, SenderAddress } from '@/features/addresses/types/addresses.types'

export function getEmptyRawAddress(initialProcessedFlag: boolean = false): RawAddress {
  return {
    processed: initialProcessedFlag,
    address_line_1: '',
    city: '',
    postal_code: '',
    country_code: '',
    address_type: 'not_defined',
  }
}

export function senderAddressToRawAddress(senderAddress: SenderAddress): RawAddress {
  return ({
    address_line_1: senderAddress?.address_1 ?? '',
    address_line_2: senderAddress?.address_2 ?? null,
    city: senderAddress?.city ?? '',
    postal_code: senderAddress?.postal_code ?? '',
    house_number: senderAddress?.house_number ?? '',
    country_code: senderAddress?.country_iso_2 ?? '',
    state_province_code: senderAddress?.country_state ?? null,
    processed: true,
    address_type: 'split', // sender addresses are always split as they have a house number
  })
}
