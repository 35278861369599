import type { Commit, Dispatch } from 'vuex'

import { arrayToObject } from '@/common/utils/array-helpers/array-to-object'
import axios from '@/common/utils/axios'

import CountryApi from '@/common/api/country.api'
import UsersApi from '@/features/account/api/users.api'
import CurrencyApi from '@/common/api/currency.api'

import {
  CORE_SET_COUNTRIES,
  CORE_SET_CURRENCIES,
  CORE_SET_DATA_DUMP,
  CORE_SET_GLOBAL_SETTINGS,
  CORE_SET_PAGE_TITLE,
  CORE_SET_PAYMENT_INVOICE_INFO,
  CORE_SET_PAYMENT_WALL_TYPE,
  CORE_SET_SEARCH_QUERY_SHIPMENTS,
  CORE_SET_SIDEBAR_VISIBILITY,
  CORE_SET_STYLED_PORTAL,
  CORE_SET_UNSAVED_CHANGES,
  CORE_SET_USER,
  CORE_SET_USER_BETA_COMMUNITY,
} from '@/common/stores/core/mutation.types'
import {
  CORE_ACCOUNT_DELETE,
  CORE_ACCOUNT_UNFREEZE,
  CORE_CREATE_DATA_DUMP,
  CORE_DISPLAY_PAYMENT_MODAL,
  CORE_DISPLAY_PAYMENT_WALL,
  CORE_FETCH_COUNTRIES,
  CORE_FETCH_COUNTRIES_LAZILY,
  CORE_FETCH_CURRENCIES,
  CORE_FETCH_CURRENCIES_LAZILY,
  CORE_FETCH_DATA_DUMP,
  CORE_FETCH_DEFINITIONS,
  CORE_FETCH_USER,
  CORE_TOGGLE_SIDEBAR_SETTINGS,
  CORE_UPDATE_PAGE_TITLE,
  CORE_UPDATE_SEARCH_QUERY_SHIPMENTS,
  CORE_UPDATE_SIDEBAR_SETTINGS,
  CORE_UPDATE_UNSAVED_CHANGES,
  CORE_UPDATE_USER,
  CORE_UPDATE_USER_BETA_COMMUNITY,
} from '@/common/stores/core/action.types'
import { SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS } from '@/features/addresses/stores/sender-addresses/action.types'

import type { Country, Currency, Settings, User } from '@/types/models'
import type { Definitions } from '@/common/types/definitions.types'
import type { ExportedUserData } from '@/features/account/types/user.types'
import type { PaymentWallType } from '@/features/payment-wall/types/payment-wall.types'

let isLoadingCurrencies = false

// Used to track the promises of lazy store actions.
// Enables multiple concurrent dispatches of a lazy action which will resolve at the same time
// because they await the same underlying promise.
let CORE_FETCH_COUNTRIES_LAZILY_PROMISE: Promise<any> | null = null
let CORE_FETCH_CURRENCIES_LAZILY_PROMISE: Promise<any> | null = null

export type CoreState = {
  countries: Country[]
  countriesByCode: Record<string, Country>
  countriesById: Record<number, Country>
  currencies: Currency[]
  dataDump: ExportedUserData | null
  globalSettings: {
    definitions: Definitions | null
    settings: Settings | null
  }
  pageTitle: string
  paymentInvoiceId: string | undefined
  paymentWallType: string | undefined
  searchQuery: string | undefined
  showSidebarSettings: boolean
  sidebarSettings: Record<string, any> | null // Note: temporary; state will be removed later.
  unsavedChanges: boolean
  user: User | undefined
  userManageableBetaFeatures: string[]
}

const state: CoreState = {
  countries: [],
  countriesByCode: {},
  countriesById: {},
  currencies: [],
  dataDump: null,
  globalSettings: {
    definitions: null,
    settings: null,
  },
  pageTitle: '',
  paymentInvoiceId: undefined,
  paymentWallType: undefined,
  searchQuery: undefined,
  showSidebarSettings: false,
  sidebarSettings: null,
  unsavedChanges: false,
  user: undefined,
  userManageableBetaFeatures: [],
}

// Note: Typing getters as `any` because typing getters is difficult in Vuex. Will be removed when we move to Pinia.
const getters = {
  allowedUserInvoiceCountries: (state: CoreState, getters: any) => {
    return state.countries.filter(c => getters.definitions.users.allowed_user_invoice_countries.includes(c.iso_2))
  },

  allowedUserSenderCountries: (state: CoreState, getters: any) => {
    return state.countries.filter(c => getters.definitions.users.allowed_user_sender_countries.includes(c.iso_2))
  },

  allowedUserPickupCountries: (state: CoreState, getters: any) => {
    return state.countries.filter(c => getters.definitions.pickups.allowed_countries.includes(c.iso_2))
  },

  countries: (state: CoreState) => state.countries,

  countryByISO2: (state: CoreState) => (iso2: string) => state.countriesByCode[iso2],

  countryByID: (state: CoreState) => (id: number) => state.countriesById[id],

  currencies: (state: CoreState) => state.currencies,

  dataDump: (state: CoreState) => state.dataDump,

  definitions: (state: CoreState) => state.globalSettings.definitions,

  isHijacked: (state: CoreState) => {
    if (state.globalSettings.definitions) {
      return state.globalSettings.definitions.hijacked.is_hijacked
    } else {
      return false
    }
  },

  paymentInvoiceId: (state: CoreState) => state.paymentInvoiceId,

  paymentWallType: (state: CoreState) => state.paymentWallType,

  podId: (state: CoreState) => state.globalSettings?.definitions?.pod.id,

  searchQuery: (state: CoreState) => state.searchQuery,

  servicePointsMapUrl: (_state: CoreState, getters: any) => getters.settings.django.SERVICEPOINTS_PUBLIC_URL,

  settings: (state: CoreState) => state.globalSettings.settings,

  sortedCountries: (state: CoreState, getters: any) => {
    return state.countries
      .map(country => country)
      .sort((countryA, countryB) => countryA.name.localeCompare(countryB.name, getters.userLanguage))
  },

  user: (state: CoreState) => state.user,

  userCountry: (_state: CoreState, getters: any) => getters.definitions.users.country,

  userLanguage: (_state: CoreState, getters: any) => getters.definitions.users.language,

  userPlanGroup: (state: CoreState) => state.user?.new_plan_group,

  unsavedChanges: (state: CoreState) => state.unsavedChanges,

  pageTitle: (state: CoreState) => state.pageTitle,

  sidebarSettings: (state: CoreState) => state.sidebarSettings,

  showSidebarSettings: (state: CoreState) => state.showSidebarSettings,

  currency: (state: CoreState) => state.user?.currency,

  userManageableBetaFeatures: (state: CoreState) => state.userManageableBetaFeatures,

  isStaffUser: (state: CoreState) => state.user?.is_staff,

  hasBetaFeature(state: CoreState) {
    return (flag: string) => state.user?.enabled_beta_features?.includes(flag) || false
  },

  userIsScheduledForDeletion: (state: CoreState) => Boolean(state.user?.delete_requested_at),

  userAccountIsOnHold: (state: CoreState) => Boolean(state.user?.is_account_on_hold),

  userHasOverdueInvoices: (state: CoreState) => Boolean(state.user?.has_overdue_invoices),
}

const actions = {
  async [CORE_ACCOUNT_DELETE]({ commit }: { commit: Commit }) {
    const user = await UsersApi.delete()
    commit(CORE_SET_USER, user)
  },

  async [CORE_ACCOUNT_UNFREEZE]({ commit }: { commit: Commit }) {
    const user = await UsersApi.unfreeze()
    commit(CORE_SET_USER, user)
  },

  async [CORE_CREATE_DATA_DUMP]({ commit }: { commit: Commit }) {
    const userData = await UsersApi.exportUserData()
    commit(CORE_SET_DATA_DUMP, userData)
  },

  [CORE_DISPLAY_PAYMENT_MODAL]({ commit }: { commit: Commit }, invoiceId: string | undefined) {
    commit(CORE_SET_PAYMENT_INVOICE_INFO, invoiceId)
  },

  [CORE_DISPLAY_PAYMENT_WALL]({ commit }: { commit: Commit }, value: PaymentWallType) {
    commit(CORE_SET_PAYMENT_WALL_TYPE, value)
  },

  async [CORE_FETCH_COUNTRIES]({ commit }: { commit: Commit }) {
    const response = await CountryApi.findAll()
    commit(CORE_SET_COUNTRIES, response.data)
  },

  async [CORE_FETCH_COUNTRIES_LAZILY]({ dispatch, state }: { dispatch: Dispatch, state: CoreState }) {
    if (state.countries.length === 0) {
      // If there isn’t already a fetch in progress, trigger one
      if (CORE_FETCH_COUNTRIES_LAZILY_PROMISE === null) {
        CORE_FETCH_COUNTRIES_LAZILY_PROMISE = dispatch(CORE_FETCH_COUNTRIES)
      }

      // Await the fetch promise and reset the promise reference
      await CORE_FETCH_COUNTRIES_LAZILY_PROMISE
      CORE_FETCH_COUNTRIES_LAZILY_PROMISE = null
    }
  },

  async [CORE_FETCH_DATA_DUMP]({ commit }: { commit: Commit }) {
    let userData
    try {
      userData = await UsersApi.getUserData()
    } catch {
      userData = null
    }
    commit(CORE_SET_DATA_DUMP, userData)
  },

  async [CORE_FETCH_USER](
    { state, commit, dispatch }: { state: CoreState, commit: Commit, dispatch: Dispatch },
    refresh: boolean,
  ) {
    if (state.user && !refresh) {
      return Promise.resolve(state.user)
    }
    const user = await UsersApi.getMe()
    commit(CORE_SET_USER, user)
    await dispatch(SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS)
  },

  async [CORE_FETCH_DEFINITIONS]({ commit }: { commit: Commit }) {
    const response = await axios.get('/xhr/definitions')
    commit(CORE_SET_GLOBAL_SETTINGS, response.data)
  },

  async [CORE_UPDATE_USER]({ commit }: { commit: Commit }, data: Partial<User>) {
    const user = await UsersApi.update(data)
    commit(CORE_SET_USER, user)
  },

  [CORE_UPDATE_UNSAVED_CHANGES]({ commit }: { commit: Commit }, unsavedChanges: boolean) {
    commit(CORE_SET_UNSAVED_CHANGES, unsavedChanges)
  },

  [CORE_UPDATE_PAGE_TITLE]({ commit }: { commit: Commit }, pageTitle: string) {
    commit(CORE_SET_PAGE_TITLE, pageTitle)
  },

  [CORE_TOGGLE_SIDEBAR_SETTINGS]({ commit, state }: { commit: Commit, state: CoreState }) {
    commit(CORE_SET_SIDEBAR_VISIBILITY, !state.showSidebarSettings)
  },

  // Note: typing this as `any` because this logic will be fully removed - it's connected to the old navigation.
  [CORE_UPDATE_SIDEBAR_SETTINGS]({ commit }: { commit: Commit }, sidebarSettings: Record<string, any>) {
    commit(CORE_UPDATE_SIDEBAR_SETTINGS, sidebarSettings)
  },

  [CORE_UPDATE_USER_BETA_COMMUNITY]({ commit }: { commit: Commit }, isEnabled: boolean) {
    commit(CORE_SET_USER_BETA_COMMUNITY, isEnabled)
  },

  [CORE_UPDATE_SEARCH_QUERY_SHIPMENTS]({ commit }: { commit: Commit }, searchQuery: string) {
    commit(CORE_SET_SEARCH_QUERY_SHIPMENTS, searchQuery)
  },

  async [CORE_FETCH_CURRENCIES_LAZILY]({ dispatch }: { dispatch: Dispatch }) {
    if (state.currencies.length === 0) {
      // If there isn’t already a fetch in progress, trigger one
      if (CORE_FETCH_CURRENCIES_LAZILY_PROMISE === null) {
        CORE_FETCH_CURRENCIES_LAZILY_PROMISE = dispatch(CORE_FETCH_CURRENCIES)
      }

      // Await the fetch promise and reset the promise reference
      await CORE_FETCH_CURRENCIES_LAZILY_PROMISE
      CORE_FETCH_CURRENCIES_LAZILY_PROMISE = null
    }
  },

  async [CORE_FETCH_CURRENCIES]({ commit }: { commit: Commit }) {
    if (isLoadingCurrencies) {
      return
    }

    let currencies: Currency[] = []
    try {
      isLoadingCurrencies = true
      currencies = await CurrencyApi.fetchCurrencies()
    } finally {
      commit(CORE_SET_CURRENCIES, currencies)
      isLoadingCurrencies = false
    }
  },
}

const mutations = {
  [CORE_SET_CURRENCIES](state: CoreState, currencies: Currency[]) {
    state.currencies = currencies
  },

  /**
   * Sets the ID of the invoice to be charged to the customer
   */
  [CORE_SET_PAYMENT_INVOICE_INFO](state: CoreState, invoiceId: string | undefined) {
    state.paymentInvoiceId = invoiceId
  },

  [CORE_SET_COUNTRIES](state: CoreState, countries: Country[]) {
    state.countries = countries
    state.countriesById = arrayToObject(countries, { getKey: country => country.id, getValue: country => country })
    state.countriesByCode = arrayToObject(countries, { getKey: country => country.iso_2, getValue: country => country })
  },

  [CORE_SET_DATA_DUMP](state: CoreState, payload: ExportedUserData) {
    state.dataDump = payload
  },

  [CORE_SET_GLOBAL_SETTINGS](
    state: CoreState,
    {
      definitions,
      settings,
    }: {
      definitions: Definitions
      settings: Settings
    },
  ) {
    state.globalSettings = {
      definitions,
      settings,
    }
  },

  [CORE_SET_SIDEBAR_VISIBILITY](state: CoreState, show: boolean) {
    state.showSidebarSettings = show
  },

  [CORE_SET_PAYMENT_WALL_TYPE](state: CoreState, value: PaymentWallType) {
    state.paymentWallType = value
  },

  [CORE_SET_SEARCH_QUERY_SHIPMENTS](state: CoreState, value: string) {
    state.searchQuery = value
  },

  [CORE_SET_STYLED_PORTAL](state: CoreState) {
    if (state.globalSettings.definitions?.returns) {
      state.globalSettings.definitions.returns.styled_portal = true
    }
  },

  [CORE_SET_USER](state: CoreState, user: User) {
    state.user = user
  },

  [CORE_SET_UNSAVED_CHANGES](state: CoreState, value: boolean) {
    state.unsavedChanges = value
  },

  [CORE_SET_PAGE_TITLE](state: CoreState, pageTitle: string) {
    state.pageTitle = pageTitle
  },

  // Note: typing this as `any` because this logic will be fully removed - it's connected to the old navigation.
  [CORE_UPDATE_SIDEBAR_SETTINGS](state: CoreState, sidebarSettings: Record<string, any>) {
    state.sidebarSettings = sidebarSettings
  },

  [CORE_SET_USER_BETA_COMMUNITY](state: CoreState, isEnabled: boolean) {
    if (state.user) {
      state.user = { ...state.user, ...{ beta_community: isEnabled } }
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
