export const CORE_ACCOUNT_DELETE = 'CORE_ACCOUNT_DELETE'
export const CORE_ACCOUNT_UNFREEZE = 'CORE_ACCOUNT_UNFREEZE'
export const CORE_CREATE_DATA_DUMP = 'CORE_CREATE_DATA_DUMP'
export const CORE_DISPLAY_PAYMENT_MODAL = 'CORE_DISPLAY_PAYMENT_MODAL'
export const CORE_DISPLAY_PAYMENT_WALL = 'CORE_DISPLAY_PAYMENT_WALL'
export const CORE_FETCH_COUNTRIES = 'CORE_FETCH_COUNTRIES'
export const CORE_FETCH_COUNTRIES_LAZILY = 'CORE_FETCH_COUNTRIES_LAZILY'
export const CORE_FETCH_CURRENCIES = 'CORE_FETCH_CURRENCIES'
export const CORE_FETCH_CURRENCIES_LAZILY = 'CORE_FETCH_CURRENCIES_LAZILY'
export const CORE_FETCH_DATA_DUMP = 'CORE_FETCH_DATA_DUMP'
export const CORE_FETCH_DEFINITIONS = 'CORE_FETCH_DEFINITIONS'
export const CORE_FETCH_USER = 'CORE_FETCH_USER'
export const CORE_TOGGLE_SIDEBAR_SETTINGS = 'CORE_TOGGLE_SIDEBAR_SETTINGS'
export const CORE_UPDATE_PAGE_TITLE = 'CORE_UPDATE_PAGE_TITLE'
export const CORE_UPDATE_SEARCH_QUERY_SHIPMENTS = 'CORE_UPDATE_SEARCH_QUERY_SHIPMENTS'
export const CORE_UPDATE_SEARCH_QUERY_SUPPORT_TICKETS = 'CORE_UPDATE_SEARCH_QUERY_SUPPORT_TICKETS'
export const CORE_UPDATE_SIDEBAR_SETTINGS = 'CORE_UPDATE_SIDEBAR_SETTINGS'
export const CORE_UPDATE_UNSAVED_CHANGES = 'CORE_UPDATE_UNSAVED_CHANGES'
export const CORE_UPDATE_USER = 'CORE_UPDATE_USER'
export const CORE_UPDATE_USER_BETA_COMMUNITY = 'CORE_UPDATE_USER_BETA_COMMUNITY'
