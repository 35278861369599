import {
  CHECKOUT_RULES_CREATE_RULE,
  CHECKOUT_RULES_DELETE_RULE,
  CHECKOUT_RULES_FETCH_RULES,
  CHECKOUT_RULES_FETCH_RULE,
  CHECKOUT_RULES_UPDATE_RULE,
} from '@/features/dynamic-checkout-rules/stores/action.types'

import { CHECKOUT_RULES_SET_RULE, CHECKOUT_RULES_SET_RULES } from '@/features/dynamic-checkout-rules/stores/mutation.types'

import {
  createCheckoutRule,
  fetchCheckoutRules,
  fetchCheckoutRule,
  deleteCheckoutRule,
  updateCheckoutRule,
} from '@/features/dynamic-checkout-rules/api/checkout-rules.api'
import type { CheckoutRule, CheckoutRuleDraft } from '../types/checkout-rules.types'
import type { ActionTree, MutationTree } from 'vuex'

const state = {
  checkoutRules: [] as CheckoutRule[],
}

type State = typeof state

const getters = {
  checkoutRules: (state: State) => state.checkoutRules,
  checkoutRuleById: (state: State) => {
    return (ruleId: State['checkoutRules'][number]['id']) => state.checkoutRules.find(rule => rule.id === ruleId)
  },
}

const actions: ActionTree<State, any> = {
  async [CHECKOUT_RULES_FETCH_RULES]({ commit }) {
    const checkoutRules = await fetchCheckoutRules()
    commit(CHECKOUT_RULES_SET_RULES, checkoutRules)
  },

  async [CHECKOUT_RULES_CREATE_RULE]({ dispatch }, checkoutRule: CheckoutRuleDraft) {
    await createCheckoutRule(checkoutRule)
    dispatch(CHECKOUT_RULES_FETCH_RULES)
  },

  async [CHECKOUT_RULES_DELETE_RULE]({ dispatch }, ruleId: State['checkoutRules'][number]['id']) {
    await deleteCheckoutRule(ruleId)
    dispatch(CHECKOUT_RULES_FETCH_RULES)
  },

  async [CHECKOUT_RULES_FETCH_RULE]({ commit }, ruleId: State['checkoutRules'][number]['id']) {
    const checkoutRule = await fetchCheckoutRule(ruleId)
    commit(CHECKOUT_RULES_SET_RULE, checkoutRule)
  },

  async [CHECKOUT_RULES_UPDATE_RULE]({ dispatch }, checkoutRule: State['checkoutRules'][number]) {
    await updateCheckoutRule(checkoutRule)
    dispatch(CHECKOUT_RULES_FETCH_RULES)
  },
}

const mutations: MutationTree<State> = {
  [CHECKOUT_RULES_SET_RULES](state, checkoutRules: State['checkoutRules']) {
    state.checkoutRules = checkoutRules
  },

  [CHECKOUT_RULES_SET_RULE](state, checkoutRule: State['checkoutRules'][number]) {
    state.checkoutRules = [...(state.checkoutRules || []), checkoutRule]
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
