import { CORE_SET_USER } from '@/common/stores/core/mutation.types'
import type { Commit } from 'vuex'

import ShippingDefaultsService from '@/features/shipping-defaults/api/shipping-defaults.api'
import {
  SHIPPING_FETCH_DEFAULT_SETTINGS,
  SHIPPING_UPDATE_DEFAULT_SETTINGS,
} from '@/features/shipping-defaults/stores/action.types'
import { SHIPPING_SET_DEFAULT_SETTINGS } from '@/features/shipping-defaults/stores/mutation.types'
import type { ShippingDefaults, ShippingDefaultsPayload } from '@/features/shipping-defaults/types/shipping-defaults.types'

type ModuleState = typeof state

const state: { shippingDefaults: ShippingDefaults } = {
  shippingDefaults: {
    default_insurance_percentage: null,
    default_weight: '',
    parcel_default_customs_shipment_type: null,
    parcel_default_insured_value: 0,
    parcel_default_shipping_method: null,
    parcel_show_order_reference_label: false,
    parcel_show_port_letter: false,
    parcel_show_qr_code_letter: false,
    use_order_number_as_invoice_number: false,
  },
}

const getters = {
  shippingDefaults: (state: ModuleState) => state.shippingDefaults,
}

const actions = {
  async [SHIPPING_FETCH_DEFAULT_SETTINGS]({ commit }: { commit: Commit }) {
    const shippingDefaults = await ShippingDefaultsService.getDefaults()
    commit(SHIPPING_SET_DEFAULT_SETTINGS, shippingDefaults)
  },

  async [SHIPPING_UPDATE_DEFAULT_SETTINGS](
    { commit, getters }: { commit: Commit, getters: any }, shippingDefaults: Partial<ShippingDefaultsPayload>,
  ) {
    const updatedDefaults = await ShippingDefaultsService.updateDefaults(shippingDefaults)

    // Shipping defaults belong to the overall account, and are not local per
    // each and every subuser. Yet, historically, they are stored on user level
    // which is something we're moving away from. Yet, for now, we'll keep the
    // user-level settings in sync as well:
    const user = { ...getters.user, ...updatedDefaults }
    commit(CORE_SET_USER, user)

    commit(SHIPPING_SET_DEFAULT_SETTINGS, updatedDefaults)
  },
}

const mutations = {
  [SHIPPING_SET_DEFAULT_SETTINGS](state: ModuleState, shippingDefaults: ShippingDefaults) {
    state.shippingDefaults = shippingDefaults
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
